import React from "react";
import ButtonRegister from "./ButtonRegister";

const LandingPageBanner = ({
  isMobile = false,
  isTablet = false,
  onClick,
  handleDownloadApp,
}) => {
  return (
    <section
      id="banner-landingpage"
      style={{
        width: "100%",
        background: isMobile ? "#F1EEFC" : "",
      }}
    >
      <div
        className={` is-flex is-flex-direction-column is-align-items-center is-justify-content-flex-end height-landingpage`}
        style={{
          height: isMobile || isTablet ? "auto" : "42.5rem",
          backgroundImage: isMobile
            ? ""
            : isTablet
            ? "url(/images/landing-page/background-banner-tablet.png)"
            : "url(/images/landing-page/background-banner.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        <div
          className={`${
            isMobile
              ? "is-flex-wrap-wrap is-align-items-center is-justify-content-center pt-7"
              : isTablet
              ? "is-flex-direction-column is-align-items-center is-justify-content-center"
              : "is-align-items-flex-end is-justify-content-center"
          } is-flex max-width-landingpage`}
          style={{
            height: "100%",
            gap: isTablet ? "3.25rem" : "",
            padding: isTablet ? "3.625rem 7.1875rem 0rem 7.1875rem" : "",
          }}
        >
          <div
            className={`${
              isTablet || isMobile
                ? "is-align-items-center has-text-centered"
                : "is-align-items-flex-start"
            } is-flex is-flex-direction-column is-justify-content-center is-flex-gap-4 has-text-left is-relative has-text-kurio-main-color-black`}
            style={{ zIndex: 2, height: "100%" }}
          >
            <div className={`${isTablet ? "has-text-centered" : ""}`}>
              <p className="has-text-weight-bold is-capitalized has-text-kurio-main-color-black title-medium">
                giải pháp
              </p>
              <p className="has-text-weight-bold is-capitalized has-text-kurio-main-color-purple title-medium">
                học & luyện thi toán tư duy
              </p>
              <p
                className=" is-uppercase has-text-kurio-main-color-purple title-big"
                style={{ fontWeight: "800" }}
              >
                toàn diện
              </p>
              <p
                className={`${isMobile ? "is-size-6 mt-2" : "is-size-5"}`}
                style={{ width: isMobile ? "14.1875rem" : "auto" }}
              >
                Cá nhân hóa lộ trình, tối ưu{" "}
                <b
                  className={`${
                    isMobile ? "is-size-5" : "is-size-4"
                  } has-text-kurio-main-color-purple`}
                >
                  100%
                </b>{" "}
                hiệu quả học tập!
              </p>
            </div>
            <div
              className={`${
                isTablet
                  ? "is-justify-content-center"
                  : "is-justify-content-flex-start"
              } is-flex is-align-items-center  is-flex-gap-4 banner-kangaroo`}
              style={{ height: isMobile ? "4rem" : "5rem" }}
            >
              <img src="/images/landing-page/icon-kangaroo.svg" alt="" />
              <p
                className={`${isMobile ? "is-size-6" : "is-size-5"}`}
                style={{ width: isMobile ? "15.25rem" : "19rem" }}
              >
                <b>Đối tác học thuật chính thức</b> của kỳ thi{" "}
                <b>Toán Kangaroo</b>
              </p>
            </div>
            <div className="btn-banner">
              <ButtonRegister
                width={isMobile ? "20rem" : "26.5rem"}
                height={isMobile ? "4rem" : "5.5rem"}
                onClick={onClick}
              />
            </div>
            <div
              className={`${
                isMobile ? "is-size-6" : "is-size-5"
              } is-flex is-flex-gap-4`}
            >
              <p>Đã có tài khoản Kurio?</p>
              <u
                onClick={() => {
                  if (isMobile || isTablet) {
                    handleDownloadApp();
                  } else {
                    window.location.href = "/signin";
                  }
                }}
                className="has-text-kurio-main-color-purple has-text-weight-bold is-clickable"
              >
                Đăng nhập
              </u>
            </div>
          </div>
          {isMobile && (
            <div
              className="is-flex is-flex-direction-column is-align-items-center is-justify-content-flex-end pt-6"
              style={{
                backgroundImage: isMobile
                  ? "url(/images/landing-page/background-banner-mobile.png)"
                  : "",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "100%",
              }}
            >
              <img
                className=""
                style={{
                  objectFit: "cover",
                  height: isMobile ? "17.625rem" : "80%",
                }}
                src="/images/landing-page/image-landing-page.png"
                alt=""
              />
            </div>
          )}
          {isTablet && (
            <img
              className=""
              style={{
                objectFit: "contain",
                height: "25rem",
              }}
              src="/images/landing-page/image-landing-page.png"
              alt=""
            />
          )}
          {!isMobile && !isTablet && (
            <img
              className=""
              style={{
                objectFit: "cover",
                height: isMobile ? "17.625rem" : "80%",
              }}
              src="/images/landing-page/image-landing-page.png"
              alt=""
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default LandingPageBanner;
