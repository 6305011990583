import React from "react";

const LandingPageIntro = ({ isMobile = false, isTablet = false }) => {
  return (
    <section
      id=""
      className="has-background-kurio-main-color-white max-width-landingpage"
      style={{ width: "100%" }}
    >
      <div
        className={`${
          isMobile
            ? "is-justify-content-center is-flex-gap-6 is-flex-direction-column"
            : isTablet
            ? "is-justify-content-center is-flex-gap-6"
            : "is-justify-content-space-between"
        } is-flex is-align-items-center has-background-kurio-main-color-white height-landingpage`}
        style={{
          height: isMobile || isTablet ? "auto" : "42.5rem",
          width: "100%",
          padding: isMobile
            ? "4rem 2rem 1.5rem 2rem"
            : isTablet
            ? "4rem 0rem 0rem 0rem"
            : "5rem 4rem 0rem 4rem",
        }}
      >
        {isMobile && (
          <div
            className="is-flex is-flex-direction-column is-flex-gap-2 is-align-items-flex-start intro-title-mobile"
            style={{ width: "20.375rem" }}
          >
            <p
              className={`${
                isMobile ? "is-size-3" : "is-size-1"
              } has-text-kurio-main-color-purple has-text-weight-bold big-title`}
              style={{ lineHeight: "120%" }}
            >
              <span className="has-text-kurio-main-color-black">Dành cho</span>{" "}
              học sinh từ lớp 1 ~ 9
            </p>
            <p className="has-text-kurio-main-color-black has-text-weight-bold is-size-6">
              <span className="has-text-kurio-main-color-purple is-size-5">
                KURIO
              </span>{" "}
              - Học Toán vui vẻ và hiệu quả!
            </p>
          </div>
        )}
        <img
          className="image-intro"
          style={{
            height: isMobile ? "21.875rem" : isTablet ? "18.85563rem" : "35rem",
            width: isMobile
              ? "17.85388rem"
              : isTablet
              ? "15.3895rem"
              : "28.56619rem",
          }}
          src="/images/landing-page/image-landing-page-2.png"
          alt=""
        />

        <div
          className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start is-flex-gap-5 intro-right"
          style={{
            width: isMobile ? "20.375rem" : isTablet ? "22rem" : "35rem",
          }}
        >
          {!isMobile && (
            <div
              className="is-flex is-flex-direction-column is-align-items-flex-start"
              style={{
                width: isTablet ? "22rem" : "33rem",
                gap: isTablet ? "0.5rem" : "1rem",
              }}
            >
              <p
                className="has-text-kurio-main-color-purple has-text-weight-bold"
                style={{
                  lineHeight: "120%",
                  fontSize: isTablet ? "2rem" : "3rem",
                }}
              >
                <span className="has-text-kurio-main-color-black">
                  Dành cho
                </span>{" "}
                học sinh từ lớp 1 ~ 9
              </p>
              <p
                className="has-text-kurio-main-color-black has-text-weight-bold"
                style={{
                  fontSize: isTablet ? "1rem" : "1.25rem",
                  lineHeight: "120%",
                }}
              >
                <span className="has-text-kurio-main-color-purple">KURIO</span>{" "}
                - Học Toán vui vẻ và hiệu quả!
              </p>
            </div>
          )}
          <div
            className="is-flex is-flex-direction-column"
            style={{
              background: "#E8E3F8",
              borderRadius: "2rem",
              padding: isMobile
                ? "1.5rem 1rem"
                : isTablet
                ? "1.5rem"
                : "2rem 2.5rem",
              gap: isMobile || isTablet ? "1rem" : "1.5rem",
            }}
          >
            <p
              className={`${
                isMobile || isTablet ? "is-size-6" : "is-size-5"
              } has-text-kurio-main-color-purple`}
              style={{ lineHeight: "120%" }}
            >
              Nội dung bám sát{" "}
              <b>chương trình Bộ Giáo dục và Luyện thi Toán quốc tế</b>
            </p>
            <div
              className={`${
                isMobile
                  ? "is-flex-gap-2 is-justify-content-center is-flex-wrap-wrap"
                  : "is-justify-content-space-between"
              } is-flex`}
              style={{ width: "100%" }}
            >
              <div
                className="has-background-kurio-main-color-white is-flex is-align-items-center is-justify-content-center logo-img"
                style={{
                  borderRadius: "1rem",
                  padding: isMobile || isTablet ? "" : "1.25rem",
                  width: isMobile || isTablet ? "4rem" : "6rem",
                  height: isMobile || isTablet ? "4rem" : "6rem",
                }}
              >
                <img
                  src="/images/landing-page/logo-ket-noi-tri-thuc.png"
                  style={{
                    width: isMobile || isTablet ? "3.16669rem" : "",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              </div>
              <div
                className="has-background-kurio-main-color-white is-flex is-align-items-center is-justify-content-center logo-img"
                style={{
                  borderRadius: "1rem",
                  padding: isMobile || isTablet ? "" : "1.25rem",
                  width: isMobile || isTablet ? "4rem" : "6rem",
                  height: isMobile || isTablet ? "4rem" : "6rem",
                }}
              >
                <img
                  src="/images/landing-page/logo-canh-dieu.png"
                  style={{ height: isMobile || isTablet ? "3.08331rem" : "" }}
                  alt=""
                />
              </div>
              <div
                className="has-background-kurio-main-color-white is-flex is-align-items-center is-justify-content-center logo-img"
                style={{
                  borderRadius: "1rem",
                  padding: isMobile || isTablet ? "" : "1.25rem",
                  width: isMobile || isTablet ? "4rem" : "6rem",
                  height: isMobile || isTablet ? "4rem" : "6rem",
                }}
              >
                <img
                  src="/images/landing-page/logo-chan-troi-sang-tao.png"
                  style={{
                    width: isMobile || isTablet ? "3.04169rem" : "",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              </div>
              <div
                className="has-background-kurio-main-color-white is-flex is-align-items-center is-justify-content-center logo-img"
                style={{
                  borderRadius: "1rem",
                  padding: isMobile || isTablet ? "" : "1.25rem",
                  width: isMobile || isTablet ? "4rem" : "6rem",
                  height: isMobile || isTablet ? "4rem" : "6rem",
                }}
              >
                <img
                  src="/images/landing-page/logo-ikmc.png"
                  alt=""
                  style={{
                    width: isMobile || isTablet ? "3rem" : "",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingPageIntro;
