import React from "react";

const ButtonRegister = ({
  width = "20rem",
  height = "4rem",
  borderRadius = "2rem",
  background = "linear-gradient(0deg, #572CD1 0%, #8864F0 100%)",
  border = "0.125rem solid transparent",
  boxShadow = "0rem 0.25rem 0rem 0rem #0A2A66",
  fontSize = "1.5rem",
  disable = false,
  isMobile = false,
  onClick,
  text = "đăng ký ngay",
  hideIcon = false,
  isReverse = false,
}) => {
  return (
    <>
      <div
        className="is-relative is-flex is-flex-direction-column"
        style={{ zIndex: 2 }}
      >
        <button
          disabled={disable}
          className={`${isMobile ? "btn-register-mobile" : "btn-register"} ${
            isReverse ? "is-flex-direction-row-reverse px-4" : ""
          } is-flex is-justify-content-center is-align-items-center is-relative is-clickable`}
          onClick={onClick}
          style={{
            width: width,
            height: height,
            background: background,
            borderRadius: borderRadius,
            border: border,
            boxShadow: boxShadow,
            gap: isReverse ? "0.75rem" : "1.5rem",
            filter: disable ? "grayscale(100%)" : "",
            backgroundClip: "padding-box",
          }}
        >
          {!isMobile && (
            <p
              className="has-text-weight-bold is-uppercase has-text-kurio-main-color-white has-text-left"
              style={{
                fontSize: fontSize,
                overFlowWrap: "break-word",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
            >
              {text}
            </p>
          )}
          {!hideIcon && (
            <img src="images/landing-page/icon-glyph-ldp.svg" alt="" />
          )}
        </button>
      </div>
    </>
  );
};

export default ButtonRegister;
