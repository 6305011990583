/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import {
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered
} from "../../reducers/examsQuestionSlice";
import IkmcExamQuestionsLeftWrapper from "../ikmc-exam-questions/left/IkmcExamQuestionsLeftWrapper";
import IkmcExamQuestionsRightWrapper from "../ikmc-exam-questions/right/IkmcExamQuestionsRightWrapper";

const PreviewExamContent = ({
  takeExamsQuestionId,
  examQuestions,
  seenQuestions,
  takeExamIdInfo,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [isPreview, setIsPreview] = useState(false);
  const [pagination, setPagination] = useState(
    localStorage.getItem("pagination")
      ? parseInt(localStorage.getItem("pagination"))
      : 1
  );
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const lastLoginKey = "lastLoginDate";
  const keys = Object.keys(localStorage);
  keys.forEach((key) => {
    if (key !== lastLoginKey && key !== "nameExam"&&
          key !== "accessToken") {
      localStorage.removeItem(key);
    }
  });
  const handleChangePagination = (pagination) => {
    setPagination(pagination);
  };
  cookies.remove("questionState");
  const handleQuestionChange = (val) => {
    if (val !== selectedQuestion) {
      if (
        val < 20 &&
        (!localStorage.getItem("pagination") ||
          parseInt(localStorage.getItem("pagination")) === 2)
      ) {
        localStorage.setItem("pagination", 1);
        setPagination(1);
      } else if (
        val >= 20 &&
        (!localStorage.getItem("pagination") ||
          parseInt(localStorage.getItem("pagination")) === 1)
      ) {
        localStorage.setItem("pagination", 2);
        setPagination(2);
      }
      const result = JSON.parse(localStorage.getItem("questionStateExams"));
      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
        // var submit = result.submit
        // dispatch(submitAnswers({submit, token, practiceQuizId:newPracticeId ? newPracticeId : practiceQuizId }));
      }
      setSelectedQuestion(val);
    }
  };

  return (
    <div>
      <BackgroundImageFromUrl
        className="columns is-mobile m-0 p-0"
        objectId={"admin/frontend-images/hub/background-exam-vault.jpg"}
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          // backgroundImage: "url('/images/hub/background-exam-vault.jpg')",
          backgroundAttachment: "fixed",
        }}
      >
        {examQuestions && (
          <div
            style={{
              // backgroundColor: checkingState ? "#F7F1DF" : "#DEEBF7",
              minHeight: "100vh",
              padding: "5rem 2.5rem 10rem 2.5rem",
              marginRight: "18.75rem",
              width: "calc(100% - 18.75rem)",
            }}
            className="column is-four-fifths is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
          >
            <IkmcExamQuestionsRightWrapper
              question={examQuestions[selectedQuestion]}
              isPreview={isPreview}
              selectedQuestion={selectedQuestion}
              seenQuestions={seenQuestions}
              handleQuestionChange={handleQuestionChange}
              questions={examQuestions}
              takeExamsQuestionId={takeExamsQuestionId}
            />
          </div>
        )}
        <div
          className="column m-0 is-one-fifth p-0 is-justify-content-center is-align-item-flex-start"
          style={{
            position: "fixed",
            right: "0",
            display: "flex",
            zIndex: 1000,
            flexDirection: "column",
            width: "18.75rem",
            height: "100%",
          }}
        >
          <IkmcExamQuestionsLeftWrapper
            selectedIndex={selectedQuestion}
            isPreview={isPreview}
            setIsPreview={setIsPreview}
            handleQuestionChange={handleQuestionChange}
            handleChangePagination={(pagination) =>
              handleChangePagination(pagination)
            }
            takeExamsQuestionId={takeExamsQuestionId}
            pagination={pagination}
          />
        </div>
      </BackgroundImageFromUrl>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    takeExamIdInfo: state.examsQuestion?.examInformation?.takeExamId,
    takeExamsQuestionId: state.examsQuestion.takeExamsQuestion,
    takeExamIdInformation: state.examsQuestion.takeExamIdInformation,
    takeExamIdLPInformation: state.examsQuestion.takeExamIdLPInformation,
    examQuestions: state.examsQuestion.questions,
    seenQuestions: state.examsQuestion.seenQuestions,
  };
}

export default connect(mapStateToProps)(PreviewExamContent);
