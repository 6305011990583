import React, { useState } from "react";
import { handleSelectedColor } from "./SelectedColor";
import "./ColorSelectionGame.css";
import ScrollRefWrapper from "../../../detail-components/ScrollRefWrapper";

const colors = ["#50e250", "#da0eda", "red", "yellow"];

const levelBackgrounds = [
  "linear-gradient(135deg, #56d6d6, #fff)",
  "linear-gradient(135deg, #b785f5, #fff)",
  "linear-gradient(135deg, #f4b3a8, #fff)",
  "linear-gradient(135deg, #f4bdde, #fff)",
  "linear-gradient(135deg, #b5eaca, #fff)",
  "linear-gradient(135deg, #f7ecc1, #fff)",
];

function ColorSelectionGame({ gridsConfig }) {
  const [selectedColor, setSelectedColor] = useState("#50e250");
  const [showSolution, setShowSolution] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(0);

  const [grid, setGrid] = useState(gridsConfig[0].grid);

  const [isCorrect, setIsCorrect] = useState(null);

  const toggleChallenge = () => {
    setIsOpen(true);
  };

  const handleSelectedLevel = (index) => {
    if (selectedLevel !== index) {
      setSelectedLevel(index);
      setGrid(gridsConfig[index].grid);
      handleResetGame(index);
    }
  };

  const handleResetGame = (index) => {
    const gridInit =
      index === 0
        ? [
            ["#50e250", "#50e250", null, "#da0eda", "#da0eda", "#da0eda"],
            [null, null, null, null, null, null],
            ["yellow", null, null, null, "#50e250", "#50e250"],
            ["yellow", null, null, null, null, null],
            [null, null, null, null, null, "yellow"],
            ["red", null, null, null, null, "yellow"],
          ]
        : index === 1
        ? [
            ["#50e250", null, "yellow", "yellow", null, "#da0eda"],
            [null, null, null, null, null, null],
            [null, null, null, null, null, null],
            [null, null, null, null, null, "#50e250"],
            [null, null, null, null, null, "yellow"],
            ["red", null, null, null, null, "yellow"],
          ]
        : index === 2
        ? [
            ["#50e250", null, null, null, "#50e250", null, "#da0eda"],
            ["#50e250", null, null, null, "#50e250", null, "#da0eda"],
            ["#50e250", null, null, null, null, null, null],
            [null, null, null, null, null, null, "yellow"],
            [null, null, null, null, null, null, null],
            [null, null, "#50e250", null, null, null, "#da0eda"],
            ["red", null, "#50e250", null, "yellow", "yellow", "yellow"],
          ]
        : index === 3
        ? [
            ["#50e250", null, "#da0eda", "#da0eda", "#da0eda", "#da0eda"],
            [null, null, null, null, null, null],
            ["#50e250", null, null, null, "#50e250", "#50e250"],
            ["#50e250", null, null, null, "#50e250", null],
            ["red", "red", null, null, null, null],
            ["red", null, null, null, null, "yellow"],
          ]
        : index === 4
        ? [
            [
              "#50e250",
              "red",
              null,
              "#50e250",
              null,
              "yellow",
              null,
              "#da0eda",
            ],
            [null, null, null, null, null, null, null, null],
            ["yellow", "yellow", null, null, null, null, null, null],
            [null, null, null, null, null, null, null, null],
            [null, null, null, null, null, null, "#da0eda", "#da0eda"],
            [null, null, null, null, null, null, null, null],
            ["yellow", "yellow", null, null, null, "#50e250", "#50e250", null],
            ["red", null, null, "red", "red", "red", "#50e250", "yellow"],
          ]
        : [
            [
              "#50e250",
              null,
              "yellow",
              "yellow",
              null,
              null,
              "yellow",
              "#da0eda",
            ],
            ["#50e250", null, null, "yellow", null, null, "yellow", null],
            ["#50e250", null, null, null, null, null, "#da0eda", null],
            [null, null, null, null, null, null, null, null],
            [null, null, null, null, null, "yellow", null, null],
            [null, null, null, null, null, null, null, null],
            ["red", null, null, null, null, "yellow", null, null],
            [
              "red",
              null,
              "yellow",
              "yellow",
              "yellow",
              "yellow",
              "#da0eda",
              "yellow",
            ],
          ];
    setGrid(gridInit);
    setIsCorrect(null);
  };

  const handleClick = (x, y) => {
    if (showSolution) return;

    const newGrid = [...grid];
    handleSelectedColor(selectedLevel, newGrid, selectedColor, x, y);
    setGrid(newGrid);
    setIsCorrect(null);
  };

  const checkSolution = () => {
    for (let i = 0; i < grid.length; i++) {
      for (let j = 0; j < gridsConfig.length; j++) {
        if (
          grid[i][j] !== gridsConfig[selectedLevel].initialSolutionGrid[i][j]
        ) {
          setIsCorrect(false);
          return;
        }
      }
    }
    setIsCorrect(true);
  };

  const getCellStyle = (i, j, isSolution) => {
    const cellColor = isSolution
      ? gridsConfig[selectedLevel].initialSolutionGrid[i][j]
      : grid[i][j];

    const style = {
      width: "3.125rem",
      height: "3.125rem",
      backgroundColor: cellColor || "white",
      borderTop: "1px solid black", // individual properties
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      borderLeft: "1px solid black",
      cursor: "pointer",
    };

    return {
      ...style,
      ...gridsConfig[selectedLevel].customStyles[`${i},${j}`],
    };
  };

  return (
    <>
      <ScrollRefWrapper />
      {/* <button onClick={() => setShowSolution(!showSolution)}>
        {showSolution ? "View Interactive Grid" : "View Solution"}
      </button> */}

      {!isOpen && (
        <div style={{ position: "sticky", top: "15rem", width: "11rem" }}>
          <button
            className="has-text-kurio-main-color-white has-background-kurio-main-color-black"
            style={{
              padding: "0.5rem 1rem",
              border: "none",
              borderRadius: "0.5rem",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              fontSize: "0.85rem",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
            onClick={toggleChallenge}
          >
            Chọn thử thách
          </button>
        </div>
      )}
      <div
        className={`challenge-menu ${
          isOpen ? "open" : ""
        } is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-flex-gap-5`}
      >
        <h2
          style={{
            paddingTop: "1rem",
            fontSize: "1rem",
            fontWeight: 700,
            textTransform: "uppercase",
          }}
          className="has-text-kurio-main-color-black "
        >
          Chọn Thử Thách
        </h2>
        <div className="challenge-grid">
          {levelBackgrounds.map((background, index) => (
            <div
              key={index}
              style={{
                background: background,
                border: selectedLevel === index ? "2px solid black" : "none",
              }}
              onClick={() => handleSelectedLevel(index)}
              className="challenge-button"
            >
              <p className="has-text-kurio-main-color-black">{index + 1}</p>
            </div>
          ))}
        </div>
      </div>

      <div
        onClick={() => setIsOpen(false)}
        style={{ paddingTop: isOpen ? "2.05rem" : 0 }}
        className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-flex-gap-6 pb-7 px-4"
      >
        <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-flex-gap-3">
          <p
            style={{
              fontSize: "1.2rem",
              textTransform: "uppercase",
              fontWeight: 700,
            }}
            className="has-text-kurio-main-color-black"
          >
            Tô màu
          </p>

          <div>
            <p
              style={{
                fontSize: "0.9rem",
                fontWeight: 700,
              }}
              className="has-text-kurio-main-color-black"
            >
              Cách chơi:
            </p>
            <p
              style={{
                fontSize: "0.85rem",
                fontWeight: 500,
              }}
              className="has-text-kurio-main-color-black"
            >
              Hãy tô màu mỗi miền còn trống bằng một trong bốn màu: xanh lá,
              tím, đỏ và vàng sao cho hai miền kề nhau bất kỳ được tô mà khác
              nhau.
            </p>
          </div>
        </div>
        <div
          style={{
            padding: "0.2rem 0.75rem",
            borderRadius: "0.5rem",
            background:
              selectedLevel === 0
                ? "#acefef"
                : selectedLevel === 1
                ? "#dfbffe"
                : selectedLevel === 2
                ? "#f4b3a8"
                : selectedLevel === 3
                ? "#f4bdde"
                : selectedLevel === 4
                ? "#b5eaca"
                : "#f7ecc1",
          }}
        >
          <p
            style={{
              fontSize: "1.2rem",
              textTransform: "uppercase",
              fontWeight: 700,
            }}
            className="has-text-kurio-main-color-black"
          >
            Thử thách {selectedLevel + 1}
          </p>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `repeat(${grid.length}, 50px)`,
            justifyContent: "center",
          }}
        >
          {(showSolution
            ? gridsConfig[selectedLevel].initialSolutionGrid
            : grid
          ).map((row, i) =>
            row.map((cell, j) => (
              <div
                key={`${i}-${j}`}
                onClick={() => handleClick(i, j)}
                style={getCellStyle(i, j, showSolution)}
              />
            ))
          )}
        </div>

        <div className="selected-color">
          <p
            style={{
              fontSize: "1rem",
              textTransform: "uppercase",
              fontWeight: 700,
              lineHeight: "normal",
              flex: 1,
            }}
            className="has-text-kurio-main-color-black"
          >
            Chọn màu
          </p>
          <div className="box-color">
            {colors.map((color) => (
              <button
                key={color}
                onClick={() => setSelectedColor(color)}
                className="has-text-kurio-main-color-black"
                style={{
                  backgroundColor: color,
                  width: "5rem",
                  height: "3rem",
                  marginRight: "0.5rem",
                  cursor: "pointer",
                  border: selectedColor === color ? "2px solid black" : "none",
                  borderRadius: "0.5rem",
                  fontSize: "1rem",
                  fontWeight: 700,
                }}
              ></button>
            ))}
          </div>
        </div>

        {isCorrect === true && (
          <div className="correct-message">Đúng rồi, làm tốt lắm!</div>
        )}

        {isCorrect === false && (
          <div className="incorrect-message">Không đúng, hãy thử lại!</div>
        )}

        <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center is-flex-gap-4">
          <div
            className=" is-flex is-align-items-center is-justify-content-center is-uppercase has-text-weight-bold has-text-kurio-main-color-white has-background-kurio-main-color-black"
            style={{
              height: "3rem",
              borderRadius: "1rem",
              width: "8rem",
              fontSize: "1rem",
              cursor: "pointer",
            }}
            onClick={() => {
              const isAnyRowNull = grid.some((row) =>
                row.some((item) => item === null)
              );
              if (!isAnyRowNull) {
                checkSolution();
              }
            }}
          >
            Kiểm tra
          </div>
          <div
            className=" is-flex is-align-items-center is-justify-content-center is-uppercase has-text-weight-bold has-text-kurio-main-color-black "
            style={{
              height: "3rem",
              borderRadius: "1rem",
              width: "8rem",
              fontSize: "1rem",
              border: "2px solid",
              cursor: "pointer",
            }}
            onClick={() => handleResetGame(selectedLevel)}
          >
            Chơi lại
          </div>
        </div>
      </div>
    </>
  );
}

export default ColorSelectionGame;
