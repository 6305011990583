import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import RegisterMobilePage from "../layout-components/landing-page/RegisterMobilePage";
import ResizeLandingPage from "../layout-components/landing-page/ResizeLandingPage";

const LoadingBeforeRedirect = () => {
  const cookies = new Cookies();
  const token = cookies.get("signinUser") || cookies.get("register");
  const handleClick = () => {
    window.location.href = process.env.REACT_APP_LANDING_PAGE_URL;
  };
  useEffect(() => {
    if (!token) {
      if (
        !window.location.pathname.includes("signin") &&
        !window.location.pathname.includes("validate-email") &&
        !window.location.pathname.includes("reset-password") &&
        !window.location.pathname.includes("calibration") &&
        !window.location.pathname.includes("register")
      ) {
        // window.location.href =
        //   process.env.REACT_APP_ENV === "development"
        //     ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
        //     : process.env.REACT_APP_LANDING_PAGE_URL;
      }
    }
  }, [token]);
  return (
    <>
      {!window.location.pathname.includes("signin") &&
      !window.location.pathname.includes("validate-email") &&
      !window.location.pathname.includes("reset-password") &&
      !window.location.pathname.includes("calibration") &&
      !window.location.pathname.includes("register") ? (
        <>
          {token ? (
            <RegisterMobilePage />
          ) : (
            <>
              {/* <LandingPage /> */}
              <ResizeLandingPage />
            </>
          )}
        </>
      ) : (
        <RegisterMobilePage />
      )}
    </>
  );
};

export default LoadingBeforeRedirect;
