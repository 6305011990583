import axios from "axios";
import Cookies from "universal-cookie";
import checkAndCallDailyLoginAPI from "./checkAndCallDailyLoginApi";

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === "development"
      ? process.env.REACT_APP_DEV_BASE_URL
      : process.env.REACT_APP_BASE_URL,
  timeout: 20000,
});
//
const urlArray = [
  "create-register",
  "confirm-register-otp",
  "register",
  "create-reset-password-phone",
  "create-reset-password-email",
  "confirm-reset-password-phone-otp",
  "reset-password-phone",
  "reset-password",
  "add-user-session",
  "password-service",
  "check-reset-password-email-request",
  "reset-password-email",
  "token",
  "/practice/v1/admin/exam/get-an-exam",
  "/lesson/v1/admin/lesson",
  "/practice/v1/admin/quiz",
  "/practice/v1/admin/question",
  "/practice/v1/user/road-map/anonymous/get-a-road-map",
  "/practice/v1/user/take-road-map-exam/anonymous/take-exam",
  "/practice/v1/user/take-road-map-exam/anonymous/get-exam-questions",
  "/practice/v1/user/take-road-map-exam/anonymous/submit-exam-answers",
  "/practice/v1/user/take-road-map-exam/anonymous/get-exam-solutions",
  "/practice/v1/user/take-road-map-problem-set/anonymous/take-problem-set",
  "/practice/v1/user/take-road-map-problem-set/anonymous/get-problem-set-questions",
  "/practice/v1/user/take-road-map-problem-set/anonymous/submit-problem-set-answers",
  "/practice/v1/user/take-road-map-problem-set/anonymous/get-problem-set-solutions",
  "/practice/v1/user/take-road-map-problem-set/anonymous/restart",
  "/practice/v1/user/road-map-category/get-all-road-map-categorys",
  "save-pre-registration",
];
const arrayPath = [
  "/account",
  "/profile",
  "/upgrade",
  "/list-unit",
  "/exercise",
  "/activities",
  "/achievement",
  "/",
];
let isDailyLoginInProgress = false; // Biến kiểm tra xem API dailyLogin đã được gọi hay chưa
let dailyLoginPromise = null;
let apiCalls = [];
let timer = null;

const cookies = new Cookies();
const apiCallTimeouts = {};
// TODO: fix lại homepage và 1 số màn khác để bỏ excludedApis
const excludedApis = [
  "get-user-info",
  "get-a-lesson",
  "get-an-unit",
  "submit-exam-answers",
  "submit-practice-answers",
];

const isExcludedApi = (requestUrl) => {
  return excludedApis.some((api) => requestUrl.includes(api));
};

instance.interceptors.request.use(
  async function (request) {
    let token = cookies.get("signinUser") || cookies.get("register");

    const refreshToken = cookies.get("refreshToken");

    // check daily login
    const lastLoginDate = localStorage.getItem("lastLoginDate");
    var checkUrl = false;
    for (let index = 0; index < urlArray.length; index++) {
      if (request.url.includes(urlArray[index])) {
        checkUrl = true;
        break;
      }
    }
    const currentTime = new Date().getTime();

    if (!isExcludedApi(request.url)) {
      const apiCallTimeout = apiCallTimeouts[request.url];
      if (apiCallTimeout && currentTime - apiCallTimeout < 300) {
        return;
      }
    }
    apiCallTimeouts[request.url] = currentTime;

    const refreshAccessToken = async (accessToken, refreshToken) => {
      try {
        const response = await instance.get(
          "/auth/v1/user/account/refresh-access-token",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Refresh-Token": refreshToken,
            },
          }
        );
        return response?.result; // Ensure it returns the result object
      } catch (error) {
        console.error("Error refreshing access token:", error);
        throw error; // Propagate the error for further handling
      }
    };

    if (
      !token &&
      refreshToken &&
      !request.url.includes("refresh-access-token")
    ) {
      try {
        const response = await refreshAccessToken(
          localStorage.getItem("accessToken"),
          refreshToken
        );

        if (response) {
          window.location.reload();
          request.headers.Authorization = `Bearer ${response.accessToken}`;
          token = response.accessToken;
          cookies.set("signinUser", response.accessToken);
          sessionStorage.setItem("accessToken", response.accessToken);
          cookies.set("refreshToken", response.refreshToken);
        }
      } catch (error) {
        window.location.href =
          process.env.REACT_APP_ENV === "development"
            ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
            : process.env.REACT_APP_LANDING_PAGE_URL;
        throw error;
      }
    } else {
      if (!checkUrl) {
        request.headers.Authorization = `Bearer ${token}`;
      }
    }

    if (token !== undefined) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    if (!isDailyLoginInProgress && !checkUrl && !lastLoginDate && token) {
      // Nếu chưa có API dailyLogin đang được gọi, thực hiện gọi API dailyLogin
      isDailyLoginInProgress = true;

      try {
        dailyLoginPromise = checkAndCallDailyLoginAPI(); // Lưu Promise của API dailyLogin để các API khác có thể chờ
        await dailyLoginPromise; // Chờ API dailyLogin hoàn thành
      } catch (error) {
        // Xử lý lỗi nếu có
      } finally {
        isDailyLoginInProgress = false;
      }
    } else if (lastLoginDate && !isDailyLoginInProgress && !checkUrl) {
      isDailyLoginInProgress = true;

      try {
        dailyLoginPromise = checkAndCallDailyLoginAPI(); // Lưu Promise của API dailyLogin để các API khác có thể chờ
        await dailyLoginPromise; // Chờ API dailyLogin hoàn thành
      } catch (error) {
        // Xử lý lỗi nếu có
      } finally {
        isDailyLoginInProgress = false;
      }
    } else if (dailyLoginPromise) {
      await dailyLoginPromise;
    }

    // tracking activities
    const sendApiAddSession = (location) => {
      if (apiCalls.length > 0) {
        instance.post(
          "/tracking/v1/user/add-user-session",
          {
            listAPIs: apiCalls,
            route: location,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        apiCalls = [];
      }
    };

    if (checkUrl) {
      return request;
    }
    apiCalls.push(request.url);
    let initialLocation = window.location.pathname;

    if (timer === null) {
      timer = setTimeout(() => {
        sendApiAddSession(window.location.pathname);
        timer = null;
      }, 30000);
    }

    // Check for location change
    let checkLocation = function () {
      if (window.location.pathname !== initialLocation) {
        initialLocation = window.location.pathname;
        clearTimeout(timer);
        sendApiAddSession(initialLocation);
        timer = setTimeout(() => {
          sendApiAddSession(window.location.pathname);
          timer = null;
        }, 30000);
      }
      setTimeout(checkLocation, 100); // Call the function again after 100 milliseconds
    };

    checkLocation();
    request.url += window.location.search;
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (
      !error.response &&
      !window.location.pathname.includes("error") &&
      !window.location.pathname.includes("signin") &&
      !window.location.pathname.includes("validate-email") &&
      !window.location.pathname.includes("reset-password") &&
      !window.location.pathname.includes("reset-password-email") &&
      !window.location.pathname.includes("calibration") &&
      !window.location.pathname.includes("register")
    ) {
      sessionStorage.setItem("error", error?.message);
      // window.location.href = window.location.origin + "/error";
    } else if (error.response?.status === 303) {
      return error.response.data;
    } else if (error.response?.status === 401) {
      if (
        !window.location.pathname.includes("preview") &&
        cookies.get("signinUser", { path: "/" })
      ) {
        const listCookies = cookies.getAll();
        Object.keys(listCookies).forEach((cookie) => {
          cookies.remove(cookie);
        });
        arrayPath
          .forEach((path) => {
            if (cookies.get("signinUser", { path: path })) {
              cookies.remove("signinUser", { path: path });
            }
          })
          ?.then(() => {
            window.location.href =
              process.env.REACT_APP_ENV === "development"
                ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
                : process.env.REACT_APP_LANDING_PAGE_URL;
          })
          .catch((error) => {
            console.error("Error removing cookie:", error);
          });
        const check = arrayPath.every((path) =>
          cookies.get("signinUser", { path: path })
        );
        if (!check) {
          window.location.href =
            process.env.REACT_APP_ENV === "development"
              ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
              : process.env.REACT_APP_LANDING_PAGE_URL;
        }
        cookies
          .remove("signinUser", { path: "/" })
          .remove("refreshToken", { path: "/" })
          .then(() => {
            window.location.href =
              process.env.REACT_APP_ENV === "development"
                ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
                : process.env.REACT_APP_LANDING_PAGE_URL;
          })
          .catch((error) => {
            console.error("Error removing cookie:", error);
          });
      } else {
        window.location.href =
          process.env.REACT_APP_ENV === "development"
            ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
            : process.env.REACT_APP_LANDING_PAGE_URL;
      }
    } else {
      if (error.response?.status === 400) {
        if (
          error?.response?.data?.errorMessage.includes(
            "the provided hex string is not a valid ObjectID"
          )
        ) {
          sessionStorage.setItem(
            "error",
            error?.response?.data?.errorMessage || error?.message
          );
          // window.location.href = "/error";
        }
        if (!window.location.pathname.includes("validate-phone")) {
          // console.log(error?.response?.data?.errorMessage);
        }
      } else if (
        !window.location.pathname.includes("error") &&
        !window.location.pathname.includes("signin") &&
        !window.location.pathname.includes("validate-email") &&
        !window.location.pathname.includes("reset-password") &&
        !window.location.pathname.includes("reset-password-email") &&
        !window.location.pathname.includes("calibration") &&
        !window.location.pathname.includes("register") &&
        error.response?.status !== 409
      ) {
        sessionStorage.setItem(
          "error",
          error?.response?.data?.errorMessage || error?.message
        );
        // window.location.href = window.location.origin + "/error";
      }
      return Promise.reject(error);
    }
  }
);

export default instance;
