import React from "react";

const GoalItem = ({
  item,
  isSelected = false,
  handleSelect,
  isMobile = false,
}) => {
  return (
    <div
      className={`${
        isSelected
          ? "has-text-kurio-main-color-purple has-text-weight-bold"
          : "has-text-kurio-main-color-black has-text-weight-medium"
      } is-flex is-align-items-center px-4 py-3 is-clickable goal-item`}
      style={{
        gap: "0.75rem",
        background: isSelected ? "#E6DFFE" : "#FAFBFC",
        width: isMobile ? "auto" : "15.75rem",
        borderRadius: "1rem",
      }}
      onClick={() => handleSelect(item.order)}
    >
      <p
        className={`${
          isSelected ? "has-background-kurio-main-color-purple" : ""
        } is-flex is-align-items-center is-justify-content-center is-clickable`}
        style={{
          minWidth: "1.5rem",
          height: "1.5rem",
          borderRadius: "0.5rem",
          backgroundColor: isSelected ? "#572CD1" : "#E8E3F8",
          border: isSelected ? "0.125rem solid #572CD1" : "none",
        }}
      >
        {isSelected && (
          <img
            style={{ objectFit: "cover" }}
            src="/images/landing-page/ic-select.svg"
            alt=""
          />
        )}
      </p>
      <p
        style={{
          fontSize: "0.75rem",
          lineHeight: "normal",
          lineStyle: "normal",
          width: "10.75rem",
        }}
      >
        {item?.content}
      </p>
    </div>
  );
};

export default GoalItem;
