import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import NextButton from "../../components/button/pagination-button/NextButton";
import PreviousButton from "../../components/button/pagination-button/PreviousButton";
import PrimaryButtonLevelSection from "../../components/button/primary-button/PrimaryButtonLevelSection";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import "./StaticGames.style.css";

// Set đường dẫn tới worker của PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StaticGames = () => {
  const [isPreview, setIsPreview] = useState(false);
  const [urlPreview, setUrlPreview] = useState("");
  const prodUrl = "https://d1l29ndmp37bv9.cloudfront.net/";

  const array = [
    {
      name: "Bài Toán Nối",
      objectId: prodUrl + "admin/demo/2.b%C3%A0i+to%C3%A1n+n%E1%BB%91i+.png",
      urlPDF: prodUrl + "admin/demo/BA%CC%80I+TOA%CC%81N+NO%CC%82%CC%81I.pdf",
    },
    {
      name: "Tô Màu",
      objectId: prodUrl + "admin/demo/3.t%C3%B4+m%C3%A0u+.png",
      urlPDF: prodUrl + "admin/demo/28.02-TO%CC%82+MA%CC%80U.pdf",
    },
    {
      name: "Cắt Dán 3D",
      objectId: prodUrl + "admin/demo/1.c%E1%BA%AFt+d%C3%A1n+3D+.png",
      urlPDF: prodUrl + "admin/demo/30.11-CA%CC%86%CC%81T+DA%CC%81N+3D.pdf",
    },
    {
      name: "Mật Mã",
      objectId: prodUrl + "admin/demo/4.m%E1%BA%ADt+m%C3%A3+.png",
      urlPDF: prodUrl + "admin/demo/28.02-MA%CC%A3%CC%82T+MA%CC%83.pdf",
    },
    {
      name: "Lật Rùa",
      objectId: prodUrl + "admin/demo/la%CC%A3%CC%82t+ru%CC%80a.png",
      urlPDF:
        prodUrl +
        "admin/demo/28.02-PHIE%CC%82%CC%81U+CHO%CC%9BI+LA%CC%A3%CC%82T+RU%CC%80A.pdf",
    },
    {
      name: "Logix",
      objectId: prodUrl + "admin/demo/6.logix+.png",
      urlPDF: prodUrl + "admin/demo/28.02-LOGIX.pdf",
    },
    {
      name: "Giờ Cao Điểm",
      objectId: prodUrl + "admin/demo/7.gi%E1%BB%9D+cao+%C4%91i%E1%BB%83m+.png",
      urlPDF:
        prodUrl + "admin/demo/GIO%CC%9B%CC%80+CAO+%C4%90IE%CC%82%CC%89M.pdf",
    },
    {
      name: "Sandwich Sudoku",
      objectId: prodUrl + "admin/demo/8.sandwich+sudoku+.png",
      urlPDF:
        prodUrl +
        "admin/demo/28.02-PHIE%CC%82%CC%81U+CHO%CC%9BI+SANDWICH+SUDOKU.pdf",
    },
    {
      name: "Tangram",
      objectId: prodUrl + "admin/demo/9.tangram+.png",
      urlPDF: prodUrl + "admin/demo/28.02-TANGRAM.pdf",
    },
    {
      name: "Vẽ 1 Nét",
      objectId: prodUrl + "admin/demo/10.v%E1%BA%BD+1+n%C3%A9t+.png",
      urlPDF: prodUrl + "admin/demo/VE%CC%83+1+NE%CC%81T.pdf",
    },
  ];
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const token = cookies.get("signinUser") || cookies.get("register");

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
  }
  const onPageLoadSuccess = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };
  const handleClickPreview = (url) => {
    setUrlPreview(url);
    setPageNumber(1);
    setIsPreview(true);
    setIsLoading(true);
  };
  const removePopup = () => {
    setIsPreview(false);
    setNumPages(null);
    setPageNumber(1);
    setUrlPreview("");
  };
  const handleCopyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    alert("Copied to clipboard");
  };
  const handleClickDownload = (url) => {
    var pdfFilename = url.substring(url.lastIndexOf("/") + 1);
    pdfFilename = decodeURI(pdfFilename);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Tạo một URL tạm thời cho tệp PDF
        const url = window.URL.createObjectURL(new Blob([blob]));

        // Tạo một thẻ a để tải tệp PDF
        const a = document.createElement("a");
        a.href = url;
        a.download = pdfFilename;

        // Thêm thẻ a vào body và kích hoạt sự kiện click
        document.body.appendChild(a);
        a.click();

        // Xóa URL tạm thời
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };

  // Tính tỷ lệ chiều dài/rộng của tờ A4
  const a4AspectRatio = 297 / 210; // Chiều dài / Chiều rộng

  // Tính toán kích thước của trang PDF
  const calculatePageSize = () => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const isWidthGreaterThanHeight =
      viewportWidth * a4AspectRatio > viewportHeight;
    let width, height;

    if (isWidthGreaterThanHeight) {
      height = viewportHeight * 0.9;
      width = height / a4AspectRatio;
    } else {
      width = viewportWidth <= 700 ? viewportWidth * 0.9 : viewportWidth * 0.4;
      height = width * a4AspectRatio;
    }

    return { width, height };
  };

  const { width, height } = calculatePageSize();

  return (
    <BackgroundImageFromUrl
      objectId="admin/frontend-images/hub/background-game.svg"
      className="is-flex is-justify-content-center"
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
      }}
    >
      <ScrollRefWrapper />
      <div
        className="is-flex is-flex-direction-column is-align-items-center"
        style={{ maxWidth: "80rem" }}
      >
        <div
          className="game-header-desktop is-align-items-center is-flex-wrap-wrap is-flex-gap-8 has-background-kurio-main-color-white"
          style={{ width: "100%", padding: "0.5rem 1.5rem" }}
        >
          <div className="is-flex is-flex-gap-4 is-justify-content-center is-align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M16.0727 21.0624C17.3315 19.2648 18.6407 16.6212 18.9023 13.2H22.4483C21.9887 16.8624 19.4615 19.884 16.0727 21.0624ZM3.4151 13.2H7.0055C7.2527 16.6344 8.5451 19.2672 9.8207 21.0732C6.4163 19.9008 3.8771 16.8732 3.4151 13.2ZM9.8579 2.91479C8.5955 4.7124 7.2743 7.3644 7.0103 10.8H3.4151C3.8783 7.1136 6.4355 4.0764 9.8579 2.91479ZM9.42719 10.8C9.79438 6.8736 11.8032 4.14 12.9636 2.8764C14.1636 4.1616 16.1412 6.87 16.4832 10.8H9.42719ZM9.42828 13.2H16.4867C16.1195 17.1324 14.1059 19.866 12.9467 21.1296C11.6903 19.7976 9.76308 17.1264 9.42828 13.2ZM22.4484 10.8H18.906C18.66 7.38119 17.382 4.75679 16.1124 2.95319C19.482 4.14119 21.9912 7.15199 22.4484 10.8ZM24.9324 12C24.9324 5.3952 19.5672 0.0216 12.966 0.0024C12.9612 0.0024 12.9564 0.0012 12.9504 0.0012C12.9456 0.0012 12.9384 0 12.9324 0C6.31557 0 0.932373 5.3844 0.932373 12C0.932373 18.6168 6.31557 24 12.9324 24C12.9396 24 12.9456 23.9988 12.9504 23.9988C12.9528 23.9988 12.954 24 12.9564 24C12.96 24 12.9636 23.9976 12.966 23.9976C19.5672 23.9796 24.9324 18.6048 24.9324 12Z"
                fill="#572CD1"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="83"
              height="16"
              viewBox="0 0 83 16"
              fill="none"
            >
              <path
                d="M78.2732 4.87C79.1265 4.87 79.8865 5.04333 80.5532 5.38999C81.2332 5.72333 81.7665 6.24333 82.1532 6.95C82.5398 7.64333 82.7332 8.53666 82.7332 9.62999V15.79H79.6132V10.11C79.6132 9.24333 79.4198 8.60333 79.0331 8.18999C78.6598 7.77666 78.1265 7.56999 77.4332 7.56999C76.9398 7.56999 76.4932 7.67666 76.0932 7.88999C75.7065 8.08999 75.3998 8.40333 75.1731 8.83C74.9598 9.25666 74.8531 9.80333 74.8531 10.47V15.79H71.7332V5.02999H74.7132V8.00999L74.1532 7.10999C74.5398 6.38999 75.0932 5.83666 75.8132 5.45C76.5332 5.06333 77.3532 4.87 78.2732 4.87Z"
                fill="#572CD1"
              />
              <path
                d="M62.6999 15.79L58.1799 5.03H61.3999L65.1599 14.29H63.5599L67.4599 5.03H70.4599L65.9199 15.79H62.6999Z"
                fill="#572CD1"
              />
              <path
                d="M56.3702 15.95C55.8368 15.95 55.3768 15.77 54.9902 15.41C54.6168 15.0366 54.4302 14.5633 54.4302 13.99C54.4302 13.4166 54.6168 12.9566 54.9902 12.61C55.3768 12.25 55.8368 12.07 56.3702 12.07C56.9168 12.07 57.3768 12.25 57.7502 12.61C58.1235 12.9566 58.3102 13.4166 58.3102 13.99C58.3102 14.5633 58.1235 15.0366 57.7502 15.41C57.3768 15.77 56.9168 15.95 56.3702 15.95Z"
                fill="#572CD1"
              />
              <path
                d="M47.3414 15.95C46.1947 15.95 45.1747 15.71 44.2814 15.23C43.4014 14.75 42.7014 14.0967 42.1814 13.27C41.6747 12.43 41.4214 11.4767 41.4214 10.41C41.4214 9.33 41.6747 8.37666 42.1814 7.54999C42.7014 6.70999 43.4014 6.05666 44.2814 5.58999C45.1747 5.11 46.1947 4.87 47.3414 4.87C48.4747 4.87 49.4881 5.11 50.3814 5.58999C51.2747 6.05666 51.9747 6.70333 52.4814 7.52999C52.9881 8.35666 53.2414 9.31666 53.2414 10.41C53.2414 11.4767 52.9881 12.43 52.4814 13.27C51.9747 14.0967 51.2747 14.75 50.3814 15.23C49.4881 15.71 48.4747 15.95 47.3414 15.95ZM47.3414 13.39C47.8614 13.39 48.3281 13.27 48.7414 13.03C49.1547 12.79 49.4814 12.45 49.7214 12.01C49.9614 11.5567 50.0814 11.0233 50.0814 10.41C50.0814 9.78333 49.9614 9.24999 49.7214 8.80999C49.4814 8.36999 49.1547 8.02999 48.7414 7.79C48.3281 7.55 47.8614 7.42999 47.3414 7.42999C46.8214 7.42999 46.3547 7.55 45.9414 7.79C45.5281 8.02999 45.1947 8.36999 44.9414 8.80999C44.7014 9.24999 44.5814 9.78333 44.5814 10.41C44.5814 11.0233 44.7014 11.5567 44.9414 12.01C45.1947 12.45 45.5281 12.79 45.9414 13.03C46.3547 13.27 46.8214 13.39 47.3414 13.39Z"
                fill="#572CD1"
              />
              <path
                d="M36.2059 15.79V5.02999H39.3259V15.79H36.2059ZM37.7659 3.52999C37.1926 3.52999 36.7259 3.36332 36.3659 3.02999C36.0059 2.69666 35.8259 2.28332 35.8259 1.78999C35.8259 1.29665 36.0059 0.883322 36.3659 0.549989C36.7259 0.216655 37.1926 0.0499878 37.7659 0.0499878C38.3393 0.0499878 38.8059 0.209988 39.1659 0.529989C39.5259 0.836655 39.7059 1.23666 39.7059 1.72999C39.7059 2.24999 39.5259 2.68332 39.1659 3.02999C38.8193 3.36332 38.3526 3.52999 37.7659 3.52999Z"
                fill="#572CD1"
              />
              <path
                d="M27.6707 15.79V5.02999H30.6507V8.06999L30.2307 7.18999C30.5507 6.42999 31.064 5.85666 31.7707 5.46999C32.4773 5.06999 33.3373 4.87 34.3507 4.87V7.75C34.2173 7.73666 34.0973 7.72999 33.9907 7.72999C33.884 7.71666 33.7707 7.71 33.6507 7.71C32.7973 7.71 32.104 7.95666 31.5707 8.45C31.0507 8.92999 30.7907 9.68333 30.7907 10.71V15.79H27.6707Z"
                fill="#572CD1"
              />
              <path
                d="M18.4801 15.95C17.5868 15.95 16.7868 15.7767 16.0801 15.43C15.3868 15.0833 14.8468 14.5567 14.4601 13.85C14.0735 13.13 13.8801 12.2167 13.8801 11.11V5.03H17.0001V10.65C17.0001 11.5433 17.1868 12.2033 17.5601 12.63C17.9468 13.0433 18.4868 13.25 19.1801 13.25C19.6601 13.25 20.0868 13.15 20.4601 12.95C20.8335 12.7367 21.1268 12.4167 21.3401 11.99C21.5535 11.55 21.6601 11.0033 21.6601 10.35V5.03H24.7801V15.79H21.8201V12.83L22.3601 13.69C22.0001 14.4367 21.4668 15.0033 20.7601 15.39C20.0668 15.7633 19.3068 15.95 18.4801 15.95Z"
                fill="#572CD1"
              />
              <path
                d="M3.51237 13.59L3.59237 9.78998L8.61237 5.02998H12.3324L7.51237 9.92998L5.89237 11.25L3.51237 13.59ZM0.932373 15.79V0.949982H4.05237V15.79H0.932373ZM8.95237 15.79L5.31237 11.27L7.27237 8.84998L12.7324 15.79H8.95237Z"
                fill="#572CD1"
              />
            </svg>
          </div>
          <div className="is-flex is-flex-gap-4 is-justify-content-center is-align-items-center">
            <img src="/images/games/icon-zalo.svg" alt="" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="130"
              height="16"
              viewBox="0 0 130 16"
              fill="none"
            >
              <path
                d="M123.393 15.24C122.247 15.24 121.22 14.96 120.313 14.4C119.407 13.8267 118.693 13 118.173 11.92C117.653 10.84 117.393 9.53334 117.393 8.00001C117.393 6.46668 117.653 5.16001 118.173 4.08001C118.693 3.00001 119.407 2.18001 120.313 1.62001C121.22 1.04668 122.247 0.76001 123.393 0.76001C124.553 0.76001 125.58 1.04668 126.473 1.62001C127.38 2.18001 128.093 3.00001 128.613 4.08001C129.133 5.16001 129.393 6.46668 129.393 8.00001C129.393 9.53334 129.133 10.84 128.613 11.92C128.093 13 127.38 13.8267 126.473 14.4C125.58 14.96 124.553 15.24 123.393 15.24ZM123.393 12.5C123.94 12.5 124.413 12.3467 124.813 12.04C125.227 11.7333 125.547 11.2467 125.773 10.58C126.013 9.91334 126.133 9.05334 126.133 8.00001C126.133 6.94668 126.013 6.08668 125.773 5.42001C125.547 4.75334 125.227 4.26668 124.813 3.96001C124.413 3.65334 123.94 3.50001 123.393 3.50001C122.86 3.50001 122.387 3.65334 121.973 3.96001C121.573 4.26668 121.253 4.75334 121.013 5.42001C120.787 6.08668 120.673 6.94668 120.673 8.00001C120.673 9.05334 120.787 9.91334 121.013 10.58C121.253 11.2467 121.573 11.7333 121.973 12.04C122.387 12.3467 122.86 12.5 123.393 12.5Z"
                fill="#572CD1"
              />
              <path
                d="M105.476 15V12.9L110.876 7.80001C111.303 7.41334 111.616 7.06668 111.816 6.76001C112.016 6.45334 112.15 6.17334 112.216 5.92001C112.296 5.66668 112.336 5.43334 112.336 5.22001C112.336 4.66001 112.143 4.23334 111.756 3.94001C111.383 3.63334 110.83 3.48001 110.096 3.48001C109.51 3.48001 108.963 3.59334 108.456 3.82001C107.963 4.04668 107.543 4.40001 107.196 4.88001L104.836 3.36001C105.37 2.56001 106.116 1.92668 107.076 1.46001C108.036 0.993343 109.143 0.76001 110.396 0.76001C111.436 0.76001 112.343 0.933343 113.116 1.28001C113.903 1.61334 114.51 2.08668 114.936 2.70001C115.376 3.31334 115.596 4.04668 115.596 4.90001C115.596 5.35334 115.536 5.80668 115.416 6.26001C115.31 6.70001 115.083 7.16668 114.736 7.66001C114.403 8.15334 113.91 8.70668 113.256 9.32001L108.776 13.54L108.156 12.36H116.056V15H105.476Z"
                fill="#572CD1"
              />
              <path
                d="M99.9245 15V2.2L101.325 3.6H97.1245V1H103.165V15H99.9245Z"
                fill="#572CD1"
              />
              <path
                d="M84.5261 15.24C83.3795 15.24 82.3528 14.96 81.4461 14.4C80.5395 13.8267 79.8261 13 79.3061 11.92C78.7861 10.84 78.5261 9.53334 78.5261 8.00001C78.5261 6.46668 78.7861 5.16001 79.3061 4.08001C79.8261 3.00001 80.5395 2.18001 81.4461 1.62001C82.3528 1.04668 83.3795 0.76001 84.5261 0.76001C85.6861 0.76001 86.7128 1.04668 87.6061 1.62001C88.5128 2.18001 89.2261 3.00001 89.7461 4.08001C90.2661 5.16001 90.5261 6.46668 90.5261 8.00001C90.5261 9.53334 90.2661 10.84 89.7461 11.92C89.2261 13 88.5128 13.8267 87.6061 14.4C86.7128 14.96 85.6861 15.24 84.5261 15.24ZM84.5261 12.5C85.0728 12.5 85.5461 12.3467 85.9461 12.04C86.3595 11.7333 86.6795 11.2467 86.9061 10.58C87.1461 9.91334 87.2661 9.05334 87.2661 8.00001C87.2661 6.94668 87.1461 6.08668 86.9061 5.42001C86.6795 4.75334 86.3595 4.26668 85.9461 3.96001C85.5461 3.65334 85.0728 3.50001 84.5261 3.50001C83.9928 3.50001 83.5195 3.65334 83.1061 3.96001C82.7061 4.26668 82.3861 4.75334 82.1461 5.42001C81.9195 6.08668 81.8061 6.94668 81.8061 8.00001C81.8061 9.05334 81.9195 9.91334 82.1461 10.58C82.3861 11.2467 82.7061 11.7333 83.1061 12.04C83.5195 12.3467 83.9928 12.5 84.5261 12.5Z"
                fill="#572CD1"
              />
              <path
                d="M70.9519 15.24C69.8052 15.24 68.7786 14.96 67.8719 14.4C66.9652 13.8267 66.2519 13 65.7319 11.92C65.2119 10.84 64.9519 9.53334 64.9519 8.00001C64.9519 6.46668 65.2119 5.16001 65.7319 4.08001C66.2519 3.00001 66.9652 2.18001 67.8719 1.62001C68.7786 1.04668 69.8052 0.76001 70.9519 0.76001C72.1119 0.76001 73.1386 1.04668 74.0319 1.62001C74.9386 2.18001 75.6519 3.00001 76.1719 4.08001C76.6919 5.16001 76.9519 6.46668 76.9519 8.00001C76.9519 9.53334 76.6919 10.84 76.1719 11.92C75.6519 13 74.9386 13.8267 74.0319 14.4C73.1386 14.96 72.1119 15.24 70.9519 15.24ZM70.9519 12.5C71.4986 12.5 71.9719 12.3467 72.3719 12.04C72.7852 11.7333 73.1052 11.2467 73.3319 10.58C73.5719 9.91334 73.6919 9.05334 73.6919 8.00001C73.6919 6.94668 73.5719 6.08668 73.3319 5.42001C73.1052 4.75334 72.7852 4.26668 72.3719 3.96001C71.9719 3.65334 71.4986 3.50001 70.9519 3.50001C70.4186 3.50001 69.9452 3.65334 69.5319 3.96001C69.1319 4.26668 68.8119 4.75334 68.5719 5.42001C68.3452 6.08668 68.2319 6.94668 68.2319 8.00001C68.2319 9.05334 68.3452 9.91334 68.5719 10.58C68.8119 11.2467 69.1319 11.7333 69.5319 12.04C69.9452 12.3467 70.4186 12.5 70.9519 12.5Z"
                fill="#572CD1"
              />
              <path
                d="M57.8173 15.24C56.844 15.24 55.8773 15.1133 54.9173 14.86C53.9706 14.5933 53.1573 14.22 52.4773 13.74L53.7573 11.26C54.2906 11.6467 54.904 11.9533 55.5973 12.18C56.304 12.4067 57.0173 12.52 57.7373 12.52C58.5506 12.52 59.1906 12.36 59.6573 12.04C60.124 11.72 60.3573 11.2733 60.3573 10.7C60.3573 10.34 60.264 10.02 60.0773 9.74C59.8906 9.46 59.5573 9.24667 59.0773 9.1C58.6106 8.95333 57.9506 8.88 57.0973 8.88H53.5173L54.2373 1H62.7773V3.6H55.3973L57.0773 2.12L56.5773 7.74L54.8973 6.26H57.8573C59.244 6.26 60.3573 6.45333 61.1973 6.84C62.0506 7.21333 62.6706 7.72667 63.0573 8.38C63.444 9.03333 63.6373 9.77333 63.6373 10.6C63.6373 11.4267 63.4306 12.1933 63.0173 12.9C62.604 13.5933 61.964 14.16 61.0973 14.6C60.244 15.0267 59.1506 15.24 57.8173 15.24Z"
                fill="#572CD1"
              />
              <path
                d="M41.7214 15V2.2L43.1214 3.6H38.9214V1H44.9614V15H41.7214Z"
                fill="#572CD1"
              />
              <path
                d="M29.2036 15L34.8436 2.24L35.6836 3.64H28.1436L29.5836 2.12V5.92H26.7036V1H37.9236V3.1L32.7236 15H29.2036Z"
                fill="#572CD1"
              />
              <path
                d="M19.2275 0.76001C20.5208 0.76001 21.6342 1.02668 22.5675 1.56001C23.5008 2.09334 24.2208 2.87334 24.7275 3.90001C25.2342 4.91334 25.4875 6.18001 25.4875 7.70001C25.4875 9.31334 25.1808 10.68 24.5675 11.8C23.9675 12.92 23.1342 13.7733 22.0675 14.36C21.0008 14.9467 19.7608 15.24 18.3475 15.24C17.6142 15.24 16.9075 15.16 16.2275 15C15.5475 14.84 14.9608 14.6 14.4675 14.28L15.6675 11.9C16.0542 12.1667 16.4675 12.3533 16.9075 12.46C17.3475 12.5533 17.8075 12.6 18.2875 12.6C19.4875 12.6 20.4408 12.2333 21.1475 11.5C21.8675 10.7667 22.2275 9.68001 22.2275 8.24001C22.2275 8.00001 22.2208 7.73334 22.2075 7.44001C22.1942 7.14668 22.1608 6.85334 22.1075 6.56001L22.9875 7.40001C22.7608 7.92001 22.4408 8.36001 22.0275 8.72001C21.6142 9.06668 21.1342 9.33334 20.5875 9.52001C20.0408 9.69334 19.4275 9.78001 18.7475 9.78001C17.8542 9.78001 17.0408 9.60001 16.3075 9.24001C15.5875 8.88001 15.0075 8.37334 14.5675 7.72001C14.1408 7.06668 13.9275 6.30001 13.9275 5.42001C13.9275 4.46001 14.1608 3.63334 14.6275 2.94001C15.1075 2.24668 15.7475 1.71334 16.5475 1.34001C17.3608 0.953343 18.2542 0.76001 19.2275 0.76001ZM19.4275 3.18001C18.9608 3.18001 18.5542 3.26668 18.2075 3.44001C17.8608 3.60001 17.5875 3.84001 17.3875 4.16001C17.1875 4.46668 17.0875 4.83334 17.0875 5.26001C17.0875 5.90001 17.3008 6.41334 17.7275 6.80001C18.1675 7.17334 18.7475 7.36001 19.4675 7.36001C19.9342 7.36001 20.3475 7.27334 20.7075 7.10001C21.0808 6.91334 21.3675 6.66001 21.5675 6.34001C21.7675 6.02001 21.8675 5.66001 21.8675 5.26001C21.8675 4.86001 21.7675 4.50668 21.5675 4.20001C21.3808 3.88001 21.1075 3.63334 20.7475 3.46001C20.3875 3.27334 19.9475 3.18001 19.4275 3.18001Z"
                fill="#572CD1"
              />
              <path
                d="M6.73315 15.24C5.58649 15.24 4.55982 14.96 3.65315 14.4C2.74649 13.8267 2.03315 13 1.51315 11.92C0.993154 10.84 0.733154 9.53334 0.733154 8.00001C0.733154 6.46668 0.993154 5.16001 1.51315 4.08001C2.03315 3.00001 2.74649 2.18001 3.65315 1.62001C4.55982 1.04668 5.58649 0.76001 6.73315 0.76001C7.89315 0.76001 8.91982 1.04668 9.81316 1.62001C10.7198 2.18001 11.4332 3.00001 11.9532 4.08001C12.4732 5.16001 12.7332 6.46668 12.7332 8.00001C12.7332 9.53334 12.4732 10.84 11.9532 11.92C11.4332 13 10.7198 13.8267 9.81316 14.4C8.91982 14.96 7.89315 15.24 6.73315 15.24ZM6.73315 12.5C7.27982 12.5 7.75315 12.3467 8.15315 12.04C8.56649 11.7333 8.88649 11.2467 9.11316 10.58C9.35316 9.91334 9.47316 9.05334 9.47316 8.00001C9.47316 6.94668 9.35316 6.08668 9.11316 5.42001C8.88649 4.75334 8.56649 4.26668 8.15315 3.96001C7.75315 3.65334 7.27982 3.50001 6.73315 3.50001C6.19982 3.50001 5.72649 3.65334 5.31315 3.96001C4.91315 4.26668 4.59315 4.75334 4.35315 5.42001C4.12649 6.08668 4.01315 6.94668 4.01315 8.00001C4.01315 9.05334 4.12649 9.91334 4.35315 10.58C4.59315 11.2467 4.91315 11.7333 5.31315 12.04C5.72649 12.3467 6.19982 12.5 6.73315 12.5Z"
                fill="#572CD1"
              />
            </svg>
          </div>
          <div className="is-flex is-flex-gap-4 is-justify-content-center is-align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M5.91331 2.4C4.19251 2.4 2.79331 3.7992 2.79331 5.52C2.79331 14.3856 10.0065 21.6 18.8733 21.6C20.5929 21.6 21.9933 20.2008 21.9933 18.48C21.9933 18.3468 21.9849 18.216 21.9681 18.0864L16.4241 16.8144C16.3137 17.0256 16.1925 17.2548 16.0821 17.4684C15.5349 18.5124 15.1389 19.2708 14.1285 18.864C10.1613 17.472 6.92251 14.232 5.51251 10.2168C5.08411 9.2748 5.90011 8.8476 6.93451 8.3076C7.14451 8.1972 7.37131 8.0784 7.57891 7.9704L6.30691 2.4252C6.17371 2.4084 6.04411 2.4 5.91331 2.4ZM18.8733 24C8.68291 24 0.393311 15.7104 0.393311 5.52C0.393311 2.4756 2.86891 0 5.91331 0C6.22531 0 6.53371 0.0264 6.83251 0.078C7.12411 0.1248 7.41691 0.1992 7.70251 0.2976C8.09251 0.4332 8.38651 0.7608 8.47891 1.164L10.1217 8.3208C10.2129 8.7144 10.0989 9.1272 9.81811 9.42C9.65491 9.5904 9.65131 9.594 8.16331 10.3728C9.36811 12.9816 11.4621 15.0684 14.0181 16.2324C14.7981 14.7432 14.8029 14.7384 14.9733 14.5752C15.2661 14.2944 15.6813 14.1852 16.0725 14.2716L23.2305 15.9144C23.6325 16.0068 23.9601 16.2996 24.0945 16.6896C24.1929 16.9728 24.2673 17.2656 24.3165 17.5668C24.3669 17.8632 24.3933 18.1704 24.3933 18.48C24.3933 21.5244 21.9165 24 18.8733 24Z"
                fill="#572CD1"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="99"
              height="16"
              viewBox="0 0 99 16"
              fill="none"
            >
              <path
                d="M89.3474 15L94.9874 2.24L95.8274 3.64H88.2874L89.7274 2.12V5.92H86.8474V1H98.0674V3.1L92.8674 15H89.3474Z"
                fill="#572CD1"
              />
              <path
                d="M80.2074 15.24C79.2341 15.24 78.2674 15.1133 77.3074 14.86C76.3608 14.5933 75.5474 14.22 74.8674 13.74L76.1474 11.26C76.6808 11.6467 77.2941 11.9533 77.9874 12.18C78.6941 12.4067 79.4074 12.52 80.1274 12.52C80.9408 12.52 81.5808 12.36 82.0474 12.04C82.5141 11.72 82.7474 11.2733 82.7474 10.7C82.7474 10.34 82.6541 10.02 82.4674 9.74C82.2808 9.46 81.9474 9.24667 81.4674 9.1C81.0008 8.95333 80.3408 8.88 79.4874 8.88H75.9074L76.6274 1H85.1674V3.6H77.7874L79.4674 2.12L78.9674 7.74L77.2874 6.26H80.2474C81.6341 6.26 82.7474 6.45333 83.5874 6.84C84.4408 7.21333 85.0608 7.72667 85.4474 8.38C85.8341 9.03333 86.0274 9.77333 86.0274 10.6C86.0274 11.4267 85.8208 12.1933 85.4074 12.9C84.9941 13.5933 84.3541 14.16 83.4874 14.6C82.6341 15.0267 81.5408 15.24 80.2074 15.24Z"
                fill="#572CD1"
              />
              <path
                d="M69.7753 15V2.2L71.1753 3.6H66.9753V1H73.0153V15H69.7753Z"
                fill="#572CD1"
              />
              <path
                d="M60.2123 15.24C59.0389 15.24 58.0123 15.0667 57.1323 14.72C56.2523 14.36 55.5656 13.86 55.0723 13.22C54.5789 12.5667 54.3323 11.8067 54.3323 10.94C54.3323 10.0733 54.5723 9.34001 55.0523 8.74001C55.5456 8.12668 56.2323 7.66668 57.1123 7.36001C57.9923 7.04001 59.0256 6.88001 60.2123 6.88001C61.4123 6.88001 62.4523 7.04001 63.3323 7.36001C64.2256 7.66668 64.9123 8.12668 65.3923 8.74001C65.8856 9.34001 66.1323 10.0733 66.1323 10.94C66.1323 11.8067 65.8856 12.5667 65.3923 13.22C64.8989 13.86 64.2056 14.36 63.3123 14.72C62.4323 15.0667 61.3989 15.24 60.2123 15.24ZM60.2123 12.86C61.0256 12.86 61.6656 12.68 62.1323 12.32C62.6123 11.96 62.8523 11.4667 62.8523 10.84C62.8523 10.2133 62.6123 9.72668 62.1323 9.38001C61.6656 9.02001 61.0256 8.84001 60.2123 8.84001C59.4123 8.84001 58.7789 9.02001 58.3123 9.38001C57.8456 9.72668 57.6123 10.2133 57.6123 10.84C57.6123 11.4667 57.8456 11.96 58.3123 12.32C58.7789 12.68 59.4123 12.86 60.2123 12.86ZM60.2123 8.52001C59.1323 8.52001 58.1856 8.37334 57.3723 8.08001C56.5723 7.78668 55.9456 7.36001 55.4923 6.80001C55.0523 6.22668 54.8323 5.54668 54.8323 4.76001C54.8323 3.93334 55.0589 3.22668 55.5123 2.64001C55.9656 2.04001 56.5989 1.58001 57.4123 1.26001C58.2256 0.926676 59.1589 0.76001 60.2123 0.76001C61.2923 0.76001 62.2323 0.926676 63.0323 1.26001C63.8456 1.58001 64.4789 2.04001 64.9323 2.64001C65.3989 3.22668 65.6323 3.93334 65.6323 4.76001C65.6323 5.54668 65.4056 6.22668 64.9523 6.80001C64.5123 7.36001 63.8856 7.78668 63.0723 8.08001C62.2589 8.37334 61.3056 8.52001 60.2123 8.52001ZM60.2123 6.62001C60.8923 6.62001 61.4256 6.46668 61.8123 6.16001C62.2123 5.85334 62.4123 5.43334 62.4123 4.90001C62.4123 4.34001 62.2056 3.90668 61.7923 3.60001C61.3923 3.29334 60.8656 3.14001 60.2123 3.14001C59.5723 3.14001 59.0523 3.29334 58.6523 3.60001C58.2523 3.90668 58.0523 4.34001 58.0523 4.90001C58.0523 5.43334 58.2456 5.85334 58.6323 6.16001C59.0189 6.46668 59.5456 6.62001 60.2123 6.62001Z"
                fill="#572CD1"
              />
              <path
                d="M41.1738 15.24C40.0272 15.24 39.0005 14.96 38.0938 14.4C37.1872 13.8267 36.4738 13 35.9538 11.92C35.4338 10.84 35.1738 9.53334 35.1738 8.00001C35.1738 6.46668 35.4338 5.16001 35.9538 4.08001C36.4738 3.00001 37.1872 2.18001 38.0938 1.62001C39.0005 1.04668 40.0272 0.76001 41.1738 0.76001C42.3338 0.76001 43.3605 1.04668 44.2538 1.62001C45.1605 2.18001 45.8738 3.00001 46.3938 4.08001C46.9138 5.16001 47.1738 6.46668 47.1738 8.00001C47.1738 9.53334 46.9138 10.84 46.3938 11.92C45.8738 13 45.1605 13.8267 44.2538 14.4C43.3605 14.96 42.3338 15.24 41.1738 15.24ZM41.1738 12.5C41.7205 12.5 42.1938 12.3467 42.5938 12.04C43.0072 11.7333 43.3272 11.2467 43.5538 10.58C43.7938 9.91334 43.9138 9.05334 43.9138 8.00001C43.9138 6.94668 43.7938 6.08668 43.5538 5.42001C43.3272 4.75334 43.0072 4.26668 42.5938 3.96001C42.1938 3.65334 41.7205 3.50001 41.1738 3.50001C40.6405 3.50001 40.1672 3.65334 39.7538 3.96001C39.3538 4.26668 39.0338 4.75334 38.7938 5.42001C38.5672 6.08668 38.4538 6.94668 38.4538 8.00001C38.4538 9.05334 38.5672 9.91334 38.7938 10.58C39.0338 11.2467 39.3538 11.7333 39.7538 12.04C40.1672 12.3467 40.6405 12.5 41.1738 12.5Z"
                fill="#572CD1"
              />
              <path
                d="M27.5996 15.24C26.4529 15.24 25.4263 14.96 24.5196 14.4C23.6129 13.8267 22.8996 13 22.3796 11.92C21.8596 10.84 21.5996 9.53334 21.5996 8.00001C21.5996 6.46668 21.8596 5.16001 22.3796 4.08001C22.8996 3.00001 23.6129 2.18001 24.5196 1.62001C25.4263 1.04668 26.4529 0.76001 27.5996 0.76001C28.7596 0.76001 29.7863 1.04668 30.6796 1.62001C31.5863 2.18001 32.2996 3.00001 32.8196 4.08001C33.3396 5.16001 33.5996 6.46668 33.5996 8.00001C33.5996 9.53334 33.3396 10.84 32.8196 11.92C32.2996 13 31.5863 13.8267 30.6796 14.4C29.7863 14.96 28.7596 15.24 27.5996 15.24ZM27.5996 12.5C28.1463 12.5 28.6196 12.3467 29.0196 12.04C29.4329 11.7333 29.7529 11.2467 29.9796 10.58C30.2196 9.91334 30.3396 9.05334 30.3396 8.00001C30.3396 6.94668 30.2196 6.08668 29.9796 5.42001C29.7529 4.75334 29.4329 4.26668 29.0196 3.96001C28.6196 3.65334 28.1463 3.50001 27.5996 3.50001C27.0663 3.50001 26.5929 3.65334 26.1796 3.96001C25.7796 4.26668 25.4596 4.75334 25.2196 5.42001C24.9929 6.08668 24.8796 6.94668 24.8796 8.00001C24.8796 9.05334 24.9929 9.91334 25.2196 10.58C25.4596 11.2467 25.7796 11.7333 26.1796 12.04C26.5929 12.3467 27.0663 12.5 27.5996 12.5Z"
                fill="#572CD1"
              />
              <path
                d="M13.7854 0.76001C15.0787 0.76001 16.192 1.02668 17.1254 1.56001C18.0587 2.09334 18.7787 2.87334 19.2854 3.90001C19.792 4.91334 20.0454 6.18001 20.0454 7.70001C20.0454 9.31334 19.7387 10.68 19.1254 11.8C18.5254 12.92 17.692 13.7733 16.6254 14.36C15.5587 14.9467 14.3187 15.24 12.9054 15.24C12.172 15.24 11.4654 15.16 10.7854 15C10.1054 14.84 9.51868 14.6 9.02535 14.28L10.2254 11.9C10.612 12.1667 11.0254 12.3533 11.4654 12.46C11.9054 12.5533 12.3654 12.6 12.8454 12.6C14.0454 12.6 14.9987 12.2333 15.7054 11.5C16.4254 10.7667 16.7854 9.68001 16.7854 8.24001C16.7854 8.00001 16.7787 7.73334 16.7654 7.44001C16.752 7.14668 16.7187 6.85334 16.6654 6.56001L17.5454 7.40001C17.3187 7.92001 16.9987 8.36001 16.5854 8.72001C16.172 9.06668 15.692 9.33334 15.1454 9.52001C14.5987 9.69334 13.9854 9.78001 13.3054 9.78001C12.412 9.78001 11.5987 9.60001 10.8654 9.24001C10.1454 8.88001 9.56535 8.37334 9.12535 7.72001C8.69869 7.06668 8.48535 6.30001 8.48535 5.42001C8.48535 4.46001 8.71869 3.63334 9.18535 2.94001C9.66535 2.24668 10.3054 1.71334 11.1054 1.34001C11.9187 0.953343 12.812 0.76001 13.7854 0.76001ZM13.9854 3.18001C13.5187 3.18001 13.112 3.26668 12.7654 3.44001C12.4187 3.60001 12.1454 3.84001 11.9454 4.16001C11.7454 4.46668 11.6454 4.83334 11.6454 5.26001C11.6454 5.90001 11.8587 6.41334 12.2854 6.80001C12.7254 7.17334 13.3054 7.36001 14.0254 7.36001C14.492 7.36001 14.9054 7.27334 15.2654 7.10001C15.6387 6.91334 15.9254 6.66001 16.1254 6.34001C16.3254 6.02001 16.4254 5.66001 16.4254 5.26001C16.4254 4.86001 16.3254 4.50668 16.1254 4.20001C15.9387 3.88001 15.6654 3.63334 15.3054 3.46001C14.9454 3.27334 14.5054 3.18001 13.9854 3.18001Z"
                fill="#572CD1"
              />
              <path
                d="M3.19331 15V2.2L4.59331 3.6H0.393311V1H6.43331V15H3.19331Z"
                fill="#572CD1"
              />
            </svg>
          </div>
          <div
            className="is-flex is-flex-gap-3 is-clickable is-justify-content-center is-align-items-center has-text-kurio-main-color-purple has-text-weight-bold has-background-kurio-purple-light-2"
            style={{
              padding: "0rem 1.5rem",
              borderRadius: "1.5rem",
              height: "2.5rem",
            }}
            onClick={() => handleCopyToClipboard(window.location.href)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.2782 20.8421C19.5809 20.8421 19.015 20.2762 19.015 19.579C19.015 18.8817 19.5809 18.3158 20.2782 18.3158C20.9754 18.3158 21.5413 18.8817 21.5413 19.579C21.5413 20.2762 20.9754 20.8421 20.2782 20.8421ZM3.8571 13.2632C3.15984 13.2632 2.59394 12.6973 2.59394 12C2.59394 11.3027 3.15984 10.7369 3.8571 10.7369C4.55436 10.7369 5.12026 11.3027 5.12026 12C5.12026 12.6973 4.55436 13.2632 3.8571 13.2632ZM20.2782 3.15791C20.9754 3.15791 21.5413 3.7238 21.5413 4.42107C21.5413 5.11833 20.9754 5.68422 20.2782 5.68422C19.5809 5.68422 19.015 5.11833 19.015 4.42107C19.015 3.7238 19.5809 3.15791 20.2782 3.15791ZM20.2782 15.7895C19.2462 15.7895 18.3114 16.2063 17.6268 16.8771L7.60363 12.4232C7.62005 12.283 7.64657 12.144 7.64657 12C7.64657 11.856 7.62005 11.7171 7.60363 11.5769L17.6268 7.12296C18.3114 7.7937 19.2462 8.21054 20.2782 8.21054C22.3674 8.21054 24.0676 6.51033 24.0676 4.42107C24.0676 2.3318 22.3674 0.631592 20.2782 0.631592C18.1889 0.631592 16.4887 2.3318 16.4887 4.42107C16.4887 4.56507 16.5152 4.70401 16.5316 4.84422L6.50847 9.29812C5.82384 8.62738 4.8891 8.21054 3.8571 8.21054C1.76784 8.21054 0.067627 9.91075 0.067627 12C0.067627 14.0893 1.76784 15.7895 3.8571 15.7895C4.8891 15.7895 5.82384 15.3726 6.50847 14.7019L16.5316 19.1558C16.5152 19.296 16.4887 19.435 16.4887 19.579C16.4887 21.6682 18.1889 23.3684 20.2782 23.3684C22.3674 23.3684 24.0676 21.6682 24.0676 19.579C24.0676 17.4897 22.3674 15.7895 20.2782 15.7895Z"
                fill="#572CD1"
              />
            </svg>
            <p
              className="is-uppercase"
              style={{
                fontSize: "1rem",
                lineHeight: "normal",
              }}
            >
              chia sẻ
            </p>
          </div>
        </div>
        <div
          className="game-header-mobile is-flex-direction-column is-align-items-center is-align-content-center is-justify-content-center has-background-kurio-main-color-white"
          style={{
            width: "100%",
            padding: "1rem 1.5rem",
            gap: "0.5rem 2rem",
          }}
        >
          <div className="is-flex is-flex-gap-4 is-align-items-center is-align-content-center">
            <div className="is-flex is-flex-gap-3 is-justify-content-center is-align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.06995 10.5312C8.69935 9.63239 9.35395 8.31059 9.48475 6.59999H11.2577C11.0279 8.43119 9.76435 9.94199 8.06995 10.5312ZM1.74146 6.59999H3.53666C3.66026 8.31719 4.30646 9.63359 4.94425 10.5366C3.24206 9.95039 1.97246 8.43659 1.74146 6.59999ZM4.96285 1.45738C4.33165 2.35618 3.67105 3.68218 3.53905 5.39998H1.74146C1.97306 3.55678 3.25165 2.03818 4.96285 1.45738ZM4.74731 5.4C4.93091 3.4368 5.93531 2.07 6.51551 1.4382C7.11551 2.0808 8.10431 3.435 8.27531 5.4H4.74731ZM4.74805 6.59999H8.27725C8.09365 8.56619 7.08685 9.93299 6.50725 10.5648C5.87905 9.89879 4.91545 8.56319 4.74805 6.59999ZM11.2581 5.40001H9.48689C9.36389 3.69061 8.72489 2.37841 8.09009 1.47661C9.77489 2.07061 11.0295 3.57601 11.2581 5.40001ZM12.5 6C12.5 2.6976 9.8174 0.0108 6.5168 0.0012C6.5144 0.0012 6.512 0.0006 6.509 0.0006C6.5066 0.0006 6.503 0 6.5 0C3.1916 0 0.5 2.6922 0.5 6C0.5 9.3084 3.1916 12 6.5 12C6.5036 12 6.5066 11.9994 6.509 11.9994C6.5102 11.9994 6.5108 12 6.512 12C6.5138 12 6.5156 11.9988 6.5168 11.9988C9.8174 11.9898 12.5 9.3024 12.5 6Z"
                  fill="#572CD1"
                />
              </svg>
              <p
                className="has-text-kurio-main-color-purple has-text-weight-bold"
                style={{ fontSize: "0.75rem", lineHeight: "normal" }}
              >
                kurio.vn
              </p>
            </div>
            <div className="is-flex is-flex-gap-3 is-justify-content-center is-align-items-center">
              <img
                style={{ width: "1.25rem", height: "1.25rem" }}
                src="/images/games/icon-zalo.svg"
                alt=""
              />
              <p
                className="has-text-kurio-main-color-purple has-text-weight-bold"
                style={{ fontSize: "0.75rem", lineHeight: "normal" }}
              >
                0971 500 120
              </p>
            </div>
            <div className="is-flex is-flex-gap-3 is-justify-content-center is-align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.26007 1.19998C2.39967 1.19998 1.70007 1.89958 1.70007 2.75998C1.70007 7.19278 5.30667 10.8 9.74007 10.8C10.5999 10.8 11.3001 10.1004 11.3001 9.23998C11.3001 9.17338 11.2959 9.10798 11.2875 9.04318L8.51547 8.40718C8.46027 8.51278 8.39967 8.62738 8.34447 8.73418C8.07087 9.25618 7.87287 9.63538 7.36767 9.43198C5.38407 8.73598 3.76467 7.11598 3.05967 5.10838C2.84547 4.63738 3.25347 4.42378 3.77067 4.15378C3.87567 4.09858 3.98907 4.03918 4.09287 3.98518L3.45687 1.21258C3.39027 1.20418 3.32547 1.19998 3.26007 1.19998ZM9.74 12C4.6448 12 0.5 7.8552 0.5 2.76C0.5 1.2378 1.7378 0 3.26 0C3.416 0 3.5702 0.0132 3.7196 0.039C3.8654 0.0624 4.0118 0.0996 4.1546 0.1488C4.3496 0.2166 4.4966 0.3804 4.5428 0.582L5.3642 4.1604C5.4098 4.3572 5.3528 4.5636 5.2124 4.71C5.1308 4.7952 5.129 4.797 4.385 5.1864C4.9874 6.4908 6.0344 7.5342 7.3124 8.1162C7.7024 7.3716 7.7048 7.3692 7.79 7.2876C7.9364 7.1472 8.144 7.0926 8.3396 7.1358L11.9186 7.9572C12.1196 8.0034 12.2834 8.1498 12.3506 8.3448C12.3998 8.4864 12.437 8.6328 12.4616 8.7834C12.4868 8.9316 12.5 9.0852 12.5 9.24C12.5 10.7622 11.2616 12 9.74 12Z"
                  fill="#572CD1"
                />
              </svg>
              <p
                className="has-text-kurio-main-color-purple has-text-weight-bold"
                style={{ fontSize: "0.75rem", lineHeight: "normal" }}
              >
                1900 8157
              </p>
            </div>
          </div>
          <div className="is-flex is-flex-gap-6 is-align-items-center is-align-content-center">
            <div
              className="is-flex is-flex-gap-2 is-clickable is-justify-content-center is-align-items-center has-text-kurio-main-color-purple has-text-weight-bold has-background-kurio-purple-light-2"
              style={{
                padding: "0rem 0.75rem",
                borderRadius: "1.5rem",
                height: "1.5rem",
              }}
              onClick={() => handleCopyToClipboard(window.location.href)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.9736 13.8947C13.5088 13.8947 13.1315 13.5175 13.1315 13.0526C13.1315 12.5878 13.5088 12.2105 13.9736 12.2105C14.4385 12.2105 14.8157 12.5878 14.8157 13.0526C14.8157 13.5175 14.4385 13.8947 13.9736 13.8947ZM3.02635 8.8421C2.56151 8.8421 2.18424 8.46484 2.18424 7.99999C2.18424 7.53515 2.56151 7.15789 3.02635 7.15789C3.49119 7.15789 3.86846 7.53515 3.86846 7.99999C3.86846 8.46484 3.49119 8.8421 3.02635 8.8421ZM13.9736 2.10526C14.4385 2.10526 14.8157 2.48252 14.8157 2.94736C14.8157 3.4122 14.4385 3.78947 13.9736 3.78947C13.5088 3.78947 13.1315 3.4122 13.1315 2.94736C13.1315 2.48252 13.5088 2.10526 13.9736 2.10526ZM13.9737 10.5263C13.2857 10.5263 12.6625 10.8042 12.2061 11.2514L5.524 8.2821C5.53495 8.18863 5.55263 8.096 5.55263 8C5.55263 7.904 5.53495 7.81137 5.524 7.71789L12.2061 4.74863C12.6625 5.19579 13.2857 5.47368 13.9737 5.47368C15.3665 5.47368 16.5 4.34021 16.5 2.94737C16.5 1.55452 15.3665 0.421051 13.9737 0.421051C12.5808 0.421051 11.4474 1.55452 11.4474 2.94737C11.4474 3.04337 11.4651 3.136 11.476 3.22947L4.79389 6.19873C4.33747 5.75158 3.71432 5.47368 3.02632 5.47368C1.63347 5.47368 0.5 6.60716 0.5 8C0.5 9.39284 1.63347 10.5263 3.02632 10.5263C3.71432 10.5263 4.33747 10.2484 4.79389 9.80126L11.476 12.7705C11.4651 12.864 11.4474 12.9566 11.4474 13.0526C11.4474 14.4455 12.5808 15.5789 13.9737 15.5789C15.3665 15.5789 16.5 14.4455 16.5 13.0526C16.5 11.6598 15.3665 10.5263 13.9737 10.5263Z"
                  fill="#572CD1"
                />
              </svg>
              <p
                className=" is-uppercase"
                style={{
                  fontSize: "0.75rem",
                  lineHeight: "normal",
                  letterSpacing: "0.0075rem",
                }}
              >
                chia sẻ
              </p>
            </div>
          </div>
        </div>

        <div className="game-container" style={{ padding: "2.5rem 0rem" }}>
          <div className=" is-flex is-flex-direction-column is-align-items-center">
            <img
              className="desktop-image"
              src="/images/games/image-game.svg"
              alt=""
            />
            <img
              className="mobile-image"
              style={{ display: "none" }}
              src="/images/games/image-game-mobile.svg"
              alt=""
            />
            <div
              className="desktop-banner is-flex-direction-column is-justify-content-center is-align-items-center is-align-items-stretch"
              style={{
                display: "flex",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: "url('/images/games/banner-game.svg')",
                borderRadius: "1rem",
                width: "100%",
                height: "4.5rem",
              }}
            >
              <div
                className="is-flex is-align-items-center is-justify-content-center is-flex-wrap-wrap"
                style={{ gap: "2.5rem" }}
              >
                <div className="is-flex is-flex-gap-2 is-align-items-center">
                  <p
                    className="has-text-kurio-main-color-white is-uppercase has-text-centered has-text-weight-bold"
                    style={{
                      fontSize: "1rem",
                      lineHeight: "normal",
                      width: "10.8125rem",
                    }}
                  >
                    khám phá thế giới toán học của
                  </p>
                  <img
                    style={{ width: "8.18181rem", height: "3.5rem" }}
                    src="/images/games/logo-kurio-game.svg"
                    alt=""
                  />
                </div>
                <div className="is-flex is-flex-gap-4 is-align-items-center">
                  <PrimaryButtonLevelSection
                    isDarkColor={true}
                    onClick={() => navigate("/")}
                  >
                    tới trang chủ
                  </PrimaryButtonLevelSection>
                  {!token && (
                    <PrimaryButtonLevelSection
                      onClick={() => navigate("/register")}
                      style={{ maxWidth: "14rem", width: "14rem" }}
                      isYellowColor={true}
                    >
                      đăng ký/đăng nhập
                    </PrimaryButtonLevelSection>
                  )}
                </div>
              </div>
            </div>
            <div
              className="desktop-banner is-justify-content-center is-align-items-center is-align-items-stretch"
              style={{
                display: "flex",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: "url('/images/games/banner-game-2.png')",
                borderRadius: "1rem",
                width: "100%",
                height: "11.5rem",
                gap: "2.5rem",
              }}
            >
              <img src="/images/games/image-banner-2-desktop.svg" alt="games" />
              <div className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-flex-start is-justify-content-flex-start is-flex-wrap-wrap">
                <p
                  className="has-text-kurio-main-color-black"
                  style={{
                    fontSize: "1rem",
                    lineHeight: "normal",
                    width: "14.8125rem",
                  }}
                >
                  Trò chơi tương tác đầu tiên đến từ KURIO. Cùng thử nhé!
                </p>
                <a
                  className="is-flex is-align-items-center is-justify-content-center is-clickable is-uppercase has-text-kurio-main-color-white has-text-weight-bold"
                  target="_blank"
                  href="/kho-tro-choi/day-so-bi-an"
                  style={{
                    padding: "1rem 2rem",
                    fontSize: "1.25rem",
                    lineHeight: "normal",
                    background: "#881A09",
                    borderRadius: "1rem",
                  }}
                >
                  chơi ngay
                </a>
              </div>
            </div>
            <div
              className="mobile-banner is-flex-direction-column is-justify-content-center is-align-items-center is-align-items-stretch"
              style={{
                display: "none",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: "url('/images/games/banner-game-mobile.svg')",
                height: "8rem",
              }}
            >
              <div
                className="is-flex is-align-items-center is-justify-content-center"
                style={{ gap: "1rem" }}
              >
                <div className="is-flex is-flex-direction-column is-flex-gap-2 is-align-items-center">
                  <p
                    className="has-text-kurio-main-color-white is-uppercase has-text-centered has-text-weight-bold"
                    style={{
                      fontSize: "0.875rem",
                      lineHeight: "normal",
                      width: "10.8125rem",
                    }}
                  >
                    khám phá thế giới toán học của
                  </p>
                  <img
                    style={{ width: "8.18181rem", height: "3.5rem" }}
                    src="/images/games/logo-kurio-game.svg"
                    alt=""
                  />
                </div>
                <div className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center">
                  <PrimaryButtonLevelSection
                    isDarkColor={true}
                    onClick={() => navigate("/")}
                    style={{
                      maxWidth: "11.5rem",
                      width: "11.5rem",
                      fontSize: "0.875rem",
                    }}
                  >
                    tới trang chủ
                  </PrimaryButtonLevelSection>
                  {/* <PrimaryButtonLevelSection
                    onClick={() => navigate("/register")}
                    style={{
                      maxWidth: "11.5rem",
                      width: "11.5rem",
                      fontSize: "0.875rem",
                    }}
                    isYellowColor={true}
                  >
                    đăng ký/đăng nhập
                  </PrimaryButtonLevelSection> */}
                </div>
              </div>
            </div>
            <div
              className="mobile-banner is-flex-direction-column is-justify-content-center is-align-items-center is-align-items-stretch"
              style={{
                display: "none",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: "url('/images/games/banner-game-2.png')",
                height: "8rem",
              }}
            >
              <div
                className="is-flex is-align-items-center is-justify-content-center"
                style={{ gap: "1rem" }}
              >
                <img
                  src="/images/games/image-banner-2-mobile.svg"
                  alt="games"
                />
                <div className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-flex-start is-justify-content-flex-start is-flex-wrap-wrap">
                  <p
                    className="has-text-kurio-main-color-black"
                    style={{
                      fontSize: "0.875rem",
                      lineHeight: "normal",
                      width: "10rem",
                    }}
                  >
                    Trò chơi tương tác đầu tiên đến từ KURIO. Cùng thử nhé!
                  </p>
                  <a
                    className="is-flex is-align-items-center is-justify-content-center is-clickable is-uppercase has-text-kurio-main-color-white has-text-weight-bold"
                    target="_blank"
                    href="/kho-tro-choi/day-so-bi-an"
                    style={{
                      padding: "0.5rem 1rem",
                      fontSize: "1rem",
                      lineHeight: "normal",
                      background: "#881A09",
                      borderRadius: "1rem",
                    }}
                  >
                    chơi ngay
                  </a>
                </div>
              </div>
            </div>
            <div
              className="list-games is-flex is-justify-content-center is-flex-wrap-wrap"
              style={{ gap: "1rem 1.5rem" }}
            >
              {isPreview && (
                <>
                  <div className="modal is-active">
                    <div
                      className=" modal-background"
                      style={{ opacity: 0.7 }}
                      onClick={removePopup}
                    ></div>
                    <div className="is-flex is-flex-direction-column is-align-items-center">
                      <div style={{ maxHeight: "90vh" }}>
                        <Document
                          loading={
                            <div
                              className="preview-loading has-text-kurio-main-color-black has-text-centered is-flex is-align-items-center is-justify-content-center has-text-weight-bold"
                              style={{
                                background: "#ffffff",
                                position: "relative",
                                zIndex: 9999,
                                maxWidth: "100% !important",

                                bottom: "0.75rem",
                              }}
                            >
                              Loading...
                            </div>
                          }
                          error={
                            <div
                              className="preview-loading has-text-kurio-main-color-black has-text-centered"
                              style={{
                                background: "#ffffff",
                                position: "relative",
                                zIndex: 9999,
                                maxWidth: "100% !important",

                                bottom: "0.75rem",
                              }}
                            >
                              Failed to load the page.
                            </div>
                          }
                          file={urlPreview}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          {isLoading &&
                            Array.from(new Array(numPages), (el, index) => (
                              <Page
                                className="is-hidden"
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                              />
                            ))}
                          <Page
                            loading={
                              <div
                                className="preview-loading has-text-kurio-main-color-black has-text-centered is-flex is-justify-content-center is-align-items-center"
                                style={{
                                  background: "#ffffff",
                                  position: "relative",
                                  zIndex: 9999,
                                  maxWidth: "100% !important",

                                  bottom: "-0.325rem",
                                }}
                              >
                                Loading...
                              </div>
                            }
                            error={
                              <div
                                className="preview-loading has-text-kurio-main-color-black has-text-centered"
                                style={{
                                  background: "#ffffff",
                                  position: "relative",
                                  zIndex: 9999,
                                  maxWidth: "100% !important",

                                  bottom: "-0.325rem",
                                }}
                              >
                                Failed to load the page.
                              </div>
                            }
                            width={width}
                            height={height}
                            onLoadSuccess={onPageLoadSuccess}
                            pageNumber={pageNumber}
                          />
                        </Document>
                      </div>
                      {!isLoading && (
                        <div
                          className="page-controls is-relative is-flex is-align-items-center is-flex-gap-4 has-background-kurio-main-color-white has-text-centered has-text-kurio-main-color-black has-text-weight-bold"
                          style={{
                            zIndex: 9999,
                            bottom: "1rem",
                            borderRadius: "0.25rem",
                          }}
                        >
                          <PreviousButton
                            disabled={pageNumber <= 1}
                            onClick={() => {
                              setPageNumber(pageNumber - 1);
                            }}
                          />
                          <p
                            style={{
                              fontSize: "1.25rem",
                              lineHeight: "normal",
                            }}
                          >
                            {pageNumber} of {numPages}
                          </p>
                          <NextButton
                            disabled={pageNumber >= numPages}
                            onClick={() => {
                              setPageNumber(pageNumber + 1);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="is-flex is-flex-direction-column is-align-content-center is-justify-content-center is-flex-gap-5">
                <div className="is-flex is-flex-direction-row is-align-content-center is-justify-content-center is-flex-gap-5">
                  {array.slice(0, 2).map((item, index) => (
                    <div
                      key={index}
                      className="is-flex is-relative p-5 is-flex-direction-column is-flex-gap-4 is-align-items-center is-justify-content-center has-background-kurio-main-color-white"
                      style={{
                        borderRadius: "1rem",
                        outline: "0.125rem solid #572CD1",
                        boxShadow: "0.125rem 0.25rem 0rem 0rem #572CD1",
                      }}
                    >
                      <div
                        className="has-text-kurio-main-color-white has-background-kurio-main-color-purple has-text-weight-bold"
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "6.6875rem",
                          padding: "0.25rem 1.25rem",
                          borderRadius: "0rem 0rem 1.5rem 1.5rem",
                        }}
                      >
                        <p
                          style={{ fontSize: "1.5rem", lineHeight: "1.89rem" }}
                        >
                          {index + 1}
                        </p>
                      </div>
                      <img
                        style={{
                          width: "13.5rem",
                          height: "9rem",
                          borderRadius: "1rem",
                          // background: "#D9D9D9",
                        }}
                        src={item.objectId}
                        alt={item.objectId}
                      />
                      <p
                        className="has-text-kurio-main-color-purple has-text-weight-bold"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "1.25rem",
                          lineHeight: "1.575rem",
                        }}
                      >
                        {item.name}
                      </p>
                      <div
                        onClick={() => {
                          if (index === 0) {
                            navigate("/kho-tro-choi/bai-toan-noi");
                          } else if (index === 1) {
                            navigate("/kho-tro-choi/to-mau");
                          }
                        }}
                        className="is-flex is-flex-gap-3 is-clickable is-uppercase px-5 is-align-items-center is-justify-content-center has-background-kurio-main-color-white has-text-kurio-main-color-purple has-text-weight-bold"
                        style={{
                          border: "0.125rem solid #572CD1",
                          borderRadius: "1.5rem",
                          height: "3rem",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M20.4887 1.74833C20.4887 0.833333 19.7488 0.0883333 18.8321 0.0833333L5.51042 0H5.50042C4.58375 0 3.84042 0.74 3.83375 1.65667C3.82708 2.57667 4.56875 3.32833 5.49042 3.33333L14.7537 3.39167L0.98875 17.155C0.337083 17.8067 0.337083 18.8617 0.98875 19.5117C1.31375 19.8383 1.74042 20 2.16708 20C2.59375 20 3.02042 19.8383 3.34542 19.5117L17.1587 5.69833L17.1671 15.0017C17.1671 15.9217 17.9137 16.6667 18.8337 16.6667H18.8354C19.7554 16.6667 20.5004 15.9183 20.5004 14.9983L20.4887 1.74833Z"
                            fill="#572CD1"
                          />
                        </svg>
                        <p style={{ fontSize: "1rem", lineHeight: "1.26rem" }}>
                          {item.name === "Bài Toán Nối" ||
                          item.name === "Tô Màu"
                            ? "Chơi ngay "
                            : "Xem trước"}
                        </p>
                      </div>
                      <div
                        onClick={() => handleClickDownload(item.urlPDF)}
                        className="is-flex is-flex-gap-3 is-clickable is-uppercase px-5 is-align-items-center is-justify-content-center has-background-kurio-main-color-purple has-text-kurio-main-color-white has-text-weight-bold"
                        style={{
                          border: "0.125rem solid #572CD1",
                          borderRadius: "1.5rem",
                          height: "3rem",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="24"
                          viewBox="0 0 23 24"
                          fill="none"
                        >
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M10.0924 12.8131C10.0898 12.7777 10.0884 12.7419 10.0884 12.7059V1.41176C10.0884 0.632471 10.7195 0 11.5002 0C12.2809 0 12.9119 0.632471 12.9119 1.41176V12.7056L16.3001 10.1647C16.9241 9.69459 17.8093 9.82306 18.2766 10.4471C18.7453 11.0711 18.6182 11.9562 17.9942 12.4235L12.3472 16.6588C12.0973 16.8466 11.798 16.9412 11.5001 16.9412C11.2164 16.9412 10.9326 16.8551 10.6884 16.6842L5.0413 12.7115C4.40319 12.2626 4.2493 11.3816 4.69825 10.7449C5.14719 10.1068 6.02672 9.95294 6.66483 10.4019L10.0924 12.8131ZM3.02958 19.7647V21.1765H19.9708V19.7647C19.9708 18.9882 20.6061 18.3529 21.3825 18.3529C22.159 18.3529 22.7943 18.9882 22.7943 19.7647V22.5882C22.7943 22.6368 22.7918 22.6847 22.787 22.732C22.7143 23.4416 22.1105 24 21.3825 24H1.61782C0.841349 24 0.206055 23.3647 0.206055 22.5882V19.7647C0.206055 18.9882 0.841349 18.3529 1.61782 18.3529C2.39429 18.3529 3.02958 18.9882 3.02958 19.7647Z"
                            fill="#FAFBFC"
                          />
                        </svg>
                        <p style={{ fontSize: "1rem", lineHeight: "1.26rem" }}>
                          Tải file pdf
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="is-flex is-flex-wrap-wrap is-align-content-center is-justify-content-center is-flex-gap-5">
                  {array.slice(2).map((item, index) => (
                    <div
                      key={index}
                      className="is-flex is-relative p-5 is-flex-direction-column is-flex-gap-4 is-align-items-center is-justify-content-center has-background-kurio-main-color-white"
                      style={{
                        borderRadius: "1rem",
                        outline: "0.125rem solid #572CD1",
                        boxShadow: "0.125rem 0.25rem 0rem 0rem #572CD1",
                      }}
                    >
                      <div
                        className="has-text-kurio-main-color-white has-background-kurio-main-color-purple has-text-weight-bold"
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "6.6875rem",
                          padding: "0.25rem 1.25rem",
                          borderRadius: "0rem 0rem 1.5rem 1.5rem",
                        }}
                      >
                        <p
                          style={{ fontSize: "1.5rem", lineHeight: "1.89rem" }}
                        >
                          {index + 3}
                        </p>
                      </div>
                      <img
                        style={{
                          width: "13.5rem",
                          height: "9rem",
                          borderRadius: "1rem",
                          // background: "#D9D9D9",
                        }}
                        src={item.objectId}
                        alt={item.objectId}
                      />
                      <p
                        className="has-text-kurio-main-color-purple has-text-weight-bold"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "1.25rem",
                          lineHeight: "1.575rem",
                        }}
                      >
                        {item.name}
                      </p>
                      <div
                        onClick={() => {
                          handleClickPreview(item.urlPDF);
                        }}
                        className="is-flex is-flex-gap-3 is-clickable is-uppercase px-5 is-align-items-center is-justify-content-center has-background-kurio-main-color-white has-text-kurio-main-color-purple has-text-weight-bold"
                        style={{
                          border: "0.125rem solid #572CD1",
                          borderRadius: "1.5rem",
                          height: "3rem",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M20.4887 1.74833C20.4887 0.833333 19.7488 0.0883333 18.8321 0.0833333L5.51042 0H5.50042C4.58375 0 3.84042 0.74 3.83375 1.65667C3.82708 2.57667 4.56875 3.32833 5.49042 3.33333L14.7537 3.39167L0.98875 17.155C0.337083 17.8067 0.337083 18.8617 0.98875 19.5117C1.31375 19.8383 1.74042 20 2.16708 20C2.59375 20 3.02042 19.8383 3.34542 19.5117L17.1587 5.69833L17.1671 15.0017C17.1671 15.9217 17.9137 16.6667 18.8337 16.6667H18.8354C19.7554 16.6667 20.5004 15.9183 20.5004 14.9983L20.4887 1.74833Z"
                            fill="#572CD1"
                          />
                        </svg>
                        <p style={{ fontSize: "1rem", lineHeight: "1.26rem" }}>
                          {item.name === "Bài Toán Nối" ||
                          item.name === "Tô Màu"
                            ? "Chơi ngay "
                            : "Xem trước"}
                        </p>
                      </div>
                      <div
                        onClick={() => handleClickDownload(item.urlPDF)}
                        className="is-flex is-flex-gap-3 is-clickable is-uppercase px-5 is-align-items-center is-justify-content-center has-background-kurio-main-color-purple has-text-kurio-main-color-white has-text-weight-bold"
                        style={{
                          border: "0.125rem solid #572CD1",
                          borderRadius: "1.5rem",
                          height: "3rem",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="24"
                          viewBox="0 0 23 24"
                          fill="none"
                        >
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M10.0924 12.8131C10.0898 12.7777 10.0884 12.7419 10.0884 12.7059V1.41176C10.0884 0.632471 10.7195 0 11.5002 0C12.2809 0 12.9119 0.632471 12.9119 1.41176V12.7056L16.3001 10.1647C16.9241 9.69459 17.8093 9.82306 18.2766 10.4471C18.7453 11.0711 18.6182 11.9562 17.9942 12.4235L12.3472 16.6588C12.0973 16.8466 11.798 16.9412 11.5001 16.9412C11.2164 16.9412 10.9326 16.8551 10.6884 16.6842L5.0413 12.7115C4.40319 12.2626 4.2493 11.3816 4.69825 10.7449C5.14719 10.1068 6.02672 9.95294 6.66483 10.4019L10.0924 12.8131ZM3.02958 19.7647V21.1765H19.9708V19.7647C19.9708 18.9882 20.6061 18.3529 21.3825 18.3529C22.159 18.3529 22.7943 18.9882 22.7943 19.7647V22.5882C22.7943 22.6368 22.7918 22.6847 22.787 22.732C22.7143 23.4416 22.1105 24 21.3825 24H1.61782C0.841349 24 0.206055 23.3647 0.206055 22.5882V19.7647C0.206055 18.9882 0.841349 18.3529 1.61782 18.3529C2.39429 18.3529 3.02958 18.9882 3.02958 19.7647Z"
                            fill="#FAFBFC"
                          />
                        </svg>
                        <p style={{ fontSize: "1rem", lineHeight: "1.26rem" }}>
                          Tải file pdf
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImageFromUrl>
  );
};

export default StaticGames;
