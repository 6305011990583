import React from "react";

const AnswerItem = ({
  itemSelected,
  handleShowAnswer,
  isCurrent,
  isPrev,
  children,
  index,
  title,
  isMobile = false,
  isTablet = false,
}) => {
  return (
    <div
      className="is-flex is-flex-direction-column is-flex-gap-5"
      style={{ width: "100%" }}
    >
      <div
        className="is-flex is-clickable is-align-items-center has-text-kurio-main-color-black has-text-weight-bold is-flex-grow-0"
        style={{ gap: isMobile ? "0.5rem" : "1.5rem" }}
        onClick={() =>
          handleShowAnswer(
            itemSelected.prev !== -1 ? itemSelected.current : -1,
            index
          )
        }
      >
        <p
          style={{
            fontSize: isMobile || isTablet ? "1rem" : "1.25rem",
            lineHeight: "110%",
            fontStyle: "normal",
            width: isMobile ? "19.625rem" : isTablet ? "21.25rem" : "32.25rem",
          }}
        >
          {title}
        </p>
        <img
          className={`${
            isCurrent ? "qa-show-answer" : isPrev ? "qa-hide-answer" : ""
          }  is-clickable`}
          style={{ width: "1rem", height: "0.5rem", objectFit: "cover" }}
          src="/images/landing-page/ic-arrow-down.svg"
          alt=""
        />
      </div>
      {isCurrent && children}
      <div
        style={{
          width: "100%",
          height: "0rem",
          borderTop: "0.0625rem solid #0A2A66",
        }}
      ></div>
    </div>
  );
};

export default AnswerItem;
