import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./component.style.css";

import ReactPlayer from "react-player";
import { Autoplay, Pagination } from "swiper/modules";

const LandingPageFeature = ({ isMobile = false, isTablet = false }) => {
  const ListSlideContent = [
    { order: 0, title: "Lộ trình Ôn thi Cá nhân hóa" },
    { order: 1, title: "Bài giảng trực quan, sinh động" },
    { order: 2, title: "Luyện thi IKMC chính thống hiệu quả" },
    { order: 3, title: "Học toán qua câu chuyện" },
    { order: 4, title: "Tạo thói quen điểm danh hằng ngày" },
    { order: 5, title: "Hệ thống tính điểm thông minh ELORIO" },
  ];
  const ListSlideVideo = [
    {
      order: 1,
      video: "/videos/landing-page/slide1.mov",
      imageUrl: "images/landing-page/background-slide-1.png",
    },
    {
      order: 2,
      video: "/videos/landing-page/slide2.mov",
      imageUrl: "images/landing-page/background-slide-2.png",
    },
    {
      order: 3,
      video: "/videos/landing-page/slide3.mov",
      imageUrl: "images/landing-page/background-slide-3.png",
    },
    {
      order: 4,
      video: "/videos/landing-page/slide4.mov",
      imageUrl: "images/landing-page/background-slide-4.png",
    },
    {
      order: 5,
      video: "/videos/landing-page/slide5.mov",
      imageUrl: "images/landing-page/background-slide-5.png",
    },
    {
      order: 6,
      video: "/videos/landing-page/slide6.mov",
      imageUrl: "images/landing-page/background-slide-6.png",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  const [playing, setPlaying] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const swiperRef = useRef(null);
  const handleChangeSlide = (order) => {
    if (order !== currentSlide) {
      setCurrentSlide(order);
      if (
        swiperRef.current &&
        swiperRef.current.swiper &&
        !swiperRef.current.swiper.autoplay?.running
      ) {
        swiperRef.current.swiper.autoplay.start();
        const newPlaying = [false, false, false, false, false, false];
        setPlaying(newPlaying);
      }
    }
  };

  const handleVideoEnd = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      setTimeout(() => {
        swiperRef.current.swiper.slideNext();
        swiperRef.current.swiper.autoplay.start();
        const newPlaying = [false, false, false, false, false, false];
        setPlaying(newPlaying);
      }, 1000);
    }
  };
  const goToSlide = (index) => {
    if (index !== currentSlide) {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideToLoop(
          index < 0
            ? ListSlideContent.length - 1
            : index >= ListSlideContent.length
            ? 0
            : index
        );
        if (!swiperRef.current.swiper.autoplay?.running) {
          swiperRef.current.swiper.autoplay.start();
          if (playing) {
            const newPlaying = [false, false, false, false, false, false];
            setPlaying(newPlaying);
          }
        }
      }
    }
  };

  return (
    <div
      className={`${
        isMobile
          ? "is-justify-content-center is-flex-wrap-wrap is-flex-gap-6"
          : isTablet
          ? "is-flex-wrap-wrap is-flex-gap-6 is-justify-content-center"
          : "is-justify-content-space-between"
      } is-flex is-align-items-center max-width-landingpage feature-landingpage`}
      style={{
        padding: isMobile
          ? "1.5rem 2rem"
          : isTablet
          ? "4rem 0rem 0rem 0rem"
          : "5rem 4rem 0rem 4rem",
        height: isMobile || isTablet ? "auto" : "42.5rem",
        width: isTablet ? "40rem" : "100%",
      }}
    >
      {isTablet && (
        <div >
          <p className="has-text-weight-bold has-text-kurio-main-color-black title-feature">
            Con{" "}
            <b className="has-text-kurio-main-color-purple">
              phát triển  tối đa
            </b>{" "}
            tư duy,<br></br>
            <b className="has-text-kurio-main-color-purple">bứt phá</b> chinh
            phục  mọi kỳ thi Toán
          </p>
          <p
            className="has-text-kurio-main-color-black"
            style={{ fontSize: "1rem", lineHeight: "120%" }}
          >
            Trải nghiệm học tập Thông minh - Toàn diện duy nhất chỉ có ở{" "}
            <b className="has-text-kurio-main-color-purple is-size-5">KURIO</b> !
          </p>
        </div>
      )}
      <div
        className="is-flex is-flex-direction-column is-flex-gap-4 feature-left"
        style={{ width: isMobile ? "20.375rem" : isTablet?"auto": "33rem" }}
      >
        {!isTablet && (
          <>
            <p className="has-text-weight-bold has-text-kurio-main-color-black title-feature">
              Con{" "}
              <b className="has-text-kurio-main-color-purple">
                phát triển <br></br> tối đa
              </b>{" "}
              tư duy,<br></br>
              <b className="has-text-kurio-main-color-purple">bứt phá</b> chinh
              phục <br></br> mọi kỳ thi Toán
            </p>
            <p
              className="has-text-kurio-main-color-black"
              style={{ fontSize: "1.25rem", lineHeight: "120%" }}
            >
              Trải nghiệm học tập Thông minh - Toàn diện duy nhất chỉ có ở{" "}
              <b className="has-text-kurio-main-color-purple">KURIO</b> !
            </p>
          </>
        )}
        {!isMobile && (
          <div
            className={`${
              isTablet
                ? "is-flex-direction-column"
                : "is-flex is-flex-wrap-wrap"
            }  is-align-items-center is-align-content-center feature-content`}
            style={{
              gap: isTablet ? "0.75rem" : "1.5rem",
              width: isTablet ? "15rem" : "",
            }}
          >
            {ListSlideContent.map((item, index) => {
              return (
                <div
                  key={item.title}
                  className="p-2 is-flex is-flex-gap-2 is-align-items-center is-justify-content-center is-clickable"
                  style={{
                    borderRadius: "1rem",
                    background:
                      currentSlide === item.order
                        ? "linear-gradient(90deg, #E6DFFE 0%, rgba(230, 223, 254, 0.00) 100%)"
                        : "#FAFBFC",
                  }}
                  onClick={() => goToSlide(index)}
                >
                  <p
                    className="has-text-center is-flex is-align-items-center is-justify-content-center is-clickable feature-order"
                    style={{
                      width: "2.5rem",
                      height: "3rem",
                      borderRadius: "1rem",
                      background:
                        currentSlide === item.order ? "#572CD1" : "#E0C6F9",
                      fontSize: "1.5rem",
                      fontWeight: 800,
                      fontStyle: "normal",
                      lineHeight: "normal",
                      color:
                        currentSlide === item.order ? "#FAFBFC" : "#572CD1",
                    }}
                  >
                    {item.order + 1}
                  </p>
                  <p
                    className="feature-title"
                    style={{
                      width: "11.5rem",
                      fontSize: "1rem",
                      lineHeight: "normal",
                      fontWeight: currentSlide === item.order ? 800 : 500,
                      color:
                        currentSlide === item.order ? "#572CD1" : "#8864F0",
                    }}
                  >
                    {item.title}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center is-flex-gap-4">
        <div
          className={`${
            isMobile
              ? "is-align-items-center is-justify-content-center is-flex-gap-4"
              : "is-align-items-flex-end is-justify-content-flex-end "
          } is-flex `}
        >
          {!isMobile && (
            <img
              className="is-clickable"
              src="/images/landing-page/ic-arrow-blue.svg"
              style={{
                transform: "rotate(180deg)",
                width: "2rem",
                height: "4rem",
                marginRight: "-0.0625rem",
                marginBottom: "7.5rem",
              }}
              alt=""
            />
          )}
          {isMobile && (
            <img
              onClick={() => {
                if (swiperRef.current && swiperRef.current.swiper) {
                  swiperRef.current.swiper.slidePrev();
                }
              }}
              className="is-clickable"
              style={{ transform: "rotate(180deg)" }}
              src="/images/landing-page/ic-arrow-blue.svg"
              alt=""
            />
          )}
          <div
            className="feature-slide-container"
            style={{
              height: isMobile ? "auto" : isTablet ? "21.875rem" : "35rem",
              width: isMobile
                ? "17.85388rem"
                : isTablet
                ? "17.5rem"
                : "28.56619rem",
            }}
          >
            <Swiper
              className="slide-feature"
              allowTouchMove={false}
              ref={swiperRef}
              lazyPreloadPrevNext={true}
              slidesPerGroup={1}
              slidesPerView={1}
              spaceBetween={30}
              centeredSlides={true}
              // autoplay={{ delay: 2500 }}
              pagination={{
                clickable: true,
              }}
              loop={true}
              modules={[Autoplay, Pagination]}
              onSlideChange={(swiper) => {
                handleChangeSlide(swiper.realIndex);
              }}
            >
              {ListSlideVideo.map((item, index) => {
                return (
                  <SwiperSlide key={item.order}>
                    <div className="is-relative">
                      <img
                        src={item.imageUrl}
                        style={{
                          objectFit: "cover",
                        }}
                        alt=""
                      />
                      <div
                        id={`video-${index}`}
                        className="has-background-kurio-main-color-white slide-video"
                      >
                        <ReactPlayer
                          className=""
                          style={{
                            width: "100%!important",
                            margin: "0 !important",
                            aspectRatio: "16/9",
                          }}
                          muted={true}
                          onEnded={handleVideoEnd}
                          onPlay={() => {
                            if (!playing[currentSlide]) {
                              const newPlaying = [...playing];
                              newPlaying[currentSlide] = true;
                              setPlaying(newPlaying);
                              if (
                                swiperRef.current &&
                                swiperRef.current.swiper &&
                                !playing[currentSlide]
                              ) {
                                swiperRef.current.swiper.autoplay.stop();
                              }
                            }
                          }}
                          playing={index === currentSlide}
                          playsinline
                          width="100%"
                          height={isMobile ? "9rem" : "auto"}
                          url={item.video}
                          controls={true}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          {isMobile && (
            <img
              onClick={() => {
                if (swiperRef.current && swiperRef.current.swiper) {
                  swiperRef.current.swiper.slideNext();
                }
              }}
              className="is-clickable"
              src="/images/landing-page/ic-arrow-blue.svg"
              alt=""
            />
          )}
        </div>
        {isMobile && (
          <div className="is-flex is-flex-wrap-wrap is-align-items-center is-align-content-center is-flex-gap-2 mobile-pagination">
            {ListSlideContent.map((item, index) => {
              return (
                <div
                  key={index}
                  className="has-text-center is-flex is-align-items-center is-justify-content-center is-clickable"
                  style={{
                    width: "2.5rem",
                    height: "3rem",
                    borderRadius: "0.5rem",
                    background:
                      currentSlide === item.order ? "#572CD1" : "#E0C6F9",
                    fontSize: "1.5rem",
                    fontWeight: 800,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    color: currentSlide === item.order ? "#FAFBFC" : "#572CD1",
                    opacity: currentSlide === item.order ? "1" : "0.4",
                  }}
                  onClick={() => goToSlide(index)}
                >
                  <p style={{}}>{item.order + 1}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingPageFeature;
