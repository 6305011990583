import React, { useEffect, useState } from "react";
import ConstantStrings from "../../../constants/ConstantStrings";
import ScrollRefWrapper from "../../../detail-components/ScrollRefWrapper";
import "./MatchingColorGame.css";

const levelBackgrounds = [
  "linear-gradient(135deg, #56d6d6, #fff)",
  "linear-gradient(135deg, #b785f5, #fff)",
  "linear-gradient(135deg, #f4b3a8, #fff)",
  "linear-gradient(135deg, #f4bdde, #fff)",
  "linear-gradient(135deg, #b5eaca, #fff)",
  "linear-gradient(135deg, #f7ecc1, #fff)",
];

const colors = [
  [
    { color: "red", points: [18, 68], value: 1 },
    { color: "#a773ea", points: [41, 77], value: 2 },
    { color: "#3fa9f5", points: [5, 53], value: 3 },
    { color: "#7ac943", points: [6, 26], value: 4 },
    { color: "#fec200", points: [0, 43], value: 5 },
    { color: "#f777f4", points: [9, 28], value: 6 },
    { color: "#ff7019", points: [19, 56], value: 7 },
    { color: "#ff7bac", points: [32, 70], value: 8 },
  ],
  [
    { color: "red", points: [11, 16], value: 1 },
    { color: "#fec200", points: [0, 49], value: 2 },
    { color: "#7ac943", points: [1, 18], value: 3 },
    { color: "#3fa9f5", points: [3, 30], value: 4 },
    { color: "#a773ea", points: [35, 60], value: 5 },
    { color: "#ff7019", points: [14, 39], value: 6 },
    { color: "#c69c6d", points: [13, 36], value: 7 },
    { color: "#08baba", points: [54, 59], value: 8 },
  ],
  [
    { color: "red", points: [0, 42], value: 1 },
    { color: "#fec200", points: [16, 41], value: 2 },
    { color: "#7ac943", points: [10, 49], value: 3 },
    { color: "#3fa9f5", points: [28, 40], value: 4 },
    { color: "#a773ea", points: [11, 14], value: 5 },
    { color: "#ff7019", points: [1, 22], value: 6 },
    { color: "#c69c6d", points: [20, 45], value: 7 },
    { color: "#08baba", points: [37, 54], value: 8 },
  ],
  [
    { color: "red", points: [69, 90], value: 1 },
    { color: "#fec200", points: [40, 46], value: 2 },
    { color: "#7ac943", points: [11, 17], value: 3 },
    { color: "#3fa9f5", points: [61, 99], value: 4 },
    { color: "#a773ea", points: [63, 94], value: 5 },
    { color: "#ff7019", points: [82, 97], value: 6 },
    { color: "#c69c6d", points: [74, 86], value: 7 },
    { color: "#08baba", points: [18, 89], value: 8 },
  ],
  [
    { color: "red", points: [0, 89], value: 1 },
    { color: "#fec200", points: [5, 21], value: 2 },
    { color: "#7ac943", points: [23, 31], value: 3 },
    { color: "#3fa9f5", points: [9, 33], value: 4 },
    { color: "#a773ea", points: [34, 42], value: 5 },
    { color: "#ff7019", points: [72, 77], value: 6 },
    { color: "#c69c6d", points: [46, 88], value: 7 },
    { color: "#08baba", points: [36, 49], value: 8 },
    { color: "#603813", points: [28, 79], value: 9 },
    { color: "#f4bdde", points: [48, 69], value: 10 },
  ],
  [
    { color: "red", points: [20, 29], value: 1 },
    { color: "#fec200", points: [30, 92], value: 2 },
    { color: "#7ac943", points: [40, 27], value: 3 },
    { color: "#3fa9f5", points: [36, 43], value: 4 },
    { color: "#a773ea", points: [39, 93], value: 5 },
    { color: "#ff7019", points: [72, 95], value: 6 },
    { color: "#c69c6d", points: [59, 85], value: 7 },
    { color: "#08baba", points: [75, 88], value: 8 },
    { color: "#603813", points: [66, 78], value: 9 },
  ],
];

// Define the correct solution paths for each color.
const solutionPaths = [
  {
    red: [18, 27, 36, 45, 54, 63, 72, 73, 74, 75, 76, 67, 68],
    "#a773ea": [41, 50, 51, 52, 61, 62, 71, 80, 79, 78, 77],
    "#3fa9f5": [5, 14, 15, 16, 25, 34, 35, 44, 53],
    "#7ac943": [6, 7, 8, 17, 26],
    "#fec200": [0, 1, 2, 3, 4, 13, 22, 23, 24, 33, 42, 43],
    "#f777f4": [9, 10, 11, 12, 21, 30, 39, 48, 57, 66, 65, 64, 55, 46, 37, 28],
    "#ff7019": [19, 20, 29, 38, 47, 56],
    "#ff7bac": [32, 31, 40, 49, 58, 59, 60, 69, 70],
  },
  {
    red: [11, 24, 32, 40, 48, 56, 57, 58, 50, 42, 34, 26, 27, 19, 16],
    "#fec200": [0, 8, 9, 17, 25, 33, 41, 49],
    "#7ac943": [1, 2, 10, 18],
    "#3fa9f5": [3, 4, 5, 6, 7, 15, 23, 31, 30],
    "#a773ea": [35, 43, 44, 45, 46, 47, 55, 63, 62, 61, 60],
    "#ff7019": [14, 22, 21, 29, 37, 38],
    "#c69c6d": [13, 12, 20, 28, 36],
    "#08baba": [54, 53, 52, 51, 59],
  },
  {
    red: [0, 8, 9, 17, 25, 26, 34],
    "#fec200": [16, 24, 32, 33, 41],
    "#7ac943": [10, 18, 19, 27, 35, 43, 51, 50, 49],
    "#3fa9f5": [40, 48, 56, 57, 58, 59, 60, 52, 44, 36, 28],
    "#a773ea": [11, 12, 13, 14],
    "#ff7019": [1, 2, 3, 4, 5, 6, 7, 15, 23, 22],
    "#c69c6d": [20, 21, 29, 30, 31, 39, 47, 55, 63, 62, 61, 53, 45],
    "#08baba": [37, 38, 46, 54],
  },
  {
    red: [
      69, 59, 49, 39, 29, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, 10, 20, 21, 22, 23, 24,
      25, 26, 27, 37, 47, 57, 56, 55, 45, 44, 43, 42, 41, 51, 50, 60, 70, 80,
      90,
    ],
    "#fec200": [40, 30, 31, 32, 33, 34, 35, 36, 46],
    "#7ac943": [11, 12, 13, 14, 15, 16, 17],
    "#3fa9f5": [61, 62, 52, 53, 54, 64, 65, 66, 67, 77, 87, 88, 98, 99],
    "#a773ea": [63, 73, 72, 71, 81, 91, 92, 93, 94],
    "#ff7019": [82, 83, 84, 85, 95, 96, 97],
    "#c69c6d": [74, 75, 76, 86],
    "#08baba": [18, 28, 38, 48, 58, 68, 78, 79, 89],
  },
  {
    red: [
      0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      89,
    ],
    "#fec200": [21, 11, 1, 2, 3, 4, 5],
    "#7ac943": [23, 24, 25, 35, 45, 55, 54, 53, 52, 51, 41, 31],
    "#3fa9f5": [9, 8, 7, 6, 16, 15, 14, 13, 12, 22, 32, 33],
    "#a773ea": [34, 44, 43, 42],
    "#ff7019": [72, 73, 74, 75, 76, 77],
    "#c69c6d": [
      46, 56, 66, 65, 64, 63, 62, 61, 71, 81, 82, 83, 84, 85, 86, 87, 88,
    ],
    "#08baba": [36, 26, 27, 17, 18, 19, 29, 39, 49],
    "#603813": [28, 38, 37, 47, 57, 67, 68, 78, 79],
    "#f4bdde": [48, 58, 59, 69],
  },
  {
    red: [20, 10, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 19, 29],
    "#fec200": [
      30, 31, 21, 11, 12, 13, 14, 15, 16, 17, 18, 28, 38, 37, 47, 46, 45, 44,
      54, 53, 52, 51, 50, 60, 70, 80, 90, 91, 92,
    ],
    "#7ac943": [40, 41, 42, 32, 22, 23, 24, 25, 26, 27],
    "#3fa9f5": [36, 33, 34, 35, 43],
    "#a773ea": [
      39, 49, 48, 58, 57, 56, 55, 65, 64, 63, 62, 61, 71, 81, 82, 83, 93,
    ],
    "#ff7019": [72, 73, 74, 84, 94, 95],
    "#c69c6d": [59, 69, 79, 89, 99, 98, 97, 96, 86, 85],
    "#08baba": [75, 76, 77, 87, 88],
    "#603813": [66, 67, 68, 78],
  },
];

const MatchingColorGame = () => {
  const [selectedLevel, setSelectedLevel] = useState(0);

  const [response, setResponse] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const gridSize =
    selectedLevel === 0
      ? 9
      : selectedLevel === 1 || selectedLevel === 2
      ? 8
      : 10;

  const [grid, setGrid] = useState(Array(gridSize * gridSize).fill(null));
  const [isDragging, setIsDragging] = useState(false);
  const [currentPath, setCurrentPath] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [lastPosition, setLastPosition] = useState(null);
  const [pathComplete, setPathComplete] = useState({}); // Track if paths are complete
  const [gameOver, setGameOver] = useState(null);

  const initializeGrid = () => {
    const newGrid = [...grid];
    colors[selectedLevel].forEach(({ color, points, value }) => {
      points.forEach((point) => {
        newGrid[point] = { color, isEndpoint: true, connected: false, value };
      });
    });
    setGrid(newGrid);
    setGameOver(null); // Reset gameOver state
  };

  const toggleChallenge = () => {
    setIsOpen(true);
  };

  const handleSelectedLevel = (index) => {
    if (selectedLevel !== index) {
      const gridSize = index === 0 ? 9 : index === 1 || index === 2 ? 8 : 10;

      setGrid(Array(gridSize * gridSize).fill(null));
      setSelectedLevel(index);
      setGameOver(null);
    }
  };

  const clearPathForColor = (color) => {
    const newGrid = grid.map((tile) => {
      if (tile && tile.color === color && !tile.isEndpoint) {
        return null;
      }
      return tile;
    });
    setGrid(newGrid);
    setPathComplete((prev) => ({ ...prev, [color]: false }));
  };

  const handleMouseDown = (index) => {
    if (gameOver) return;
    const tile = grid[index];
    if (tile && tile.isEndpoint) {
      const color = tile.color;
      const colorData = colors[selectedLevel].find((c) => c.color === color);

      if (colorData && colorData.points.includes(index)) {
        if (pathComplete[color]) {
          clearPathForColor(color);
        }

        setIsDragging(true);
        setSelectedColor(color);
        setCurrentPath([index]);
        setLastPosition(index);
      }
    }
  };

  const handleMouseOver = (index) => {
    if (gameOver || !isDragging || !selectedColor || !isValidMove(index))
      return;

    if (isDragging && selectedColor && isValidMove(index)) {
      const tile = grid[index];

      // Kiểm tra nếu ô hiện tại là điểm cuối
      if (tile && tile.color === selectedColor && tile.isEndpoint) {
        setPathComplete((prev) => ({
          ...prev,
          [selectedColor]: true,
        }));
        setIsDragging(false);
        setCurrentPath((prev) => [...prev, index]);
        return;
      }

      if (tile && tile.isEndpoint && tile.color !== selectedColor) {
        return;
      }

      const newGrid = [...grid];

      if (tile && tile.connected && tile.color !== selectedColor) {
        newGrid.forEach((t, idx) => {
          if (t && t.color === tile.color && !t.isEndpoint) {
            newGrid[idx] = null;
          }
        });
      }

      if (tile === null || !tile.isEndpoint) {
        newGrid[index] = {
          color: selectedColor,
          connected: true,
        };
      }

      setGrid(newGrid);
      setCurrentPath((prevPath) => [...prevPath, index]);
      setLastPosition(index);
    }
  };

  const handleMouseUp = () => {
    if (gameOver) return; // Prevent interaction if game is over
    if (currentPath.length > 0) {
      const colorData = colors[selectedLevel].find(
        (c) => c.color === selectedColor
      );
      const hasReachedAllEndpoints = colorData.points.every((point) =>
        currentPath.includes(point)
      );

      if (hasReachedAllEndpoints) {
        setPathComplete((prev) => ({ ...prev, [selectedColor]: true }));
      }
    }

    if (isPathComplete(currentPath)) {
      updateGridWithCompletedPath();
    } else {
      clearPathForColor(selectedColor);
    }

    setIsDragging(false);
    setSelectedColor(null);
    setCurrentPath([]);
    setLastPosition(null);

    if (!grid.some((index) => index === null)) {
      checkSolution();
    }
  };

  const isPathComplete = (path) => {
    if (selectedColor) {
      const colorData = colors[selectedLevel].find(
        (c) => c.color === selectedColor
      );

      if (
        path.includes(colorData.points[0]) &&
        path.includes(colorData.points[1])
      ) {
        return true;
      }
      return false;
    }
  };

  const updateGridWithCompletedPath = () => {
    const newGrid = [...grid];
    currentPath.forEach((index) => {
      newGrid[index] = { ...newGrid[index], connected: true }; // Đánh dấu ô đã nối
    });
    setGrid(newGrid);
    setPathComplete((prev) => ({ ...prev, [selectedColor]: true }));
  };

  const checkSolution = () => {
    const isSolutionCorrect = Object.entries(
      solutionPaths[selectedLevel]
    ).every(([color, path]) => {
      return path.every((index) => grid[index]?.color === color);
    });

    if (isSolutionCorrect) {
      setGameOver(true);
    } else {
      setGameOver(false); // Reset gameOver state
    }
  };

  const isValidMove = (index) => {
    const lastRow = Math.floor(lastPosition / gridSize);
    const lastCol = lastPosition % gridSize;
    const currentRow = Math.floor(index / gridSize);
    const currentCol = index % gridSize;

    const isVerticalMove =
      lastCol === currentCol && Math.abs(currentRow - lastRow) === 1;
    const isHorizontalMove =
      lastRow === currentRow && Math.abs(currentCol - lastCol) === 1;

    return isVerticalMove || isHorizontalMove;
  };

  const resetGame = () => {
    const newGrid = grid.map((tile) => (tile && tile.isEndpoint ? tile : null));

    // Set endpoints as per `colors` array
    colors[selectedLevel].forEach(({ color, points, value }) => {
      points.forEach((point) => {
        newGrid[point] = { color, isEndpoint: true, connected: false, value };
      });
    });

    setGrid(newGrid);
    setGameOver(null);
  };

  const handleTouchMove = (event) => {
    if (gameOver || !isDragging || !selectedColor) return;

    // Get the touch coordinates
    const touch = event.touches[0];
    const targetElement = document.elementFromPoint(
      touch.clientX,
      touch.clientY
    );

    if (targetElement && targetElement.classList.contains("grid")) {
      const index = parseInt(targetElement.getAttribute("data-index"), 10);
      handleMouseOver(index); // Reuse handleMouseOver for logic
    }
  };

  useEffect(() => {
    initializeGrid();
  }, [selectedLevel]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      console.log(window.innerWidth);
      if (window.innerWidth <= 460) {
        setResponse(true);
      } else {
        setResponse(false);
      }
    });
  }, []);

  return (
    <>
      <ScrollRefWrapper />
      {!isOpen && (
        <div style={{ position: "sticky", top: "15rem", width: "11rem" }}>
          <button
            className="has-text-kurio-main-color-white has-background-kurio-main-color-black"
            style={{
              padding: "0.5rem 1rem",
              border: "none",
              borderRadius: "0.5rem",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              fontSize: "0.85rem",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
            onClick={toggleChallenge}
          >
            Chọn thử thách
          </button>
        </div>
      )}
      <div
        className={`challenge-menu ${
          isOpen ? "open" : ""
        } is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-flex-gap-5`}
      >
        <h2
          style={{
            paddingTop: "1rem",
            fontSize: "1rem",
            fontWeight: 700,
            textTransform: "uppercase",
          }}
          className="has-text-kurio-main-color-black "
        >
          Chọn Thử Thách
        </h2>
        <div className="challenge-grid">
          {levelBackgrounds.map((background, index) => (
            <div
              key={index}
              style={{
                background: background,
                border: selectedLevel === index ? "2px solid black" : "none",
              }}
              onClick={() => handleSelectedLevel(index)}
              className="challenge-button"
            >
              <p className="has-text-kurio-main-color-black">{index + 1}</p>
            </div>
          ))}
        </div>
      </div>

      <div
        onClick={() => setIsOpen(false)}
        style={{ paddingTop: isOpen ? "2.05rem" : 0 }}
        className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-flex-gap-6 pb-7 px-3"
      >
        <div className="is-flex is-flex-direction-column  is-align-items-center is-flex-gap-4">
          <p
            style={{
              fontSize: "1.2rem",
              textTransform: "uppercase",
              fontWeight: 700,
            }}
            className="has-text-kurio-main-color-black"
          >
            Bài toán nối
          </p>

          <div className="is-flex is-flex-direction-column is-align-content-flex-start is-flex-gap-3">
            <div>
              <p
                style={{
                  fontSize: "0.9rem",
                  fontWeight: 700,
                }}
                className="has-text-kurio-main-color-black"
              >
                Cách chơi:
              </p>
              <p
                style={{
                  fontSize: "0.85rem",
                  fontWeight: 500,
                }}
                className="has-text-kurio-main-color-black"
              >
                Nối hai số bằng các đường màu chạy dọc theo các ô vuông sao cho
                mỗi ô vuông có đúng một đường đi qua.
              </p>
            </div>

            <div>
              <p
                style={{
                  fontSize: "0.9rem",
                  fontWeight: 700,
                }}
                className="has-text-kurio-main-color-black"
              >
                Hướng dẫn chơi:
              </p>
              <p
                style={{
                  fontSize: "0.85rem",
                  fontWeight: 500,
                }}
                className="has-text-kurio-main-color-black"
              >
                Nên chơi trên máy tính với chuột, bắt đầu kéo từ 1 ô đến 1 ô
                cùng màu ở vị trí khác để tô màu đường đi, chỉ có thể kéo tô màu
                từ các ô.
              </p>
            </div>
          </div>
        </div>

        <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-flex-gap-4">
          <div
            style={{
              padding: "0.2rem 0.75rem",
              borderRadius: "0.5rem",
              background:
                selectedLevel === 0
                  ? "#acefef"
                  : selectedLevel === 1
                  ? "#dfbffe"
                  : selectedLevel === 2
                  ? "#f4b3a8"
                  : selectedLevel === 3
                  ? "#f4bdde"
                  : selectedLevel === 4
                  ? "#b5eaca"
                  : "#f7ecc1",
            }}
          >
            <p
              style={{
                fontSize: "1.2rem",
                textTransform: "uppercase",
                fontWeight: 700,
              }}
              className="has-text-kurio-main-color-black"
            >
              Thử thách {selectedLevel + 1}
            </p>
          </div>

          <div
            className="game-board"
            style={{
              gridTemplateColumns: response
                ? `repeat(${gridSize}, 35px)`
                : `repeat(${gridSize}, 45px)`,
            }}
            onMouseLeave={handleMouseUp}
            onTouchEnd={handleMouseUp}
          >
            {grid.map((tile, index) => {
              const isConnected = tile && tile.connected;

              return (
                <React.Fragment key={index}>
                  <div
                    data-index={index}
                    className={`grid ${isConnected ? "connected" : ""} ${
                      tile &&
                      (tile.value === 1 ||
                        tile.value === 6 ||
                        tile.value === 2 ||
                        tile.value === 5 ||
                        tile.value === 9)
                        ? "has-text-kurio-main-color-white"
                        : "has-text-kurio-main-color-black"
                    }`}
                    style={{
                      backgroundColor: tile ? tile.color : "white",
                      fontWeight: 700,
                      fontSize: "1.2rem",
                    }}
                    onMouseDown={() => handleMouseDown(index)}
                    onTouchStart={() => handleMouseDown(index)}
                    onMouseOver={() => handleMouseOver(index)}
                    onTouchMove={handleTouchMove}
                    onMouseUp={handleMouseUp}
                  >
                    {tile ? tile.value : ""}
                  </div>
                </React.Fragment>
              );
            })}
          </div>

          {gameOver === true && (
            <div className="correct-message">Đúng rồi, làm tốt lắm!</div>
          )}

          {gameOver === false && (
            <div className="incorrect-message">Không đúng, hãy thử lại!</div>
          )}

          <div
            className=" is-flex is-align-items-center is-justify-content-center is-uppercase has-text-weight-bold has-text-kurio-main-color-black "
            style={{
              height: "3rem",
              borderRadius: "1rem",
              width: "8rem",
              fontSize: "1rem",
              border: "2px solid",
              cursor: "pointer",
            }}
            onClick={resetGame}
          >
            Chơi lại
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchingColorGame;
