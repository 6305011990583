const gridsConfig = [
  // LEVEL 1
  {
    initialSolutionGrid: [
      ["#50e250", "#50e250", "yellow", "#da0eda", "#da0eda", "#da0eda"],
      ["#da0eda", "#da0eda", "yellow", "red", "yellow", "red"],
      ["yellow", "#da0eda", "red", "red", "#50e250", "#50e250"],
      ["yellow", "#50e250", "red", "#da0eda", "#da0eda", "#da0eda"],
      ["#50e250", "#50e250", "yellow", "yellow", "#da0eda", "yellow"],
      ["red", "#50e250", "red", "red", "red", "yellow"],
    ],
    grid: [
      ["#50e250", "#50e250", null, "#da0eda", "#da0eda", "#da0eda"],
      [null, null, null, null, null, null],
      ["yellow", null, null, null, "#50e250", "#50e250"],
      ["yellow", null, null, null, null, null],
      [null, null, null, null, null, "yellow"],
      ["red", null, null, null, null, "yellow"],
    ],
    customStyles: {
      "0,0": {
        borderRight: "none",
        backgroundColor: "#50e250",
        borderLeft: "2px solid black",
        borderTop: "2px solid black",
      },
      "0,1": {
        borderLeft: "none",
        backgroundColor: "#50e250",
        borderTop: "2px solid black",
      },
      "0,2": { borderBottom: "none", borderTop: "2px solid black" },
      "0,3": {
        borderRight: "none",
        backgroundColor: "#da0eda",
        borderTop: "2px solid black",
      },
      "0,4": {
        borderLeft: "none",
        borderRight: "none",
        backgroundColor: "#da0eda",
        borderTop: "2px solid black",
      },
      "0,5": {
        borderLeft: "none",
        backgroundColor: "#da0eda",
        borderRight: "2px solid black",
        borderTop: "2px solid black",
      },
      "1,0": { borderRight: "none", borderLeft: "2px solid black" },
      "1,1": { borderLeft: "none", borderBottom: "none" },
      "1,2": { borderTop: "none" },
      "1,3": { borderBottom: "none" },
      "1,5": { borderRight: "2px solid black" },
      "2,0": {
        borderBottom: "none",
        backgroundColor: "yellow",
        borderLeft: "2px solid black",
      },
      "2,1": { borderTop: "none" },
      "2,2": { borderBottom: "none", borderRight: "none" },
      "2,3": { borderLeft: "none", borderTop: "none" },
      "2,4": { borderRight: "none", backgroundColor: "#50e250" },
      "2,5": {
        borderLeft: "none",
        backgroundColor: "#50e250",
        borderRight: "2px solid black",
      },
      "3,0": {
        borderTop: "none",
        backgroundColor: "yellow",
        borderLeft: "2px solid black",
      },
      "3,1": { borderBottom: "none" },
      "3,2": { borderTop: "none" },
      "3,3": { borderRight: "none" },
      "3,4": {
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "none",
      },
      "3,5": { borderLeft: "none", borderRight: "2px solid black" },
      "4,0": { borderRight: "none", borderLeft: "2px solid black" },
      "4,1": { borderBottom: "none", borderLeft: "none", borderTop: "none" },
      "4,2": { borderRight: "none" },
      "4,3": { borderLeft: "none" },
      "4,4": { borderTop: "none" },
      "4,5": {
        backgroundColor: "yellow",
        borderBottom: "none",
        borderRight: "2px solid black",
      },
      "5,0": {
        backgroundColor: "red",
        borderLeft: "2px solid black",
        borderBottom: "2px solid black",
      },
      "5,1": { borderTop: "none", borderBottom: "2px solid black" },
      "5,2": { borderRight: "none", borderBottom: "2px solid black" },
      "5,3": {
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "2px solid black",
      },
      "5,4": { borderLeft: "none", borderBottom: "2px solid black" },
      "5,5": {
        backgroundColor: "yellow",
        borderTop: "none",
        borderRight: "2px solid black",
        borderBottom: "2px solid black",
      },
    },
  },
  // LEVEL 2

  {
    initialSolutionGrid: [
      ["#50e250", "red", "yellow", "yellow", "#50e250", "#da0eda"],
      ["yellow", "red", "#50e250", "red", "red", "red"],
      ["yellow", "red", "#50e250", "red", "yellow", "yellow"],
      ["#da0eda", "#da0eda", "#da0eda", "#da0eda", "#da0eda", "#50e250"],
      ["yellow", "yellow", "red", "red", "#da0eda", "yellow"],
      ["red", "#50e250", "#50e250", "#50e250", "#50e250", "yellow"],
    ],
    grid: [
      ["#50e250", null, "yellow", "yellow", null, "#da0eda"],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, "#50e250"],
      [null, null, null, null, null, "yellow"],
      ["red", null, null, null, null, "yellow"],
    ],
    customStyles: {
      "0,0": {
        backgroundColor: "#50e250",
        borderLeft: "2px solid black",
        borderTop: "2px solid black",
      },
      "0,1": {
        borderBottom: "none",
        borderTop: "2px solid black",
      },
      "0,2": {
        borderRight: "none",
        backgroundColor: "yellow",
        borderTop: "2px solid black",
      },
      "0,3": {
        borderLeft: "none",
        backgroundColor: "yellow",
        borderTop: "2px solid black",
      },
      "0,4": {
        borderTop: "2px solid black",
      },
      "0,5": {
        backgroundColor: "#da0eda",
        borderRight: "2px solid black",
        borderTop: "2px solid black",
      },
      "1,0": { borderBottom: "none", borderLeft: "2px solid black" },
      "1,1": { borderTop: "none", borderBottom: "none" },
      "1,2": { borderBottom: "none" },
      "1,3": { borderBottom: "none", borderRight: "none" },
      "1,4": { borderLeft: "none", borderRight: "none" },
      "1,5": { borderLeft: "none", borderRight: "2px solid black" },
      "2,0": {
        borderTop: "none",
        borderLeft: "2px solid black",
      },
      "2,1": { borderTop: "none" },
      "2,2": { borderTop: "none" },
      "2,3": { borderTop: "none" },
      "2,4": { borderRight: "none" },
      "2,5": {
        borderLeft: "none",
        borderRight: "2px solid black",
      },
      "3,0": {
        borderRight: "none",
        borderLeft: "2px solid black",
      },
      "3,1": { borderLeft: "none", borderRight: "none" },
      "3,2": { borderLeft: "none", borderRight: "none" },
      "3,3": { borderRight: "none", borderLeft: "none" },
      "3,4": {
        borderLeft: "none",
        borderBottom: "none",
      },
      "3,5": { backgroundColor: "#50e250", borderRight: "2px solid black" },
      "4,0": { borderRight: "none", borderLeft: "2px solid black" },
      "4,1": { borderLeft: "none" },
      "4,2": { borderRight: "none" },
      "4,3": { borderLeft: "none" },
      "4,4": { borderTop: "none" },
      "4,5": {
        backgroundColor: "yellow",
        borderBottom: "none",
        borderRight: "2px solid black",
      },
      "5,0": {
        backgroundColor: "red",
        borderLeft: "2px solid black",
        borderBottom: "2px solid black",
      },
      "5,1": { borderRight: "none", borderBottom: "2px solid black" },
      "5,2": {
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "2px solid black",
      },
      "5,3": {
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "2px solid black",
      },
      "5,4": { borderLeft: "none", borderBottom: "2px solid black" },
      "5,5": {
        backgroundColor: "yellow",
        borderTop: "none",
        borderRight: "2px solid black",
        borderBottom: "2px solid black",
      },
    },
  },

  // LEVEL 3

  {
    initialSolutionGrid: [
      ["#50e250", "yellow", "yellow", "red", "#50e250", "red", "#da0eda"],
      ["#50e250", "red", "#da0eda", "#da0eda", "#50e250", "yellow", "#da0eda"],
      ["#50e250", "yellow", "yellow", "#da0eda", "red", "red", "red"],
      ["red", "red", "red", "#da0eda", "yellow", "#50e250", "yellow"],
      ["red", "yellow", "yellow", "#da0eda", "red", "#50e250", "red"],
      [
        "yellow",
        "yellow",
        "#50e250",
        "#da0eda",
        "#50e250",
        "#50e250",
        "#da0eda",
      ],
      ["red", "yellow", "#50e250", "#da0eda", "yellow", "yellow", "yellow"],
    ],
    grid: [
      ["#50e250", null, null, null, "#50e250", null, "#da0eda"],
      ["#50e250", null, null, null, "#50e250", null, "#da0eda"],
      ["#50e250", null, null, null, null, null, null],
      [null, null, null, null, null, null, "yellow"],
      [null, null, null, null, null, null, null],
      [null, null, "#50e250", null, null, null, "#da0eda"],
      ["red", null, "#50e250", null, "yellow", "yellow", "yellow"],
    ],
    customStyles: {
      "0,0": {
        backgroundColor: "#50e250",
        borderLeft: "2px solid black",
        borderTop: "2px solid black",
        borderBottom: "none",
      },
      "0,1": {
        borderRight: "none",
        borderTop: "2px solid black",
      },
      "0,2": {
        borderLeft: "none",
        borderTop: "2px solid black",
      },
      "0,3": {
        borderTop: "2px solid black",
      },
      "0,4": {
        backgroundColor: "#50e250",
        borderTop: "2px solid black",
        borderBottom: "none",
      },
      "0,5": {
        borderTop: "2px solid black",
      },
      "0,6": {
        borderRight: "2px solid black",
        backgroundColor: "#da0eda",
        borderBottom: "none",
        borderTop: "2px solid black",
      },
      "1,0": {
        borderTop: "none",
        backgroundColor: "#50e250",
        borderBottom: "none",
        borderLeft: "2px solid black",
      },
      "1,2": { borderRight: "none" },
      "1,3": { borderBottom: "none", borderLeft: "none" },
      "1,4": { borderTop: "none", backgroundColor: "#50e250" },
      "1,6": {
        borderRight: "2px solid black",
        backgroundColor: "#da0eda",
        borderTop: "none",
      },
      "2,0": {
        backgroundColor: "#50e250",
        borderTop: "none",
        borderLeft: "2px solid black",
      },
      "2,1": { borderRight: "none" },
      "2,2": { borderLeft: "none" },
      "2,3": { borderTop: "none", borderBottom: "none" },
      "2,4": { borderRight: "none" },
      "2,5": {
        borderLeft: "none",
        borderRight: "none",
      },
      "2,6": {
        borderLeft: "none",
        borderRight: "2px solid black",
      },
      "3,0": {
        borderRight: "none",
        borderLeft: "2px solid black",
        borderBottom: "none",
      },
      "3,1": { borderLeft: "none", borderRight: "none" },
      "3,2": { borderLeft: "none" },
      "3,3": { borderTop: "none", borderBottom: "none" },
      "3,5": { borderBottom: "none" },
      "3,6": { backgroundColor: "yellow", borderRight: "2px solid black" },
      "4,0": { borderTop: "none", borderLeft: "2px solid black" },
      "4,1": { borderRight: "none", borderBottom: "none" },
      "4,2": { borderLeft: "none" },
      "4,3": { borderTop: "none", borderBottom: "none" },
      "4,5": {
        borderTop: "none",
        borderBottom: "none",
      },
      "4,6": {
        borderRight: "2px solid black",
      },
      "5,0": {
        borderLeft: "2px solid black",
        borderRight: "none",
      },
      "5,1": { borderTop: "none", borderLeft: "none", borderBottom: "none" },
      "5,2": {
        borderBottom: "none",
        backgroundColor: "#50e250",
      },
      "5,3": {
        borderTop: "none",
        borderBottom: "none",
      },
      "5,4": { borderRight: "none" },
      "5,5": { borderTop: "none", borderLeft: "none" },
      "5,6": {
        backgroundColor: "#da0eda",
        borderRight: "2px solid black",
      },
      "6,0": {
        backgroundColor: "red",
        borderLeft: "2px solid black",
        borderBottom: "2px solid black",
      },
      "6,1": { borderTop: "none", borderBottom: "2px solid black" },
      "6,2": {
        borderTop: "none",
        backgroundColor: "#50e250",
        borderBottom: "2px solid black",
      },
      "6,3": {
        borderTop: "none",
        borderBottom: "2px solid black",
      },
      "6,4": {
        backgroundColor: "yellow",
        borderRight: "none",
        borderBottom: "2px solid black",
      },
      "6,5": {
        backgroundColor: "yellow",
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "2px solid black",
      },
      "6,6": {
        backgroundColor: "yellow",
        borderLeft: "none",
        borderRight: "2px solid black",
        borderBottom: "2px solid black",
      },
    },
  },

  //  LELVEL 4
  {
    initialSolutionGrid: [
      ["#50e250", "red", "#da0eda", "#da0eda", "#da0eda", "#da0eda"],
      ["yellow", "yellow", "yellow", "red", "yellow", "red"],
      ["#50e250", "#da0eda", "red", "red", "#50e250", "#50e250"],
      ["#50e250", "yellow", "yellow", "#da0eda", "#50e250", "#da0eda"],
      ["red", "red", "yellow", "#da0eda", "red", "#da0eda"],
      ["red", "yellow", "yellow", "#50e250", "red", "yellow"],
    ],
    grid: [
      ["#50e250", null, "#da0eda", "#da0eda", "#da0eda", "#da0eda"],
      [null, null, null, null, null, null],
      ["#50e250", null, null, null, "#50e250", "#50e250"],
      ["#50e250", null, null, null, "#50e250", null],
      ["red", "red", null, null, null, null],
      ["red", null, null, null, null, "yellow"],
    ],
    customStyles: {
      "0,0": {
        backgroundColor: "#50e250",
        borderLeft: "2px solid black",
        borderTop: "2px solid black",
      },
      "0,1": {
        borderTop: "2px solid black",
      },
      "0,2": {
        backgroundColor: "#da0eda",
        borderRight: "none",
        borderTop: "2px solid black",
      },
      "0,3": {
        borderRight: "none",
        borderLeft: "none",
        backgroundColor: "#da0eda",
        borderTop: "2px solid black",
      },
      "0,4": {
        borderLeft: "none",
        borderRight: "none",
        backgroundColor: "#da0eda",
        borderTop: "2px solid black",
      },
      "0,5": {
        borderLeft: "none",
        backgroundColor: "#da0eda",
        borderRight: "2px solid black",
        borderTop: "2px solid black",
      },
      "1,0": { borderRight: "none", borderLeft: "2px solid black" },
      "1,1": { borderLeft: "none", borderRight: "none" },
      "1,2": { borderLeft: "none" },
      "1,3": { borderBottom: "none" },
      "1,5": { borderRight: "2px solid black" },
      "2,0": {
        borderBottom: "none",
        backgroundColor: "#50e250",
        borderLeft: "2px solid black",
      },
      "2,2": { borderRight: "none" },
      "2,3": { borderLeft: "none", borderTop: "none" },
      "2,4": {
        borderRight: "none",
        borderBottom: "none",
        backgroundColor: "#50e250",
      },
      "2,5": {
        borderLeft: "none",
        backgroundColor: "#50e250",
        borderRight: "2px solid black",
      },
      "3,0": {
        borderTop: "none",
        backgroundColor: "#50e250",
        borderLeft: "2px solid black",
      },
      "3,1": { borderRight: "none" },
      "3,2": { borderLeft: "none", borderBottom: "none" },
      "3,3": { borderBottom: "none" },
      "3,4": {
        borderTop: "none",
        backgroundColor: "#50e250",
      },
      "3,5": { borderBottom: "none", borderRight: "2px solid black" },
      "4,0": {
        borderRight: "none",
        borderBottom: "none",
        backgroundColor: "red",
        borderLeft: "2px solid black",
      },
      "4,1": { borderLeft: "none", backgroundColor: "red" },
      "4,2": { borderTop: "none", borderBottom: "none" },
      "4,3": { borderTop: "none" },
      "4,4": { borderBottom: "none" },
      "4,5": {
        borderTop: "none",
        borderRight: "2px solid black",
      },
      "5,0": {
        backgroundColor: "red",
        borderLeft: "2px solid black",
        borderBottom: "2px solid black",
        borderTop: "none",
      },
      "5,1": { borderRight: "none", borderBottom: "2px solid black" },
      "5,2": {
        borderTop: "none",
        borderLeft: "none",
        borderBottom: "2px solid black",
      },
      "5,3": {
        borderBottom: "2px solid black",
      },
      "5,4": { borderTop: "none", borderBottom: "2px solid black" },
      "5,5": {
        backgroundColor: "yellow",
        borderRight: "2px solid black",
        borderBottom: "2px solid black",
      },
    },
  },

  //  LELVEL 5
  {
    initialSolutionGrid: [
      [
        "#50e250",
        "red",
        "red",
        "#50e250",
        "#da0eda",
        "yellow",
        "#50e250",
        "#da0eda",
      ],
      ["red", "red", "red", "#da0eda", "#da0eda", "red", "red", "red"],
      [
        "yellow",
        "yellow",
        "#50e250",
        "#50e250",
        "#50e250",
        "red",
        "yellow",
        "red",
      ],
      [
        "#da0eda",
        "#50e250",
        "#50e250",
        "yellow",
        "#50e250",
        "yellow",
        "yellow",
        "#50e250",
      ],
      [
        "red",
        "red",
        "red",
        "yellow",
        "#50e250",
        "yellow",
        "#da0eda",
        "#da0eda",
      ],
      [
        "#50e250",
        "#da0eda",
        "#da0eda",
        "yellow",
        "yellow",
        "yellow",
        "red",
        "red",
      ],
      [
        "yellow",
        "yellow",
        "#da0eda",
        "#50e250",
        "#da0eda",
        "#50e250",
        "#50e250",
        "#da0eda",
      ],
      ["red", "#da0eda", "#da0eda", "red", "red", "red", "#50e250", "yellow"],
    ],
    grid: [
      ["#50e250", "red", null, "#50e250", null, "yellow", null, "#da0eda"],
      [null, null, null, null, null, null, null, null],
      ["yellow", "yellow", null, null, null, null, null, null],
      [null, null, null, null, null, null, null, null],
      [null, null, null, null, null, null, "#da0eda", "#da0eda"],
      [null, null, null, null, null, null, null, null],
      ["yellow", "yellow", null, null, null, "#50e250", "#50e250", null],
      ["red", null, null, "red", "red", "red", "#50e250", "yellow"],
    ],
    customStyles: {
      "0,0": {
        backgroundColor: "#50e250",
        borderLeft: "2px solid black",
        borderTop: "2px solid black",
      },
      "0,1": {
        backgroundColor: "yellow",
        borderTop: "2px solid black",
      },
      "0,2": {
        borderBottom: "none",
        borderTop: "2px solid black",
      },
      "0,3": {
        backgroundColor: "#50e250",
        borderTop: "2px solid black",
      },
      "0,4": {
        borderBottom: "none",
        borderTop: "2px solid black",
      },
      "0,5": {
        backgroundColor: "yellow",
        borderTop: "2px solid black",
      },
      "0,6": {
        borderTop: "2px solid black",
      },
      "0,7": {
        backgroundColor: "#da0eda",
        borderRight: "2px solid black",
        borderTop: "2px solid black",
      },
      "1,0": { borderRight: "none", borderLeft: "2px solid black" },
      "1,1": { borderLeft: "none", borderRight: "none" },
      "1,2": { borderLeft: "none", borderTop: "none" },
      "1,3": { borderRight: "none" },
      "1,4": { borderLeft: "none", borderTop: "none" },
      "1,5": { borderRight: "none", borderBottom: "none" },
      "1,6": { borderLeft: "none", borderRight: "none" },
      "1,7": {
        borderLeft: "none",
        borderBottom: "none",
        borderRight: "2px solid black",
      },
      "2,0": {
        borderRight: "none",
        backgroundColor: "yellow",
        borderLeft: "2px solid black",
      },
      "2,1": {
        borderLeft: "none",
        backgroundColor: "yellow",
      },
      "2,2": { borderRight: "none", borderBottom: "none" },
      "2,3": {
        borderRight: "none",
        borderLeft: "none",
      },
      "2,4": {
        borderLeft: "none",
        borderBottom: "none",
      },
      "2,5": {
        borderTop: "none",
      },
      "2,6": {
        borderBottom: "none",
      },
      "2,7": {
        borderTop: "none",
        borderRight: "2px solid black",
      },
      "3,0": {
        borderLeft: "2px solid black",
      },
      "3,1": { borderRight: "none" },
      "3,2": { borderLeft: "none", borderTop: "none" },
      "3,3": { borderBottom: "none" },
      "3,4": {
        borderTop: "none",
        borderBottom: "none",
      },
      "3,5": {
        borderRight: "none",
        borderBottom: "none",
      },
      "3,6": {
        borderLeft: "none",
        borderTop: "none",
      },
      "3,7": { borderRight: "2px solid black" },
      "4,0": {
        borderRight: "none",
        borderLeft: "2px solid black",
      },
      "4,1": { borderLeft: "none", borderRight: "none" },
      "4,2": { borderLeft: "none" },
      "4,3": { borderTop: "none", borderBottom: "none" },
      "4,4": { borderTop: "none" },
      "4,5": { borderTop: "none", borderBottom: "none" },
      "4,6": { borderRight: "none", backgroundColor: "#da0eda" },
      "4,7": {
        borderLeft: "none",
        backgroundColor: "#da0eda",
        borderRight: "2px solid black",
      },
      "5,0": {
        borderLeft: "2px solid black",
      },
      "5,1": { borderRight: "none" },
      "5,2": {
        borderBottom: "none",
        borderLeft: "none",
      },
      "5,3": {
        borderTop: "none",
        borderRight: "none",
      },
      "5,4": { borderRight: "none", borderLeft: "none" },
      "5,5": {
        borderLeft: "none",
        borderTop: "none",
      },
      "5,6": {
        borderRight: "none",
      },
      "5,7": {
        borderLeft: "none",
        borderRight: "2px solid black",
      },
      "6,0": {
        borderRight: "none",
        backgroundColor: "yellow",
        borderLeft: "2px solid black",
      },
      "6,1": { borderLeft: "none", backgroundColor: "yellow" },
      "6,2": {
        borderBottom: "none",
        borderTop: "none",
      },
      "6,5": {
        borderRight: "none",
        backgroundColor: "#50e250",
      },
      "6,6": {
        borderLeft: "none",
        borderBottom: "none",
        backgroundColor: "#50e250",
      },
      "6,7": {
        borderRight: "2px solid black",
      },
      "7,0": {
        backgroundColor: "red",
        borderLeft: "2px solid black",
        borderBottom: "2px solid black",
      },
      "7,1": { borderRight: "none", borderBottom: "2px solid black" },
      "7,2": {
        borderLeft: "none",
        borderTop: "none",
        borderBottom: "2px solid black",
      },
      "7,3": {
        borderRight: "none",
        backgroundColor: "red",
        borderBottom: "2px solid black",
      },
      "7,4": {
        borderRight: "none",
        borderLeft: "none",
        backgroundColor: "red",
        borderBottom: "2px solid black",
      },
      "7,5": {
        borderLeft: "none",
        backgroundColor: "red",
        borderBottom: "2px solid black",
      },
      "7,6": {
        borderTop: "none",
        backgroundColor: "#50e250",
        borderBottom: "2px solid black",
      },
      "7,7": {
        backgroundColor: "yellow",
        borderRight: "2px solid black",
        borderBottom: "2px solid black",
      },
    },
  },

  //  LELVEL 6
  {
    initialSolutionGrid: [
      [
        "#50e250",
        "#da0eda",
        "yellow",
        "yellow",
        "red",
        "red",
        "yellow",
        "#da0eda",
      ],
      [
        "#50e250",
        "#da0eda",
        "#50e250",
        "yellow",
        "#da0eda",
        "red",
        "yellow",
        "red",
      ],
      ["#50e250", "#da0eda", "red", "red", "#da0eda", "red", "#da0eda", "red"],
      [
        "yellow",
        "yellow",
        "yellow",
        "red",
        "#50e250",
        "#50e250",
        "#50e250",
        "red",
      ],
      [
        "red",
        "#da0eda",
        "#da0eda",
        "red",
        "#da0eda",
        "yellow",
        "#da0eda",
        "red",
      ],
      [
        "#50e250",
        "#50e250",
        "red",
        "red",
        "#50e250",
        "#50e250",
        "#50e250",
        "#50e250",
      ],
      [
        "red",
        "#50e250",
        "#50e250",
        "#da0eda",
        "#50e250",
        "yellow",
        "red",
        "red",
      ],
      [
        "red",
        "#da0eda",
        "yellow",
        "yellow",
        "yellow",
        "yellow",
        "#da0eda",
        "yellow",
      ],
    ],
    grid: [
      ["#50e250", null, "yellow", "yellow", null, null, "yellow", "#da0eda"],
      ["#50e250", null, null, "yellow", null, null, "yellow", null],
      ["#50e250", null, null, null, null, null, "#da0eda", null],
      [null, null, null, null, null, null, null, null],
      [null, null, null, null, null, "yellow", null, null],
      [null, null, null, null, null, null, null, null],
      ["red", null, null, null, null, "yellow", null, null],
      [
        "red",
        null,
        "yellow",
        "yellow",
        "yellow",
        "yellow",
        "#da0eda",
        "yellow",
      ],
    ],
    customStyles: {
      "0,0": {
        backgroundColor: "#50e250",
        borderBottom: "none",
        borderLeft: "2px solid black",
        borderTop: "2px solid black",
      },
      "0,1": {
        borderTop: "2px solid black",
        borderBottom: "none",
      },
      "0,2": {
        borderRight: "none",
        backgroundColor: "yellow",
        borderTop: "2px solid black",
      },
      "0,3": {
        borderLeft: "none",
        borderBottom: "none",
        backgroundColor: "yellow",
        borderTop: "2px solid black",
      },
      "0,4": {
        borderRight: "none",
        borderTop: "2px solid black",
      },
      "0,5": {
        borderLeft: "none",
        borderBottom: "none",
        borderTop: "2px solid black",
      },
      "0,6": {
        borderBottom: "none",
        backgroundColor: "yellow",
        borderTop: "2px solid black",
      },
      "0,7": {
        backgroundColor: "#da0eda",
        borderRight: "2px solid black",
        borderTop: "2px solid black",
      },
      "1,0": {
        backgroundColor: "#50e250",
        borderTop: "none",
        borderBottom: "none",
        borderLeft: "2px solid black",
      },
      "1,1": { borderTop: "none", borderBottom: "none" },
      "1,3": { borderTop: "none" },
      "1,4": { borderBottom: "none" },
      "1,5": { borderTop: "none", borderBottom: "none" },
      "1,6": { borderTop: "none", backgroundColor: "yellow" },
      "1,7": {
        borderBottom: "none",
        borderRight: "2px solid black",
      },
      "2,0": {
        borderTop: "none",
        backgroundColor: "#50e250",
        borderLeft: "2px solid black",
      },
      "2,1": {
        borderTop: "none",
      },
      "2,2": { borderRight: "none" },
      "2,3": {
        borderBottom: "none",
        borderLeft: "none",
      },
      "2,4": {
        borderTop: "none",
      },
      "2,5": {
        borderTop: "none",
      },
      "2,6": {
        backgroundColor: "#da0eda",
      },
      "2,7": {
        borderTop: "none",
        borderBottom: "none",
        borderRight: "2px solid black",
      },
      "3,0": {
        borderRight: "none",
        borderLeft: "2px solid black",
      },
      "3,1": { borderLeft: "none", borderRight: "none" },
      "3,2": { borderLeft: "none" },
      "3,3": { borderBottom: "none", borderTop: "none" },
      "3,4": {
        borderRight: "none",
      },
      "3,5": {
        borderLeft: "none",
        borderRight: "none",
      },
      "3,6": {
        borderLeft: "none",
      },
      "3,7": {
        borderBottom: "none",
        borderTop: "none",
        borderRight: "2px solid black",
      },
      "4,0": {
        borderLeft: "2px solid black",
      },
      "4,1": { borderRight: "none" },
      "4,2": { borderLeft: "none" },
      "4,3": { borderTop: "none", borderBottom: "none" },
      "4,5": { backgroundColor: "yellow" },
      "4,7": {
        borderTop: "none",
        borderRight: "2px solid black",
      },
      "5,0": {
        borderRight: "none",
        borderLeft: "2px solid black",
      },
      "5,1": { borderLeft: "none", borderBottom: "none" },
      "5,2": {
        borderRight: "none",
      },
      "5,3": {
        borderTop: "none",
        borderLeft: "none",
      },
      "5,4": { borderRight: "none", borderBottom: "none" },
      "5,5": {
        borderLeft: "none",
        borderRight: "none",
      },
      "5,6": {
        borderLeft: "none",
        borderRight: "none",
      },
      "5,7": {
        borderLeft: "none",
        borderRight: "2px solid black",
      },
      "6,0": {
        borderBottom: "none",
        backgroundColor: "red",
        borderLeft: "2px solid black",
      },
      "6,1": { borderTop: "none", borderRight: "none" },
      "6,2": {
        borderLeft: "none",
      },
      "6,4": {
        borderTop: "none",
      },
      "6,5": {
        borderBottom: "none",
        backgroundColor: "yellow",
      },
      "6,6": {
        borderRight: "none",
      },
      "6,7": {
        borderLeft: "none",
        borderRight: "2px solid black",
      },
      "7,0": {
        backgroundColor: "red",
        borderTop: "none",
        borderLeft: "2px solid black",
        borderBottom: "2px solid black",
      },
      "7,1": { borderBottom: "2px solid black" },
      "7,2": {
        borderRight: "none",
        backgroundColor: "yellow",
        borderBottom: "2px solid black",
      },
      "7,3": {
        borderRight: "none",
        borderLeft: "none",
        backgroundColor: "yellow",
        borderBottom: "2px solid black",
      },
      "7,4": {
        borderRight: "none",
        borderLeft: "none",
        backgroundColor: "yellow",
        borderBottom: "2px solid black",
      },
      "7,5": {
        borderTop: "none",
        borderLeft: "none",
        backgroundColor: "yellow",
        borderBottom: "2px solid black",
      },
      "7,6": {
        backgroundColor: "#da0eda",
        borderBottom: "2px solid black",
      },
      "7,7": {
        backgroundColor: "yellow",
        borderRight: "2px solid black",
        borderBottom: "2px solid black",
      },
    },
  },
];

export default gridsConfig;
