/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import {
  getAPracticeQuestions,
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
  submitAnswers,
} from "../../reducers/practiceQuestionSlice";
import { LoadingImage } from "../../templates/helper/LoadingImage";
import { getAllImage } from "../../templates/helper/getAllImages";
import UnitQuizLeftWrapper from "./left/UnitQuizLeftWrapper";
import UnitQuizRightWrapper from "./right/UnitQuizRightWrapper";

export const UnitQuizContext = React.createContext();
const UnitQuizMainWrapper = ({
  questions,
  seenQuestions,
  correctAnswers,
  quiz,
  practiceQuizId,
  practiceQuizIdLP,
  newPracticeId,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const [answerTest, setAnswerTest] = useState(null);
  const [loadingImage, setLoadingImage] = useState(true);
  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };

  const [pagination, setPagination] = useState(
    localStorage.getItem("pagination")
      ? parseInt(localStorage.getItem("pagination"))
      : 1
  );

  const checkingState = questions?.every(
    (item) => item.question.showSolution === true
  );

  const handleChangePagination = (pagination) => {
    setPagination(pagination);
  };

  const handleQuestionChange = (val, isSubmit = true) => {
    if (val !== selectedQuestion) {
      if (
        val < 20 &&
        (!localStorage.getItem("pagination") ||
          parseInt(localStorage.getItem("pagination")) === 2)
      ) {
        localStorage.setItem("pagination", 1);
        setPagination(1);
      } else if (
        val >= 20 &&
        (!localStorage.getItem("pagination") ||
          parseInt(localStorage.getItem("pagination")) === 1)
      ) {
        localStorage.setItem("pagination", 2);
        setPagination(2);
      }

      const result = JSON.parse(localStorage.getItem("questionStateQuiz"));
      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
        var submit = result.submit;

        practiceQuizId = cookies.get("newPracticeId")
          ? cookies.get("newPracticeId")
          : cookies.get("practiceQuizId");

        if (isSubmit) {
          dispatch(
            submitAnswers({
              practiceQuizId,
              submit,
            })
          );
        }
      } else {
        var answeredQuestions = questions.map((question) => ({
          questionId: question.questionId,
          questionIndex: question.questionIndex,
          answer: question.answer?.length > 0 ? question.answer : [],
        }));
        dispatch(setQuestionsAnswered(answeredQuestions));
      }
      localStorage.removeItem("questionStateQuiz");
      setSelectedQuestion(val);
    }
  };

  useEffect(() => {
    // dispatch(fetchPracticeQuestions());

    if (cookies.get("newPracticeId") && !checkingState) {
      newPracticeId = cookies.get("newPracticeId");
      dispatch(getAPracticeQuestions({ newPracticeId, page: 1, size: 50 }));
      if (!questions || questions === null) {
        setLoadingImage(true);
      }
    }

    if (
      practiceQuizId !== null &&
      cookies.get("practiceQuizId") &&
      !cookies.get("practiceQuizIdLP") &&
      !cookies.get("newPracticeId")
    ) {
      practiceQuizId = cookies.get("practiceQuizId");

      dispatch(getAPracticeQuestions({ practiceQuizId, page: 1, size: 50 }));
      if (!questions || questions === null) {
        setLoadingImage(true);
      }
    }

    if (practiceQuizIdLP !== null && cookies.get("practiceQuizIdLP")) {
      practiceQuizIdLP = cookies.get("practiceQuizIdLP");
      dispatch(getAPracticeQuestions({ practiceQuizIdLP, page: 1, size: 50 }));
      if (!questions || questions === null) {
        setLoadingImage(true);
      }
    }
  }, [dispatch, practiceQuizId, practiceQuizIdLP, newPracticeId]);
  if (loadingImage && questions !== null && questions?.length > 0) {
    const result = getAllImage(questions, "QUIZ");
    if (result && result?.length > 0) {
      return (
        <>
          <LoadingImage
            images={result}
            router={""}
            handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
          />
        </>
      );
    } else {
      setLoadingImage(false);
    }
  }

  return (
    <>
      {!loadingImage && questions !== null && questions?.length > 0 && (
        <div className="columns is-mobile m-0 p-0">
          <UnitQuizContext.Provider
            value={{
              answerTest,
              setAnswerTest,
            }}
          >
            {questions && (
              <div
                style={{
                  // backgroundColor: checkingState ? "#F7F1DF" : "#DEEBF7",
                  minHeight: "100vh",
                  padding: "5rem 2.5rem 10rem 2.5rem",
                  marginRight: "18.75rem",
                  width: "calc(100% - 18.75rem)",
                }}
                className="column is-four-fifths is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
              >
                <UnitQuizRightWrapper
                  question={questions[selectedQuestion]}
                  selectedQuestion={selectedQuestion}
                  seenQuestions={seenQuestions}
                  handleQuestionChange={handleQuestionChange}
                  questions={questions}
                  practiceQuizId={
                    newPracticeId ? newPracticeId : practiceQuizId
                  }
                />
              </div>
            )}
            <div
              className="column m-0 is-one-fifth p-0 is-justify-content-center is-align-item-flex-start"
              style={{
                position: "fixed",
                right: "0",
                display: "flex",
                zIndex: 1000,
                flexDirection: "column",
                width: "18.75rem",
                height: "100%",
              }}
            >
              <UnitQuizLeftWrapper
                selectedIndex={selectedQuestion}
                quiz={quiz}
                handleChangePagination={(pagination) =>
                  handleChangePagination(pagination)
                }
                pagination={pagination}
                handleQuestionChange={handleQuestionChange}
                practiceQuizId={newPracticeId ? newPracticeId : practiceQuizId}
              />
            </div>
          </UnitQuizContext.Provider>
        </div>
      )}
      {loadingImage && <LoadingImage />}
    </>
  );
};

function mapStateToProps(state) {
  return {
    practiceQuizId: state.practiceQuestion.quizInformation?.practiceQuizId,
    practiceQuizIdLP: state.practiceQuestion.quizInformation?.practiceQuizIdLP,
    newPracticeId: state.practiceQuestion.newPracticeId,
    quiz: state.practiceQuestion.quizInformation,
    questions: state.practiceQuestion.questions,
    seenQuestions: state.practiceQuestion.seenQuestions,
    correctAnswers: state.practiceQuestion.correctAnswers,
  };
}

export default connect(mapStateToProps)(UnitQuizMainWrapper);
