import React, { useState } from "react";
import { decodeToken } from "react-jwt";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import DuplicateLoginErrorComponent from "../../components/DuplicateLoginErrorComponent";
import LoadingBeforeRedirect from "../../components/LoadingBeforeRedirect";
import { getUserInfo } from "../../config/auth";
import ConstantStrings from "../../constants/ConstantStrings";

const PrivateRouter = ({ page, children }) => {
  const cookies = new Cookies();
  const token = cookies.get("signinUser") || cookies.get("register");
  const refreshToken = cookies.get("refreshToken");
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const roles = ["CREATOR", "REVIEWER"];
  if (page === "LOGIN") {
    if (token) {
      // alert("Ban da dang nhap");
      return <Navigate to={"/"} />;
    }
  }
  const arrayPath = [
    "/account",
    "/profile",
    "/upgrade",
    "/list-unit",
    "/exercise",
    "/activities",
    "/achievement",
    "/",
  ];
  const getUserInformation = async () => {
    const { result } = await getUserInfo();
    return result;
  };
  if (page === "PRIVATE") {
    if (!token && !refreshToken) {
      return <LoadingBeforeRedirect />;
    } else {
      if (refreshToken) {
        return children;
      }
      const decode = decodeToken(token);
      if (!decode.roles || !decode.roles.includes("USER") || !decode.phone) {
        getUserInformation()
          .then((res) => {
            if (res?.phone === null || res?.phone === "") {
              navigate("/validate-phone");
            } else {
              const listCookies = cookies.getAll();
              Object.keys(listCookies).forEach((cookie) => {
                cookies.remove(cookie);
              });
              arrayPath
                .forEach((path) => {
                  if (cookies.get("signinUser", { path: path })) {
                    cookies.remove("signinUser", { path: path });
                  }
                })
                ?.then(() => {
                  window.location.href =
                    process.env.REACT_APP_ENV === "development"
                      ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
                      : process.env.REACT_APP_LANDING_PAGE_URL;
                })
                .catch((error) => {
                  console.error("Error removing cookie:", error);
                });
            }
          })
          .catch((error) => {
            if (error?.response?.status === 409) {
              localStorage.setItem("clearAll", true);
              setShowError(true);
              return <DuplicateLoginErrorComponent isShowPopup={true} />;
            }
          });

        const check = arrayPath.every((path) =>
          cookies.get("signinUser", { path: path })
        );
        if (!check) {
          window.location.href =
            process.env.REACT_APP_ENV === "development"
              ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
              : process.env.REACT_APP_LANDING_PAGE_URL;
        }
      } else {
        getUserInformation()
          .then((res) => {
            if (
              (res?.phone === "" ||
                res?.phone?.length === 0 ||
                res?.phone === null) &&
              !window.location.pathname.includes("validate-phone")
            ) {
              return <Navigate to={"/validate-phone"} />;
            }
            if (
              (res?.fullName === "" ||
                res?.birthday === "" ||
                res?.gradeId === "" ||
                res?.gradeId === null ||
                res.gradeName === "") &&
              !window.location.pathname.includes("calibration")
            ) {
              navigate("/calibration");
              // return <Navigate to={"/calibration"} />;
            }
          })
          .catch((error) => {
            if (error?.response?.status === 409) {
              localStorage.setItem("clearAll", true);
              setShowError(true);
              return <DuplicateLoginErrorComponent isShowPopup={true} />;
            }
          });
      }
    }
  }
  if (page === "VALIDATE_PHONE") {
    getUserInformation()
      .then((res) => {
        if (
          (res?.phone !== null && res?.phone !== "") ||
          res?.phone?.length > 0
        ) {
          navigate("/");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          localStorage.setItem("clearAll", true);
          setShowError(true);
          return <DuplicateLoginErrorComponent isShowPopup={true} />;
        }
      });
  }
  if (page === "REQUIRE_LOGIN") {
    if (!token) {
      alert("Phai dang nhap");
      window.location.href =
        process.env.REACT_APP_ENV === "development"
          ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
          : process.env.REACT_APP_LANDING_PAGE_URL;
    }
  }
  if (page === "ADMIN") {
    const arrayURL =
      process.env.REACT_APP_ENV === "development"
        ? JSON.parse(process.env.REACT_APP_DEV_ARRAY_ADMIN_URL)
        : JSON.parse(process.env.REACT_APP_ARRAY_ADMIN_URL);
    const handleRes = (e) => {
      if (arrayURL.some((url) => e.origin.includes(url))) {
        if (
          !decodeToken(e?.data.token).roles.some((role) => roles.includes(role))
        ) {
          alert("Khong duoc vao");
          navigate("/");
        } else {
          // cookies.set("signinUser", e?.data.token, {
          //   maxAge: 2 * 3600,
          //   path: "/",
          //   // httpOnly: true,
          // });
        }
      }
    };
    window.addEventListener("message", handleRes);
  }
  // if (page === "BLOCK_ROUTER") {
  //   getUserInformation()
  //     .then((res) => {
  //       if (ConstantStrings.HIDE_GRADES.includes(res.gradeName)) {
  //         navigate("/");
  //       }
  //     })
  //     .catch((error) => {
  //       if (error?.response?.status === 409) {
  //         localStorage.setItem("clearAll", true);
  //         setShowError(true);
  //         return <DuplicateLoginErrorComponent isShowPopup={true} />;
  //       }
  //     });
  // }

  if (page === "IKMC-EXAM-QUESTION") {
    if (!localStorage.getItem("nameExam")) {
      window.location.href = "https://kurio.vn/";
    }
  } else if (page === "MONTHLY-EXAM-QUESTION") {
    // const previousRouter = location.state?.prevRouter;
    // console.log(location);
    // if (localStorage.getItem("")) {
    // //   window.location.href = "https://kurio.vn/";
    // }
  }
  return showError ? (
    <DuplicateLoginErrorComponent isShowPopup={true} />
  ) : (
    children
  );
};

export default PrivateRouter;
