export const handleSelectedColor = (index, newGrid, selectedColor, x, y) => {
  const colorConfig = {
    0: {
      "0,2": ["0,2", "1,2"],
      "1,2": ["0,2", "1,2"],
      "1,0": ["1,0", "1,1", "2,1"],
      "1,1": ["1,0", "1,1", "2,1"],
      "2,1": ["1,0", "1,1", "2,1"],
      "1,3": ["1,3", "2,2", "2,3", "3,2"],
      "1,4": ["1,4"],
      "1,5": ["1,5"],
      "2,2": ["1,3", "2,2", "2,3", "3,2"],
      "2,3": ["1,3", "2,2", "2,3", "3,2"],
      "3,2": ["1,3", "2,2", "2,3", "3,2"],
      "3,1": ["3,1", "4,0", "4,1", "5,1"],
      "4,0": ["3,1", "4,0", "4,1", "5,1"],
      "4,1": ["3,1", "4,0", "4,1", "5,1"],
      "5,1": ["3,1", "4,0", "4,1", "5,1"],
      "3,3": ["3,3", "3,4", "3,5", "4,4"],
      "3,4": ["3,3", "3,4", "3,5", "4,4"],
      "3,5": ["3,3", "3,4", "3,5", "4,4"],
      "4,4": ["3,3", "3,4", "3,5", "4,4"],
      "4,2": ["4,2", "4,3"],
      "4,3": ["4,2", "4,3"],
      "5,2": ["5,2", "5,3", "5,4"],
      "5,3": ["5,2", "5,3", "5,4"],
      "5,4": ["5,2", "5,3", "5,4"],
    },
    1: {
      "0,1": ["0,1", "1,1", "2,1"],
      "0,4": ["0,4"],
      "1,0": ["1,0", "2,0"],
      "1,1": ["0,1", "1,1", "2,1"],
      "1,2": ["1,2", "2,2"],
      "1,3": ["1,3", "1,4", "1,5", "2,3"],
      "1,4": ["1,3", "1,4", "1,5", "2,3"],
      "1,5": ["1,3", "1,4", "1,5", "2,3"],
      "2,0": ["1,0", "2,0"],
      "2,1": ["0,1", "1,1", "2,1"],
      "2,2": ["1,2", "2,2"],
      "2,3": ["1,3", "1,4", "1,5", "2,3"],
      "2,4": ["2,4", "2,5"],
      "2,5": ["2,4", "2,5"],
      "3,0": ["3,0", "3,1", "3,2", "3,3", "3,4", "4,4"],
      "3,1": ["3,0", "3,1", "3,2", "3,3", "3,4", "4,4"],
      "3,2": ["3,0", "3,1", "3,2", "3,3", "3,4", "4,4"],
      "3,3": ["3,0", "3,1", "3,2", "3,3", "3,4", "4,4"],
      "3,4": ["3,0", "3,1", "3,2", "3,3", "3,4", "4,4"],
      "4,0": ["4,0", "4,1"],
      "4,1": ["4,0", "4,1"],
      "4,2": ["4,2", "4,3"],
      "4,3": ["4,2", "4,3"],
      "4,4": ["3,0", "3,1", "3,2", "3,3", "3,4", "4,4"],
      "5,1": ["5,1", "5,2", "5,3", "5,4"],
      "5,2": ["5,1", "5,2", "5,3", "5,4"],
      "5,3": ["5,1", "5,2", "5,3", "5,4"],
      "5,4": ["5,1", "5,2", "5,3", "5,4"],
    },
    2: {
      "0,1": ["0,1", "0,2"],
      "0,2": ["0,1", "0,2"],
      "0,3": ["0,3"],
      "0,5": ["0,5"],
      "1,1": ["1,1"],
      "1,2": ["1,2", "1,3", "2,3", "3,3", "4,3", "5,3", "6,3"],
      "1,3": ["1,2", "1,3", "2,3", "3,3", "4,3", "5,3", "6,3"],
      "1,5": ["1,5"],
      "2,1": ["2,1", "2,2"],
      "2,2": ["2,1", "2,2"],
      "2,3": ["1,2", "1,3", "2,3", "3,3", "4,3", "5,3", "6,3"],
      "2,4": ["2,4", "2,5", "2,6"],
      "2,5": ["2,4", "2,5", "2,6"],
      "2,6": ["2,4", "2,5", "2,6"],
      "3,0": ["3,0", "3,1", "3,2", "4,0"],
      "3,1": ["3,0", "3,1", "3,2", "4,0"],
      "3,2": ["3,0", "3,1", "3,2", "4,0"],
      "3,3": ["1,2", "1,3", "2,3", "3,3", "4,3", "5,3", "6,3"],
      "3,4": ["3,4"],
      "3,5": ["3,5", "4,5", "5,5", "5,4"],
      "4,0": ["3,0", "3,1", "3,2", "4,0"],
      "4,1": ["4,1", "4,2", "5,0", "5,1", "6,1"],
      "4,2": ["4,1", "4,2", "5,0", "5,1", "6,1"],
      "4,3": ["1,2", "1,3", "2,3", "3,3", "4,3", "5,3", "6,3"],
      "4,4": ["4,4"],
      "4,5": ["3,5", "4,5", "5,5", "5,4"],
      "4,6": ["4,6"],
      "5,0": ["4,1", "4,2", "5,0", "5,1", "6,1"],
      "5,1": ["4,1", "4,2", "5,0", "5,1", "6,1"],
      "5,3": ["1,2", "1,3", "2,3", "3,3", "4,3", "5,3", "6,3"],
      "5,4": ["3,5", "4,5", "5,5", "5,4"],
      "5,5": ["3,5", "4,5", "5,5", "5,4"],
      "6,1": ["4,1", "4,2", "5,0", "5,1", "6,1"],
      "6,3": ["1,2", "1,3", "2,3", "3,3", "4,3", "5,3", "6,3"],
    },
    3: {
      "0,1": ["0,1"],
      "1,0": ["1,0", "1,1", "1,2"],
      "1,1": ["1,0", "1,1", "1,2"],
      "1,2": ["1,0", "1,1", "1,2"],
      "1,3": ["1,3", "2,3", "2,2"],
      "1,4": ["1,4"],
      "1,5": ["1,5"],
      "2,1": ["2,1"],
      "2,2": ["1,3", "2,3", "2,2"],
      "2,3": ["1,3", "2,3", "2,2"],
      "3,1": ["3,1", "3,2", "4,2", "5,2", "5,1"],
      "3,2": ["3,1", "3,2", "4,2", "5,2", "5,1"],
      "3,3": ["3,3", "4,3"],
      "3,5": ["3,5", "4,5"],
      "4,2": ["3,1", "3,2", "4,2", "5,2", "5,1"],
      "4,3": ["3,3", "4,3"],
      "4,4": ["4,4", "5,4"],
      "4,5": ["3,5", "4,5"],
      "5,1": ["3,1", "3,2", "4,2", "5,2", "5,1"],
      "5,2": ["3,1", "3,2", "4,2", "5,2", "5,1"],
      "5,3": ["5,3"],
      "5,4": ["4,4", "5,4"],
    },
    4: {
      "0,2": ["0,2", "1,0", "1,1", "1,2"],
      "0,4": ["0,4", "1,3", "1,4"],
      "0,6": ["0,6"],
      "1,0": ["0,2", "1,0", "1,1", "1,2"],
      "1,1": ["0,2", "1,0", "1,1", "1,2"],
      "1,2": ["0,2", "1,0", "1,1", "1,2"],
      "1,3": ["0,4", "1,3", "1,4"],
      "1,4": ["0,4", "1,3", "1,4"],
      "1,5": ["1,5", "1,6", "1,7", "2,5", "2,7"],
      "1,6": ["1,5", "1,6", "1,7", "2,5", "2,7"],
      "1,7": ["1,5", "1,6", "1,7", "2,5", "2,7"],
      "2,2": ["2,2", "2,3", "2,4", "3,1", "3,2", "3,4", "4,4"],
      "2,3": ["2,2", "2,3", "2,4", "3,1", "3,2", "3,4", "4,4"],
      "2,4": ["2,2", "2,3", "2,4", "3,1", "3,2", "3,4", "4,4"],
      "2,5": ["1,5", "1,6", "1,7", "2,5", "2,7"],
      "2,6": ["2,6", "3,6", "3,5", "4,5", "5,5", "5,4", "5,3", "4,3", "3,3"],
      "2,7": ["1,5", "1,6", "1,7", "2,5", "2,7"],
      "3,0": ["3,0"],
      "3,1": ["2,2", "2,3", "2,4", "3,1", "3,2", "3,4", "4,4"],
      "3,2": ["2,2", "2,3", "2,4", "3,1", "3,2", "3,4", "4,4"],
      "3,3": ["2,6", "3,6", "3,5", "4,5", "5,5", "5,4", "5,3", "4,3", "3,3"],
      "3,4": ["2,2", "2,3", "2,4", "3,1", "3,2", "3,4", "4,4"],
      "3,5": ["2,6", "3,6", "3,5", "4,5", "5,5", "5,4", "5,3", "4,3", "3,3"],
      "3,6": ["2,6", "3,6", "3,5", "4,5", "5,5", "5,4", "5,3", "4,3", "3,3"],
      "3,7": ["3,7"],
      "4,0": ["4,0", "4,1", "4,2"],
      "4,1": ["4,0", "4,1", "4,2"],
      "4,2": ["4,0", "4,1", "4,2"],
      "4,3": ["2,6", "3,6", "3,5", "4,5", "5,5", "5,4", "5,3", "4,3", "3,3"],
      "4,4": ["2,2", "2,3", "2,4", "3,1", "3,2", "3,4", "4,4"],
      "4,5": ["2,6", "3,6", "3,5", "4,5", "5,5", "5,4", "5,3", "4,3", "3,3"],
      "5,0": ["5,0"],
      "5,1": ["5,1", "5,2", "6,2", "7,2", "7,1"],
      "5,2": ["5,1", "5,2", "6,2", "7,2", "7,1"],
      "5,3": ["2,6", "3,6", "3,5", "4,5", "5,5", "5,4", "5,3", "4,3", "3,3"],
      "5,4": ["2,6", "3,6", "3,5", "4,5", "5,5", "5,4", "5,3", "4,3", "3,3"],
      "5,5": ["2,6", "3,6", "3,5", "4,5", "5,5", "5,4", "5,3", "4,3", "3,3"],
      "5,6": ["5,6", "5,7"],
      "5,7": ["5,6", "5,7"],
      "6,2": ["5,1", "5,2", "6,2", "7,2", "7,1"],
      "6,3": ["6,3"],
      "6,4": ["6,4"],
      "6,7": ["6,7"],
      "7,1": ["5,1", "5,2", "6,2", "7,2", "7,1"],
      "7,2": ["5,1", "5,2", "6,2", "7,2", "7,1"],
    },
    5: {
      "0,1": ["0,1", "1,1", "2,1"],
      "0,4": ["0,4", "0,5", "1,5", "2,5"],
      "0,5": ["0,4", "0,5", "1,5", "2,5"],
      "1,1": ["0,1", "1,1", "2,1"],
      "1,2": ["1,2"],
      "1,4": ["1,4", "2,4"],
      "1,5": ["0,4", "0,5", "1,5", "2,5"],
      "1,7": ["1,7", "2,7", "3,7", "4,7"],
      "2,1": ["0,1", "1,1", "2,1"],
      "2,2": ["2,2", "2,3", "3,3", "4,3", "5,3", "5,2"],
      "2,3": ["2,2", "2,3", "3,3", "4,3", "5,3", "5,2"],
      "2,4": ["1,4", "2,4"],
      "2,5": ["0,4", "0,5", "1,5", "2,5"],
      "2,7": ["1,7", "2,7", "3,7", "4,7"],
      "3,0": ["3,0", "3,1", "3,2"],
      "3,1": ["3,0", "3,1", "3,2"],
      "3,2": ["3,0", "3,1", "3,2"],
      "3,3": ["2,2", "2,3", "3,3", "4,3", "5,3", "5,2"],
      "3,4": ["3,4", "3,5", "3,6"],
      "3,5": ["3,4", "3,5", "3,6"],
      "3,6": ["3,4", "3,5", "3,6"],
      "3,7": ["1,7", "2,7", "3,7", "4,7"],
      "4,0": ["4,0"],
      "4,1": ["4,1", "4,2"],
      "4,2": ["4,1", "4,2"],
      "4,3": ["2,2", "2,3", "3,3", "4,3", "5,3", "5,2"],
      "4,4": ["4,4"],
      "4,6": ["4,6"],
      "4,7": ["1,7", "2,7", "3,7", "4,7"],
      "5,0": ["5,0", "5,1", "6,1", "6,2"],
      "5,1": ["5,0", "5,1", "6,1", "6,2"],
      "5,2": ["2,2", "2,3", "3,3", "4,3", "5,3", "5,2"],
      "5,3": ["2,2", "2,3", "3,3", "4,3", "5,3", "5,2"],
      "5,4": ["5,4", "5,5", "5,6", "5,7", "6,4"],
      "5,5": ["5,4", "5,5", "5,6", "5,7", "6,4"],
      "5,6": ["5,4", "5,5", "5,6", "5,7", "6,4"],
      "5,7": ["5,4", "5,5", "5,6", "5,7", "6,4"],
      "6,1": ["5,0", "5,1", "6,1", "6,2"],
      "6,2": ["5,0", "5,1", "6,1", "6,2"],
      "6,3": ["6,3"],
      "6,4": ["5,4", "5,5", "5,6", "5,7", "6,4"],
      "6,6": ["6,6", "6,7"],
      "6,7": ["6,6", "6,7"],
      "7,1": ["7,1"],
    },
  };

  const coordinates = `${x},${y}`;
  if (colorConfig[index] && colorConfig[index][coordinates]) {
    colorConfig[index][coordinates].forEach((cell) => {
      const [cellX, cellY] = cell.split(",").map(Number);
      newGrid[cellX][cellY] = selectedColor;
    });
  }
};
