import React, { useState } from "react";
import AnswerItem from "./AnswerItem";

const LandingPageQA = ({ isMobile = false, isTablet = false }) => {
  const ListCategories = [
    { title: " Thông tin thanh toán" },
    { title: " Chương trình học ở kurio" },
    { title: " Luyện thi ikmc với kurio" },
    { title: " Quy định về việc sử dụng tài khoản" },
  ];
  const [categorySelected, setCategorySelected] = useState(isMobile ? -1 : 0);
  const [itemSelected, setItemSelected] = useState({ prev: -1, current: -1 });
  const handleShowAnswer = (prev, current) => {
    if (itemSelected.current === current) {
      setItemSelected({ prev: current, current: -1 });
    } else {
      setItemSelected({ prev: prev, current: current });
    }
  };
  const handleHideAnswer = () => {
    setItemSelected({ prev: -1, current: -1 });
    setCategorySelected(-1);
  };
  return (
    <div
      className={`${
        isMobile ? "is-flex-wrap-wrap" : ""
      } is-flex is-flex-direction-column is-align-items-center is-justify-content-center is-flex-gap-6`}
      style={{
        backgroundImage: "url('/images/landing-page/background-qa.png",
        height: "42.5rem",
        width: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        padding:isTablet?"3rem":""
      }}
    >
      <div
        className={`${
          isMobile ? "is-flex-gap-4" : "is-flex-gap-5"
        } is-flex is-align-items-center qa-title`}
        style={{width:isMobile?"20rem":"32.4375rem"}}
      >
        <p
          style={{
            color: "#FFFFFF",
            fontSize: isMobile ? "3rem" : "5.4rem",
            fontStyle: "normal",
            lineHeight: "normal",
            fontWeight: 800,
          }}
        >
          Q&A
        </p>
        <div
          style={{
            width: "0rem",
            height: isMobile ? "3rem" : "4rem",
            borderLeft: "0.125rem solid #FFFFFF",
          }}
        ></div>
        <p
          className="has-text-medium"
          style={{
            fontSize: isMobile ? "1rem" : "1.5rem",
            lineHeight: "normal",
            fontStyle: "normal",
            color: "#FFFFFF",
          }}
        >
          Cùng <b>KURIO</b> giải đáp mọi thắc mắc!
        </p>
      </div>
      <div
        className={`${
          isMobile ? "is-flex-wrap-wrap" : ""
        } is-flex is-align-items-flex-start is-justify-content-center`}
        style={{ gap: "1.25rem" }}
      >
        <div className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-flex-start is-justify-content-center">
          {ListCategories.map((item, index) => {
            return (
              <div
                className="is-flex is-align-content-center is-justify-content-center is-align-items-center"
                key={index}
              >
                <div
                  className={`${
                    categorySelected === index
                      ? "has-text-kurio-main-color-purple"
                      : "has-text-kurio-main-color-white"
                  } is-clickable is-uppercase is-flex is-justify-content-center is-align-items-center category-qa`}
                  onClick={() => {
                    setCategorySelected(index);
                    handleShowAnswer(-1, -1);
                  }}
                  style={{
                    fontSize: "1.25rem",
                    lineHeight: "110%",
                    fontWeight: 800,
                    fontStyle: "normal",
                    width: isMobile ? "20rem" :isTablet?"15rem": "25rem",
                    height: "6rem",
                    padding:isTablet?"0.625rem 1rem": "0.625rem 3rem",
                    border: "0.0625rem solid #FAFBFC",
                    background:
                      categorySelected === index
                        ? "linear-gradient(180deg, #FAFBFC 0%, #CBA7EF 100%)"
                        : "linear-gradient(180deg, rgba(250, 251, 252, 0.20) 0%, rgba(250, 251, 252, 0.00) 100%)",
                    borderRadius: "0.5rem",
                  }}
                >
                  {index + 1}.{item.title}
                </div>
                {categorySelected === index && !isMobile && (
                  <img src="/images/landing-page/ic-arrow-qa.svg" alt="" />
                )}
              </div>
            );
          })}
        </div>
        {!isMobile && (
          <div
            className="has-background-kurio-main-color-white is-flex is-align-items-flex-start qa"
            style={{
              borderRadius: "1rem",
              padding: "2rem 0.5rem 2rem 2rem",
              width:isTablet?"27rem": "40rem",
            }}
          >
            <div
              className=" is-flex is-align-items-flex-start qa has-text-kurio-main-color-black"
              style={{
                maxHeight: "23rem",
                height: "23rem",
                overflowY: "auto",
                overflowX: "hidden",
                width: "100%",
                fontSize: "1rem",
                lineHeight: "150%",
              }}
            >
              <div
                className=" is-flex is-flex-direction-column is-flex-gap-5 is-align-items-flex-start qa has-text-kurio-main-color-black"
                style={{
                  width: "35.75rem",
                }}
              >
                {categorySelected === 0 && (
                  <>
                    <AnswerItem
                      handleShowAnswer={handleShowAnswer}
                      itemSelected={itemSelected}
                      index={1}
                      isCurrent={itemSelected.current === 1}
                      isPrev={itemSelected.prev === 1}
                      title={"1.1. Kurio có hỗ trợ phương thức thanh toán nào?"}
                      isTablet={isTablet}
                    >
                      <div className="is-flex is-flex-direction-column is-flex-gap-4">
                        <p>
                          Hình thức mua hàng và thanh toán tại website Kurio.vn
                          được thực hiện bằng một trong các hình thức như sau:
                        </p>
                        <p>Hình thức chuyển khoản:</p>
                        <ul>
                          <li className="qa-list-item">
                            Khi khách hàng đăng ký tài khoản và thực hiện thanh
                            toán mua khóa học trên website Kurio.vn thành công.
                            Đội ngũ chăm sóc khách hàng của Kurio sẽ kích hoạt
                            tài khoản của khách hàng và liên hệ trực tiếp cho
                            khách hàng.
                          </li>
                          <li className="qa-list-item">
                            Hoặc sau khi chuyển khoản thành công khách hàng có
                            thể chủ động liên hệ qua Hotline/Zalo 0971500120 của
                            Kurio để thông báo về việc chuyển tiền mua khóa học
                            Kurio sẽ hỗ trợ kích hoạt khóa học.
                          </li>
                        </ul>
                        <p>Hình thức thanh toán qua cổng thanh toán VNPAY:</p>
                        <ul>
                          <li className="qa-list-item">
                            Khi khách hàng đăng ký tài khoản và chọn hình thức
                            thanh toán mua khóa học qua cổng thanh toán VNPAY,
                            sau khi thanh toán xong thì tài khoản sẽ được kích
                            hoạt luôn
                          </li>
                          <li className="qa-list-item">
                            Khách hàng lưu ý đăng nhập đúng tài khoản đã đăng ký
                            để có thể đăng nhập sử dụng được khóa học.
                          </li>
                        </ul>
                        <p>
                          Ngoài ra, nếu mua hàng trên ứng dụng Toán Kurio, khách
                          hàng có thể thanh toán qua cách đăng ký trên Cửa hàng
                          ứng dụng của Google hoặc Apple.
                        </p>
                      </div>
                    </AnswerItem>
                    <AnswerItem
                      handleShowAnswer={handleShowAnswer}
                      itemSelected={itemSelected}
                      index={2}
                      isCurrent={itemSelected.current === 2}
                      isPrev={itemSelected.prev === 2}
                      title={
                        "1.2. Có những gói học phí nào và thời hạn sử dụng là bao lâu?"
                      }
                      isTablet={isTablet}
                    >
                      <p>
                        Kurio cung cấp các gói học phí linh hoạt theo 6 tháng,
                        và 1 năm. Thời hạn sử dụng được ghi rõ khi mua gói và sẽ
                        tính từ ngày thanh toán thành công.
                      </p>
                    </AnswerItem>
                    <AnswerItem
                      handleShowAnswer={handleShowAnswer}
                      itemSelected={itemSelected}
                      index={3}
                      isCurrent={itemSelected.current === 3}
                      isPrev={itemSelected.prev === 3}
                      title={"1.3. Chính sách hoàn tiền của Kurio như thế nào?"}
                      isTablet={isTablet}
                    >
                      <div className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-flex-start">
                        <p>
                          Quý khách có thể được hoàn tiền sản phẩm dịch vụ khi
                          sử dụng dịch vụ của website <b>Kurio.vn</b> như sau:
                        </p>
                        <p className="ml-2">
                          - Học sinh được hoàn trả học phí 100% nếu đáp ứng đủ
                          điều kiện theo quy định.
                        </p>
                        <p>
                          <b>Quy trình hoàn tiền:</b> được thông báo trên
                          website
                          <b> Kurio.vn</b> hoặc thông báo trực tiếp cho Khách
                          hàng tại thời điểm Khách hàng đăng ký thành công sản
                          phẩm, dịch vụ.
                        </p>
                        <p>
                          Mọi thắc mắc, khiếu nại về vấn đề chính sách hoàn tiền
                          vui lòng liên hệ số hotline <b>0971500120</b> để được
                          hỗ trợ tốt nhất
                        </p>
                        <b>Điều kiện:</b>
                        <ul>
                          <li className="qa-list-item">
                            Phụ huynh đã thanh toán 100% học phí của gói học.
                          </li>
                          <li className="qa-list-item">
                            Học sinh chưa sử dụng tài khoản.
                          </li>
                        </ul>
                        <b>Thủ tục yêu cầu hoàn trả:</b>
                        <ul>
                          <li className="qa-list-item">
                            <b>Bước 1: </b>Liên hệ số Hotline <b>0971500120</b>{" "}
                            cung cấp thông tin số điện thoại đăng ký tài khoản
                            của học sinh và thông tin gói học.
                          </li>
                          <li className="qa-list-item">
                            <b>Bước 2: </b>
                            <b>Kurio.vn</b> xem xét đề nghị hoàn học phí và điều
                            kiện
                          </li>
                          <li className="qa-list-item">
                            <b>Bước 3: </b>
                            <b>Kurio.vn</b> thông báo quyết định hoàn học phí và
                            xin thông tin ngân hàng để hoàn lại học phí cho học
                            sinh
                          </li>
                          <li className="qa-list-item">
                            <b>Bước 4: </b>
                            <b>Kurio.vn</b> tiến hành hoàn phí. Thời gian hoàn
                            tất hoàn phí dự kiến được thực hiện trong 15 ngày
                            làm việc kể từ ngày xác nhận đủ điều kiện hoàn phí.
                          </li>
                        </ul>
                        <p>
                          <b className="is-italic is-underlined">Lưu ý : </b> Để
                          thông tin hoàn học phí được xác thực Quý phụ huynh vui
                          lòng cung cấp chứng từ chuyển khoản để Kurio tiến hành
                          kiểm tra thông tin và hoàn phí đúng quy định.
                        </p>
                      </div>
                    </AnswerItem>
                  </>
                )}
                {categorySelected === 1 && (
                  <>
                    <AnswerItem
                      itemSelected={itemSelected}
                      handleShowAnswer={handleShowAnswer}
                      index={1}
                      isCurrent={itemSelected.current === 1}
                      isPrev={itemSelected.prev === 1}
                      title={
                        "2.1. Chương trình học của Kurio dựa trên tài liệu nào?"
                      }
                      isTablet={isTablet}
                    >
                      <p>
                        <b>Kurio</b> xây dựng chương trình học dựa trên khung
                        chương trình của <b>Bộ Giáo Dục Việt Nam</b>, tổng hợp
                        kiến thức từ các sách giáo khoa và kết hợp với Toán tư
                        duy chuẩn quốc tế từ kỳ thi
                        <b> IKMC</b>, giúp học sinh nắm vững nền tảng và phát
                        triển tư duy toán học toàn diện
                      </p>
                    </AnswerItem>
                    <AnswerItem
                      itemSelected={itemSelected}
                      handleShowAnswer={handleShowAnswer}
                      index={2}
                      isCurrent={itemSelected.current === 2}
                      isPrev={itemSelected.prev === 2}
                      title={"2.2. Hệ thống đánh giá thông minh Elorio là gì?"}
                      isTablet={isTablet}
                    >
                      <p>
                        - Elorio là hệ thống đánh giá thông minh của{" "}
                        <b>Kurio</b>, giúp xác định trình độ của học sinh và gợi
                        ý lộ trình học tập cá nhân hóa.
                      </p>
                      <p>
                        - Dựa trên bài kiểm tra đầu vào và quá trình học, Elorio
                        sẽ điều chỉnh độ khó và nội dung bài tập, đảm bảo học
                        sinh được học phù hợp với khả năng và tiến bộ đều đặn.
                      </p>
                    </AnswerItem>
                    <AnswerItem
                      itemSelected={itemSelected}
                      handleShowAnswer={handleShowAnswer}
                      index={3}
                      isCurrent={itemSelected.current === 3}
                      isPrev={itemSelected.prev === 3}
                      title={
                        "2.3. Thời gian lý tưởng để luyện tập với Kurio mỗi ngày là bao lâu?"
                      }
                      isTablet={isTablet}
                    >
                      <p>
                        Để đạt hiệu quả tốt nhất, học sinh nên luyện tập Toán tư
                        duy với <b>Kurio</b> từ 20-30 phút mỗi ngày. Khoảng thời
                        gian này đủ để con ôn luyện kiến thức và giữ hứng thú
                        học tập mà không bị quá tải.
                      </p>
                    </AnswerItem>
                  </>
                )}
                {categorySelected === 2 && (
                  <>
                    <AnswerItem
                      itemSelected={itemSelected}
                      handleShowAnswer={handleShowAnswer}
                      index={1}
                      isCurrent={itemSelected.current === 1}
                      isPrev={itemSelected.prev === 1}
                      title={
                        "3.1. Luyện thi IKMC trên Kurio khác gì so với các lớp luyện thi khác?"
                      }
                      isTablet={isTablet}
                    >
                      <p>
                        - Là đối tác học thuật chính thức của kỳ thi <b>IKMC</b>
                        , <b>Kurio</b> cung cấp lộ trình ôn thi chuẩn xác với
                        kho đề thi chính thức từ các năm trước, phòng thi ảo có
                        đồng hồ đếm ngược mô phỏng y như thi thật và lời giải
                        chi tiết.
                      </p>
                      <p>
                        - Lộ trình cá nhân hóa của <b>Kurio</b> đảm bảo học sinh
                        ôn đúng phần cần thiết, hiệu quả cao hơn so với các lớp
                        học thêm thông thường.
                      </p>
                    </AnswerItem>
                    <AnswerItem
                      itemSelected={itemSelected}
                      handleShowAnswer={handleShowAnswer}
                      index={2}
                      isCurrent={itemSelected.current === 2}
                      isPrev={itemSelected.prev === 2}
                      title={"3.2. Kurio có cung cấp đề thi thử IKMC không?"}
                      isTablet={isTablet}
                    >
                      <p>
                        <b>Có, Kurio</b> có kho đề thi chính thức của{" "}
                        <b>IKMC</b> từ nhiều năm, và tính năng Thi tháng theo
                        dạng đề <b>IKMC</b>, giúp học sinh làm quen với các dạng
                        bài và <b>rèn luyện kỹ năng</b> giải đề, giúp con{" "}
                        <b>tự tin</b> và làm quen với áp lực thi thật.
                      </p>
                    </AnswerItem>
                    <AnswerItem
                      itemSelected={itemSelected}
                      handleShowAnswer={handleShowAnswer}
                      index={3}
                      isCurrent={itemSelected.current === 3}
                      isPrev={itemSelected.prev === 3}
                      title={
                        "3.3. Lộ trình luyện thi IKMC trên Kurio có khác gì so với luyện thi thông thường không?"
                      }
                      isTablet={isTablet}
                    >
                      <p>
                        <b>Có. Kurio</b> cung cấp một lộ trình ôn thi{" "}
                        <b>cá nhân hóa</b> dựa trên khả năng hiện tại của mỗi
                        học sinh, giúp con học đúng phần cần bổ sung mà không bị
                        quá tải. Lộ trình này gồm ba bước cụ thể để đảm bảo học
                        sinh đạt hiệu quả tối ưu.
                      </p>
                    </AnswerItem>
                    <AnswerItem
                      itemSelected={itemSelected}
                      handleShowAnswer={handleShowAnswer}
                      index={4}
                      isCurrent={itemSelected.current === 4}
                      isPrev={itemSelected.prev === 4}
                      title={
                        "3.4. Ba bước trong lộ trình luyện thi IKMC của Kurio là gì?"
                      }
                      isTablet={isTablet}
                    >
                      <p>Lộ trình luyện thi IKMC tại Kurio gồm 3 bước:</p>
                      <div className="is-flex is-flex-direction-column is-flex-gap-3">
                        <p className="ml-3">
                          <b>1. Đánh giá trình độ ban đầu</b> – Học sinh sẽ làm
                          bài kiểm tra đầu vào để đánh giá chính xác khả năng và
                          các phần kiến thức còn yếu.
                        </p>
                        <p className="ml-3">
                          <b>2. Xây dựng lộ trình cá nhân hóa</b> – Dựa trên kết
                          quả đầu vào, Kurio sẽ thiết kế lộ trình học tập phù
                          hợp với nhu cầu và mục tiêu của từng học sinh.
                        </p>
                        <p className="ml-3">
                          <b>3. Kiểm tra đầu ra</b> – Sau khi hoàn thành lộ
                          trình, Kurio sẽ tổ chức một bài kiểm tra tổng hợp để
                          đánh giá tiến bộ của học sinh, giúp con tự tin trước
                          kỳ thi chính thức.
                        </p>
                      </div>
                    </AnswerItem>
                    <AnswerItem
                      itemSelected={itemSelected}
                      handleShowAnswer={handleShowAnswer}
                      index={5}
                      isCurrent={itemSelected.current === 5}
                      isPrev={itemSelected.prev === 5}
                      title={
                        "3.5. Kurio có chương trình học nào hỗ trợ ngoài việc luyện thi IKMC không?"
                      }
                      isTablet={isTablet}
                    >
                      <p>
                        Ngoài luyện thi <b>IKMC</b>, Kurio còn cung cấp chương
                        trình học Toán tư duy theo chuẩn quốc tế và khung chương
                        trình Bộ Giáo Dục Việt Nam, giúp học sinh không chỉ học
                        để thi mà còn phát triển tư duy logic, khả năng phân
                        tích và sáng tạo.
                      </p>
                    </AnswerItem>
                  </>
                )}
                {categorySelected === 3 && (
                  <>
                    <AnswerItem
                      itemSelected={itemSelected}
                      handleShowAnswer={handleShowAnswer}
                      index={1}
                      isCurrent={itemSelected.current === 1}
                      isPrev={itemSelected.prev === 1}
                      title={
                        "4.1. Một tài khoản có đăng nhập được nhiều thiết bị không?"
                      }
                      isTablet={isTablet}
                    >
                      <p>
                        Tài khoản Kurio có thể đăng nhập trên máy tính, iPad và
                        điện thoại nhưng người dùng chỉ được đăng nhập một lúc
                        trên một thiết bị.
                      </p>
                    </AnswerItem>
                    <AnswerItem
                      itemSelected={itemSelected}
                      handleShowAnswer={handleShowAnswer}
                      index={2}
                      isCurrent={itemSelected.current === 2}
                      isPrev={itemSelected.prev === 2}
                      title={
                        "4.2. Tại sao mỗi tài khoản chỉ nên được sử dụng bởi một người?"
                      }
                      isTablet={isTablet}
                    >
                      <p>
                        Vì Kurio cung cấp lộ trình học tập cá nhân hóa dựa trên
                        trình độ và tiến độ của từng học sinh, nên mỗi tài khoản
                        chỉ nên được sử dụng bởi một người để đảm bảo đánh giá
                        chính xác và đạt hiệu quả học tập tối đa. Việc sử dụng
                        nhiều người cho một tài khoản có thể ảnh hưởng đến kết
                        quả đánh giá và không đảm bảo lộ trình học tập phù hợp.
                      </p>
                    </AnswerItem>
                    <AnswerItem
                      itemSelected={itemSelected}
                      handleShowAnswer={handleShowAnswer}
                      index={3}
                      isCurrent={itemSelected.current === 3}
                      isPrev={itemSelected.prev === 3}
                      title={
                        "4.3. Có thể chuyển nhượng tài khoản cho người khác không?"
                      }
                      isTablet={isTablet}
                    >
                      <p>
                        <b>Không</b>. Tài khoản Kurio được cá nhân hóa theo
                        người học từ khi đăng ký, nên việc chuyển nhượng tài
                        khoản cho người khác sẽ ảnh hưởng đến lộ trình học tập
                        và kết quả đánh giá.
                      </p>
                    </AnswerItem>
                    <AnswerItem
                      itemSelected={itemSelected}
                      handleShowAnswer={handleShowAnswer}
                      index={4}
                      isCurrent={itemSelected.current === 4}
                      isPrev={itemSelected.prev === 4}
                      title={
                        "4.4. Nếu có vấn đề khi sử dụng tài khoản, tôi có thể liên hệ với ai?"
                      }
                      isTablet={isTablet}
                    >
                      <p>
                        Đội ngũ hỗ trợ của Kurio luôn sẵn sàng trợ giúp. Phụ
                        huynh và học sinh có thể liên hệ qua Zalo/ hotline
                        <b> 0971 500 120 </b>
                        để được giải đáp nhanh chóng.
                      </p>
                    </AnswerItem>
                  </>
                )}
              </div>
              <div style={{ width: "0.25rem" }}></div>
            </div>
          </div>
        )}
        {isMobile && categorySelected !== -1 && (
          <div
            className={`${
              categorySelected !== -1
                ? "modal is-active is-justify-content-flex-end"
                : "is-hidden"
            }`}
            style={{
              width: "100%",
              height: "100%",
              zIndex: 9999,
            }}
          >
            <div
              className="modal-background"
              onClick={handleHideAnswer}
              style={{ opacity: 0.3 }}
            ></div>
            <div
              className={`has-background-kurio-main-color-white qa`}
              style={{
                borderRadius: "1rem 1rem 0 0",
                padding: "2rem 0.5rem 2rem 1rem",
                width: "100%",
                height: "75.83%",
                top: "24.17%",
                zIndex: 9999,
                opacity: 1,
              }}
            >
              <div
                className=" is-flex is-flex-direction-column is-flex-gap-5 qa has-text-kurio-main-color-black"
                style={{
                  maxHeight: "100%",
                  height: "100%",
                  overflowY: "auto",
                  width: "100%",
                  fontSize: "1rem",
                  lineHeight: "150%",
                }}
              >
                <div
                  className=" is-flex is-flex-direction-column is-flex-gap-5 qa has-text-kurio-main-color-black"
                  style={{
                    width: "calc(100% - 0.75rem)",
                  }}
                >
                  <div
                    className={`${
                      isMobile ? "is-flex-gap-4" : "is-flex-gap-5"
                    } is-flex is-align-items-center is-justify-content-center has-text-kurio-main-color-purple `}
                    style={{
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: isMobile ? "3rem" : "5.4rem",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        fontWeight: 800,
                      }}
                    >
                      Q&A
                    </p>
                    <div
                      style={{
                        width: "0rem",
                        height: isMobile ? "3rem" : "4rem",
                        borderLeft: "0.125rem solid #572CD1",
                      }}
                    ></div>
                    <p
                      className="has-text-medium"
                      style={{
                        fontSize: isMobile ? "1rem" : "1.5rem",
                        lineHeight: "normal",
                        fontStyle: "normal",
                      }}
                    >
                      Cùng <b>KURIO</b> giải đáp<br></br> mọi thắc mắc!
                    </p>
                  </div>
                  <div
                    className="is-flex is-align-items-center is-justify-content-center has-text-kurio-main-color-purple has-text-weight-bold py-4 qa-category-title-mobile"
                    style={{
                      background: "#E1D7FF",
                      borderRadius: "0.5rem",
                      width: "100%",
                      fontSize: "1rem",
                      lineHeight:"120%",
                    }}
                  >
                    {categorySelected + 1}.
                    {ListCategories[categorySelected].title}
                  </div>
                  {categorySelected === 0 && (
                    <>
                      <AnswerItem
                        handleShowAnswer={handleShowAnswer}
                        itemSelected={itemSelected}
                        index={1}
                        isCurrent={itemSelected.current === 1}
                        isPrev={itemSelected.prev === 1}
                        isMobile={isMobile}
                        title={
                          "1.1. Kurio có hỗ trợ phương thức thanh toán nào?"
                        }
                      >
                        <div className="is-flex is-flex-direction-column is-flex-gap-4">
                          <p>
                            Hình thức mua hàng và thanh toán tại website
                            Kurio.vn được thực hiện bằng một trong các hình thức
                            như sau:
                          </p>
                          <p>Hình thức chuyển khoản:</p>
                          <ul>
                            <li className="qa-list-item">
                              Khi khách hàng đăng ký tài khoản và thực hiện
                              thanh toán mua khóa học trên website Kurio.vn
                              thành công. Đội ngũ chăm sóc khách hàng của Kurio
                              sẽ kích hoạt tài khoản của khách hàng và liên hệ
                              trực tiếp cho khách hàng.
                            </li>
                            <li className="qa-list-item">
                              Hoặc sau khi chuyển khoản thành công khách hàng có
                              thể chủ động liên hệ qua Hotline/Zalo 0971500120
                              của Kurio để thông báo về việc chuyển tiền mua
                              khóa học Kurio sẽ hỗ trợ kích hoạt khóa học.
                            </li>
                          </ul>
                          <p>Hình thức thanh toán qua cổng thanh toán VNPAY:</p>
                          <ul>
                            <li className="qa-list-item">
                              Khi khách hàng đăng ký tài khoản và chọn hình thức
                              thanh toán mua khóa học qua cổng thanh toán VNPAY,
                              sau khi thanh toán xong thì tài khoản sẽ được kích
                              hoạt luôn
                            </li>
                            <li className="qa-list-item">
                              Khách hàng lưu ý đăng nhập đúng tài khoản đã đăng
                              ký để có thể đăng nhập sử dụng được khóa học.
                            </li>
                          </ul>
                          <p>
                            Ngoài ra, nếu mua hàng trên ứng dụng Toán Kurio,
                            khách hàng có thể thanh toán qua cách đăng ký trên
                            Cửa hàng ứng dụng của Google hoặc Apple.
                          </p>
                        </div>
                      </AnswerItem>
                      <AnswerItem
                        handleShowAnswer={handleShowAnswer}
                        itemSelected={itemSelected}
                        index={2}
                        isCurrent={itemSelected.current === 2}
                        isPrev={itemSelected.prev === 2}
                        title={
                          "1.2. Có những gói học phí nào và thời hạn sử dụng là bao lâu?"
                        }
                        isMobile={isMobile}
                      >
                        <p>
                          Kurio cung cấp các gói học phí linh hoạt theo 6 tháng,
                          và 1 năm. Thời hạn sử dụng được ghi rõ khi mua gói và
                          sẽ tính từ ngày thanh toán thành công.
                        </p>
                      </AnswerItem>
                      <AnswerItem
                        handleShowAnswer={handleShowAnswer}
                        itemSelected={itemSelected}
                        index={3}
                        isCurrent={itemSelected.current === 3}
                        isPrev={itemSelected.prev === 3}
                        title={
                          "1.3. Chính sách hoàn tiền của Kurio như thế nào?"
                        }
                        isMobile={isMobile}
                      >
                        <div className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-flex-start">
                          <p>
                            Quý khách có thể được hoàn tiền sản phẩm dịch vụ khi
                            sử dụng dịch vụ của website <b>Kurio.vn</b> như sau:
                          </p>
                          <p className="ml-2">
                            - Học sinh được hoàn trả học phí 100% nếu đáp ứng đủ
                            điều kiện theo quy định.
                          </p>
                          <p>
                            <b>Quy trình hoàn tiền:</b> được thông báo trên
                            website
                            <b> Kurio.vn</b> hoặc thông báo trực tiếp cho Khách
                            hàng tại thời điểm Khách hàng đăng ký thành công sản
                            phẩm, dịch vụ.
                          </p>
                          <p>
                            Mọi thắc mắc, khiếu nại về vấn đề chính sách hoàn
                            tiền vui lòng liên hệ số hotline <b>0971500120</b>{" "}
                            để được hỗ trợ tốt nhất
                          </p>
                          <b>Điều kiện:</b>
                          <ul>
                            <li className="qa-list-item">
                              Phụ huynh đã thanh toán 100% học phí của gói học.
                            </li>
                            <li className="qa-list-item">
                              Học sinh chưa sử dụng tài khoản.
                            </li>
                          </ul>
                          <b>Thủ tục yêu cầu hoàn trả:</b>
                          <ul>
                            <li className="qa-list-item">
                              <b>Bước 1: </b>Liên hệ số Hotline{" "}
                              <b>0971500120</b> cung cấp thông tin số điện thoại
                              đăng ký tài khoản của học sinh và thông tin gói
                              học.
                            </li>
                            <li className="qa-list-item">
                              <b>Bước 2: </b>
                              <b>Kurio.vn</b> xem xét đề nghị hoàn học phí và
                              điều kiện
                            </li>
                            <li className="qa-list-item">
                              <b>Bước 3: </b>
                              <b>Kurio.vn</b> thông báo quyết định hoàn học phí
                              và xin thông tin ngân hàng để hoàn lại học phí cho
                              học sinh
                            </li>
                            <li className="qa-list-item">
                              <b>Bước 4: </b>
                              <b>Kurio.vn</b> tiến hành hoàn phí. Thời gian hoàn
                              tất hoàn phí dự kiến được thực hiện trong 15 ngày
                              làm việc kể từ ngày xác nhận đủ điều kiện hoàn
                              phí.
                            </li>
                          </ul>
                          <p>
                            <b className="is-italic is-underlined">Lưu ý : </b>{" "}
                            Để thông tin hoàn học phí được xác thực Quý phụ
                            huynh vui lòng cung cấp chứng từ chuyển khoản để
                            Kurio tiến hành kiểm tra thông tin và hoàn phí đúng
                            quy định.
                          </p>
                        </div>
                      </AnswerItem>
                    </>
                  )}
                  {categorySelected === 1 && (
                    <>
                      <AnswerItem
                        itemSelected={itemSelected}
                        handleShowAnswer={handleShowAnswer}
                        index={1}
                        isCurrent={itemSelected.current === 1}
                        isPrev={itemSelected.prev === 1}
                        title={
                          "2.1. Chương trình học của Kurio dựa trên tài liệu nào?"
                        }
                        isMobile={isMobile}
                      >
                        <p>
                          <b>Kurio</b> xây dựng chương trình học dựa trên khung
                          chương trình của <b>Bộ Giáo Dục Việt Nam</b>, tổng hợp
                          kiến thức từ các sách giáo khoa và kết hợp với Toán tư
                          duy chuẩn quốc tế từ kỳ thi
                          <b> IKMC</b>, giúp học sinh nắm vững nền tảng và phát
                          triển tư duy toán học toàn diện
                        </p>
                      </AnswerItem>
                      <AnswerItem
                        itemSelected={itemSelected}
                        handleShowAnswer={handleShowAnswer}
                        index={2}
                        isCurrent={itemSelected.current === 2}
                        isPrev={itemSelected.prev === 2}
                        title={
                          "2.2. Hệ thống đánh giá thông minh Elorio là gì?"
                        }
                        isMobile={isMobile}
                      >
                        <p>
                          - Elorio là hệ thống đánh giá thông minh của{" "}
                          <b>Kurio</b>, giúp xác định trình độ của học sinh và
                          gợi ý lộ trình học tập cá nhân hóa.
                        </p>
                        <p>
                          - Dựa trên bài kiểm tra đầu vào và quá trình học,
                          Elorio sẽ điều chỉnh độ khó và nội dung bài tập, đảm
                          bảo học sinh được học phù hợp với khả năng và tiến bộ
                          đều đặn.
                        </p>
                      </AnswerItem>
                      <AnswerItem
                        itemSelected={itemSelected}
                        handleShowAnswer={handleShowAnswer}
                        index={3}
                        isCurrent={itemSelected.current === 3}
                        isPrev={itemSelected.prev === 3}
                        title={
                          "2.3. Thời gian lý tưởng để luyện tập với Kurio mỗi ngày là bao lâu?"
                        }
                        isMobile={isMobile}
                      >
                        <p>
                          Để đạt hiệu quả tốt nhất, học sinh nên luyện tập Toán
                          tư duy với <b>Kurio</b> từ 20-30 phút mỗi ngày. Khoảng
                          thời gian này đủ để con ôn luyện kiến thức và giữ hứng
                          thú học tập mà không bị quá tải.
                        </p>
                      </AnswerItem>
                    </>
                  )}
                  {categorySelected === 2 && (
                    <>
                      <AnswerItem
                        itemSelected={itemSelected}
                        handleShowAnswer={handleShowAnswer}
                        index={1}
                        isCurrent={itemSelected.current === 1}
                        isPrev={itemSelected.prev === 1}
                        title={
                          "3.1. Luyện thi IKMC trên Kurio khác gì so với các lớp luyện thi khác?"
                        }
                        isMobile={isMobile}
                      >
                        <p>
                          - Là đối tác học thuật chính thức của kỳ thi{" "}
                          <b>IKMC</b>, <b>Kurio</b> cung cấp lộ trình ôn thi
                          chuẩn xác với kho đề thi chính thức từ các năm trước,
                          phòng thi ảo có đồng hồ đếm ngược mô phỏng y như thi
                          thật và lời giải chi tiết.
                        </p>
                        <p>
                          - Lộ trình cá nhân hóa của <b>Kurio</b> đảm bảo học
                          sinh ôn đúng phần cần thiết, hiệu quả cao hơn so với
                          các lớp học thêm thông thường.
                        </p>
                      </AnswerItem>
                      <AnswerItem
                        itemSelected={itemSelected}
                        handleShowAnswer={handleShowAnswer}
                        index={2}
                        isCurrent={itemSelected.current === 2}
                        isPrev={itemSelected.prev === 2}
                        title={"3.2. Kurio có cung cấp đề thi thử IKMC không?"}
                        isMobile={isMobile}
                      >
                        <p>
                          <b>Có, Kurio</b> có kho đề thi chính thức của{" "}
                          <b>IKMC</b> từ nhiều năm, và tính năng Thi tháng theo
                          dạng đề <b>IKMC</b>, giúp học sinh làm quen với các
                          dạng bài và <b>rèn luyện kỹ năng</b> giải đề, giúp con{" "}
                          <b>tự tin</b> và làm quen với áp lực thi thật.
                        </p>
                      </AnswerItem>
                      <AnswerItem
                        itemSelected={itemSelected}
                        handleShowAnswer={handleShowAnswer}
                        index={3}
                        isCurrent={itemSelected.current === 3}
                        isPrev={itemSelected.prev === 3}
                        title={
                          "3.3. Lộ trình luyện thi IKMC trên Kurio có khác gì so với luyện thi thông thường không?"
                        }
                        isMobile={isMobile}
                      >
                        <p>
                          <b>Có. Kurio</b> cung cấp một lộ trình ôn thi{" "}
                          <b>cá nhân hóa</b> dựa trên khả năng hiện tại của mỗi
                          học sinh, giúp con học đúng phần cần bổ sung mà không
                          bị quá tải. Lộ trình này gồm ba bước cụ thể để đảm bảo
                          học sinh đạt hiệu quả tối ưu.
                        </p>
                      </AnswerItem>
                      <AnswerItem
                        itemSelected={itemSelected}
                        handleShowAnswer={handleShowAnswer}
                        index={4}
                        isCurrent={itemSelected.current === 4}
                        isPrev={itemSelected.prev === 4}
                        title={
                          "3.4. Ba bước trong lộ trình luyện thi IKMC của Kurio là gì?"
                        }
                        isMobile={isMobile}
                      >
                        <p>Lộ trình luyện thi IKMC tại Kurio gồm 3 bước:</p>
                        <div className="is-flex is-flex-direction-column is-flex-gap-3">
                          <p className="ml-3">
                            <b>1. Đánh giá trình độ ban đầu</b> – Học sinh sẽ
                            làm bài kiểm tra đầu vào để đánh giá chính xác khả
                            năng và các phần kiến thức còn yếu.
                          </p>
                          <p className="ml-3">
                            <b>2. Xây dựng lộ trình cá nhân hóa</b> – Dựa trên
                            kết quả đầu vào, Kurio sẽ thiết kế lộ trình học tập
                            phù hợp với nhu cầu và mục tiêu của từng học sinh.
                          </p>
                          <p className="ml-3">
                            <b>3. Kiểm tra đầu ra</b> – Sau khi hoàn thành lộ
                            trình, Kurio sẽ tổ chức một bài kiểm tra tổng hợp để
                            đánh giá tiến bộ của học sinh, giúp con tự tin trước
                            kỳ thi chính thức.
                          </p>
                        </div>
                      </AnswerItem>
                      <AnswerItem
                        itemSelected={itemSelected}
                        handleShowAnswer={handleShowAnswer}
                        index={5}
                        isCurrent={itemSelected.current === 5}
                        isPrev={itemSelected.prev === 5}
                        title={
                          "3.5. Kurio có chương trình học nào hỗ trợ ngoài việc luyện thi IKMC không?"
                        }
                        isMobile={isMobile}
                      >
                        <p>
                          Ngoài luyện thi <b>IKMC</b>, Kurio còn cung cấp chương
                          trình học Toán tư duy theo chuẩn quốc tế và khung
                          chương trình Bộ Giáo Dục Việt Nam, giúp học sinh không
                          chỉ học để thi mà còn phát triển tư duy logic, khả
                          năng phân tích và sáng tạo.
                        </p>
                      </AnswerItem>
                    </>
                  )}
                  {categorySelected === 3 && (
                    <>
                      <AnswerItem
                        itemSelected={itemSelected}
                        handleShowAnswer={handleShowAnswer}
                        index={1}
                        isCurrent={itemSelected.current === 1}
                        isPrev={itemSelected.prev === 1}
                        title={
                          "4.1. Một tài khoản có đăng nhập được nhiều thiết bị không?"
                        }
                        isMobile={isMobile}
                      >
                        <p>
                          Tài khoản Kurio có thể đăng nhập trên máy tính, iPad
                          và điện thoại nhưng người dùng chỉ được đăng nhập một
                          lúc trên một thiết bị.
                        </p>
                      </AnswerItem>
                      <AnswerItem
                        itemSelected={itemSelected}
                        handleShowAnswer={handleShowAnswer}
                        index={2}
                        isCurrent={itemSelected.current === 2}
                        isPrev={itemSelected.prev === 2}
                        title={
                          "4.2. Tại sao mỗi tài khoản chỉ nên được sử dụng bởi một người?"
                        }
                        isMobile={isMobile}
                      >
                        <p>
                          Vì Kurio cung cấp lộ trình học tập cá nhân hóa dựa
                          trên trình độ và tiến độ của từng học sinh, nên mỗi
                          tài khoản chỉ nên được sử dụng bởi một người để đảm
                          bảo đánh giá chính xác và đạt hiệu quả học tập tối đa.
                          Việc sử dụng nhiều người cho một tài khoản có thể ảnh
                          hưởng đến kết quả đánh giá và không đảm bảo lộ trình
                          học tập phù hợp.
                        </p>
                      </AnswerItem>
                      <AnswerItem
                        itemSelected={itemSelected}
                        handleShowAnswer={handleShowAnswer}
                        index={3}
                        isCurrent={itemSelected.current === 3}
                        isPrev={itemSelected.prev === 3}
                        title={
                          "4.3. Có thể chuyển nhượng tài khoản cho người khác không?"
                        }
                        isMobile={isMobile}
                      >
                        <p>
                          <b>Không</b>. Tài khoản Kurio được cá nhân hóa theo
                          người học từ khi đăng ký, nên việc chuyển nhượng tài
                          khoản cho người khác sẽ ảnh hưởng đến lộ trình học tập
                          và kết quả đánh giá.
                        </p>
                      </AnswerItem>
                      <AnswerItem
                        itemSelected={itemSelected}
                        handleShowAnswer={handleShowAnswer}
                        index={4}
                        isCurrent={itemSelected.current === 4}
                        isPrev={itemSelected.prev === 4}
                        title={
                          "4.4. Nếu có vấn đề khi sử dụng tài khoản, tôi có thể liên hệ với ai?"
                        }
                        isMobile={isMobile}
                      >
                        <p>
                          Đội ngũ hỗ trợ của Kurio luôn sẵn sàng trợ giúp. Phụ
                          huynh và học sinh có thể liên hệ qua Zalo/ hotline
                          <b> 0971 500 120 </b>
                          để được giải đáp nhanh chóng.
                        </p>
                      </AnswerItem>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingPageQA;
