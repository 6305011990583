import React from "react";
import "./component.style.css";

const InputRegister = ({
  value,
  type,
  label,
  name,
  onChange,
  widthInput = "100%",
  borderRadius = "1rem",
  heightInput = "3.5rem",
  isMobile = false,
  maxLength = 50,
  labelStyle = {
    maxWidth: "3.75rem",
    width:"100%",
    fontSize: "1rem",
    lineHeight: "normal",
    fontStyle: "normal",
    fontWeight: 700,
    overFlowWrap: "break-word",
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
}) => {
  var labelForMobile = {...labelStyle,fontSize:"0.75rem",maxWidth:"2.5rem"}
  return (
    <div
      className="is-flex is-align-items-center is-align-items-self-stretch is-flex-gap-4 has-text-kurio-main-color-black form-send-email"
    >
      {label && <label style={ isMobile ? labelForMobile : labelStyle}>{label}</label>}
      <input
        className="form-input-send-email is-flex-grow-0"
        type={type}
        onChange={onChange}
        name={name}
        value={value}
        maxLength={maxLength}
        style={{
          width: widthInput,
          borderRadius: borderRadius,
          height: heightInput,
        }}
      />
    </div>
  );
};

export default InputRegister;
